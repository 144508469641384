export const VALID_GUESSES = [
  'aaiun',
  'aarao',
  'ababa',
  'ababe',
  'abaca',
  'abace',
  'abaci',
  'abaco',
  'abada',
  'abade',
  'abado',
  'abafa',
  'abafe',
  'abafo',
  'abaio',
  'abais',
  'abaja',
  'abala',
  'abale',
  'abalo',
  'abama',
  'abana',
  'abane',
  'abano',
  'abare',
  'abaro',
  'abaso',
  'abata',
  'abate',
  'abati',
  'abato',
  'abatu',
  'abavi',
  'abavo',
  'abdal',
  'abdao',
  'abdar',
  'abdon',
  'abeau',
  'abeba',
  'abeca',
  'abece',
  'abede',
  'abedo',
  'abela',
  'abesi',
  'abete',
  'abeto',
  'abexi',
  'abglt',
  'abiao',
  'abias',
  'abico',
  'abiel',
  'abies',
  'abiga',
  'abigi',
  'abilo',
  'abios',
  'abisa',
  'abiud',
  'abius',
  'ableu',
  'ablua',
  'ablui',
  'abluo',
  'abner',
  'abnue',
  'aboco',
  'abohm',
  'abole',
  'aboli',
  'aboma',
  'abono',
  'abram',
  'abrao',
  'abrar',
  'abras',
  'abrem',
  'abres',
  'abreu',
  'abria',
  'abril',
  'abrir',
  'abris',
  'abriu',
  'absis',
  'abste',
  'absus',
  'abube',
  'abudo',
  'abuiz',
  'abuja',
  'abulo',
  'abuna',
  'abusa',
  'abuse',
  'abuso',
  'abuta',
  'abuxo',
  'acaba',
  'acabe',
  'acabo',
  'acaca',
  'acacu',
  'acade',
  'acaem',
  'acaia',
  'acaio',
  'acais',
  'acaju',
  'acala',
  'acalo',
  'acana',
  'acane',
  'acano',
  'acapu',
  'acara',
  'acare',
  'acari',
  'acaro',
  'acaru',
  'acaso',
  'acata',
  'acate',
  'acato',
  'acaua',
  'acave',
  'acavo',
  'acaxa',
  'accio',
  'acefe',
  'acena',
  'acene',
  'aceno',
  'acero',
  'acesa',
  'aceso',
  'aceto',
  'achai',
  'acham',
  'achar',
  'achas',
  'achei',
  'achem',
  'aches',
  'achoa',
  'achou',
  'acibe',
  'acica',
  'acida',
  'acido',
  'acima',
  'acino',
  'acioa',
  'acipe',
  'aciso',
  'acker',
  'aclio',
  'aclis',
  'acmao',
  'acnea',
  'acnua',
  'acoba',
  'acobe',
  'acoca',
  'acode',
  'acoes',
  'acola',
  'acole',
  'acoli',
  'acome',
  'acona',
  'acope',
  'acopo',
  'acore',
  'acoro',
  'acral',
  'acres',
  'acreu',
  'acrol',
  'acron',
  'actel',
  'actio',
  'actol',
  'acuai',
  'acuam',
  'acuar',
  'acuas',
  'acuba',
  'acube',
  'acuda',
  'acude',
  'acudi',
  'acudo',
  'acuei',
  'acuem',
  'acues',
  'acuma',
  'acume',
  'acumi',
  'acuna',
  'acuns',
  'acuos',
  'acuou',
  'acure',
  'acusa',
  'acuse',
  'acuso',
  'acuta',
  'acuti',
  'adaba',
  'adaca',
  'adada',
  'adaga',
  'adage',
  'adail',
  'adair',
  'adais',
  'adali',
  'adami',
  'adara',
  'adari',
  'adeba',
  'adega',
  'adege',
  'adele',
  'adelo',
  'adema',
  'adena',
  'adeno',
  'adere',
  'aderi',
  'adeso',
  'adeus',
  'adiai',
  'adiam',
  'adiao',
  'adiar',
  'adias',
  'adibe',
  'adibo',
  'adida',
  'adido',
  'adiei',
  'adiel',
  'adiem',
  'adies',
  'adila',
  'adimo',
  'adine',
  'adino',
  'adioe',
  'adion',
  'adiou',
  'adipe',
  'adipo',
  'adira',
  'adiro',
  'adita',
  'adite',
  'adito',
  'adive',
  'adixa',
  'adjer',
  'adlai',
  'adler',
  'adoca',
  'adoce',
  'adoco',
  'adoga',
  'adolf',
  'adolo',
  'adoni',
  'adora',
  'adore',
  'adoro',
  'adota',
  'adote',
  'adoto',
  'adova',
  'adoxo',
  'adqui',
  'adsir',
  'adson',
  'aduar',
  'aduba',
  'adube',
  'adubo',
  'adula',
  'adule',
  'adulo',
  'advem',
  'advim',
  'advir',
  'aecio',
  'aedes',
  'aeead',
  'aegla',
  'aelia',
  'aeneo',
  'aerea',
  'aereo',
  'afaca',
  'afaga',
  'afago',
  'afana',
  'afane',
  'afano',
  'afear',
  'afeca',
  'afefe',
  'afega',
  'afere',
  'aferi',
  'afeta',
  'afete',
  'afeto',
  'afiai',
  'afial',
  'afiam',
  'afiar',
  'afias',
  'afido',
  'afiei',
  'afiem',
  'afies',
  'afilo',
  'afina',
  'afine',
  'afino',
  'afins',
  'afiou',
  'afira',
  'afiro',
  'afixa',
  'afixe',
  'afixo',
  'aflar',
  'aflua',
  'aflui',
  'afluo',
  'afoba',
  'afobe',
  'afobo',
  'afodo',
  'afofa',
  'afofe',
  'afofo',
  'afoga',
  'afogo',
  'afono',
  'afora',
  'afore',
  'aforo',
  'afose',
  'afoxe',
  'afros',
  'aftas',
  'afufo',
  'afura',
  'agabe',
  'agabo',
  'agace',
  'agada',
  'agala',
  'agama',
  'agame',
  'agami',
  'agamo',
  'agana',
  'agape',
  'agare',
  'agaro',
  'agata',
  'agate',
  'agati',
  'agave',
  'agavo',
  'ageia',
  'ageis',
  'agema',
  'ageno',
  'agiam',
  'agiar',
  'agias',
  'agida',
  'agido',
  'agimo',
  'agina',
  'agino',
  'agios',
  'agira',
  'agita',
  'agite',
  'agito',
  'aglae',
  'aglea',
  'aglia',
  'agmar',
  'agmen',
  'agnal',
  'agnes',
  'agnus',
  'agoge',
  'agolo',
  'agoma',
  'agono',
  'agora',
  'agori',
  'agrao',
  'agrar',
  'agraz',
  'agrem',
  'agreu',
  'agrio',
  'agror',
  'aguai',
  'aguam',
  'aguar',
  'aguas',
  'aguca',
  'aguce',
  'aguco',
  'aguda',
  'agudo',
  'aguei',
  'aguem',
  'agues',
  'aguia',
  'aguou',
  'aguti',
  'aiaca',
  'aiaia',
  'aiala',
  'aiane',
  'aiapa',
  'aiape',
  'aicar',
  'aidje',
  'aidro',
  'aigue',
  'aimao',
  'aimas',
  'aimee',
  'aimol',
  'ainda',
  'ainho',
  'ainoa',
  'ainsa',
  'aioca',
  'aiola',
  'aioro',
  'aipim',
  'aipos',
  'airao',
  'airar',
  'aires',
  'airua',
  'aitao',
  'aiuba',
  'aiuca',
  'aival',
  'aivao',
  'aivas',
  'aizoa',
  'ajabo',
  'ajacu',
  'ajaez',
  'ajais',
  'ajaja',
  'ajame',
  'ajamo',
  'ajana',
  'ajaua',
  'ajipa',
  'ajobo',
  'ajole',
  'ajuda',
  'ajude',
  'ajudo',
  'ajufe',
  'ajuga',
  'ajupa',
  'ajupe',
  'ajuru',
  'alabe',
  'alacr',
  'alada',
  'alado',
  'alafe',
  'alaga',
  'alago',
  'alaim',
  'alain',
  'alair',
  'alali',
  'alalo',
  'alamo',
  'alane',
  'alano',
  'alaor',
  'alapi',
  'alaso',
  'alaur',
  'alavo',
  'albin',
  'albis',
  'alboi',
  'albom',
  'albor',
  'album',
  'albur',
  'alcai',
  'alcam',
  'alcao',
  'alcar',
  'alcas',
  'alcaz',
  'alcea',
  'alcei',
  'alcem',
  'alces',
  'alceu',
  'alcir',
  'alcis',
  'alcou',
  'aldea',
  'aldel',
  'alder',
  'aldir',
  'aldol',
  'alear',
  'alefe',
  'alega',
  'alego',
  'aleia',
  'aleja',
  'aleli',
  'alelo',
  'alema',
  'alemo',
  'alena',
  'alepo',
  'alerj',
  'alete',
  'aleto',
  'aleva',
  'alexa',
  'alfar',
  'alfeu',
  'alfil',
  'alfim',
  'alfio',
  'alfir',
  'alfol',
  'alfoz',
  'alfre',
  'alfur',
  'algal',
  'algar',
  'algas',
  'algaz',
  'alger',
  'alges',
  'algia',
  'algol',
  'algor',
  'algoz',
  'algum',
  'algur',
  'alhal',
  'alhar',
  'alhas',
  'alhea',
  'alheo',
  'alhia',
  'alhos',
  'aliai',
  'aliam',
  'aliar',
  'alias',
  'aliba',
  'alibi',
  'alica',
  'alice',
  'alico',
  'alida',
  'aliei',
  'aliem',
  'alies',
  'alife',
  'alija',
  'alije',
  'alijo',
  'alilo',
  'alime',
  'aline',
  'aliou',
  'alipo',
  'alisa',
  'alise',
  'aliso',
  'alita',
  'aljuz',
  'almao',
  'almas',
  'almez',
  'almir',
  'almom',
  'almuz',
  'aloca',
  'aloco',
  'alode',
  'aloes',
  'alofe',
  'alofo',
  'alogo',
  'aloja',
  'aloje',
  'alojo',
  'alono',
  'alose',
  'alpao',
  'alpea',
  'alpes',
  'alpos',
  'alqua',
  'alsol',
  'altar',
  'altas',
  'altea',
  'alter',
  'altim',
  'altor',
  'altos',
  'aluan',
  'aluar',
  'aluco',
  'aluda',
  'alude',
  'aludi',
  'aludo',
  'alufa',
  'aluga',
  'alugo',
  'aluio',
  'aluir',
  'aluja',
  'alula',
  'aluna',
  'aluno',
  'alura',
  'alvao',
  'alvar',
  'alvas',
  'alveo',
  'alves',
  'alvim',
  'alvor',
  'alvos',
  'amaca',
  'amaci',
  'amada',
  'amade',
  'amado',
  'amadu',
  'amago',
  'amais',
  'amala',
  'amale',
  'amame',
  'amamo',
  'amamu',
  'amani',
  'amapa',
  'amara',
  'amari',
  'amaro',
  'amasa',
  'amava',
  'ambao',
  'ambar',
  'ambas',
  'ambel',
  'amber',
  'ambil',
  'ambir',
  'amboa',
  'ambom',
  'ambos',
  'ambre',
  'ambua',
  'ambui',
  'amear',
  'ameba',
  'ameca',
  'ameia',
  'ameis',
  'ameju',
  'amelo',
  'amemo',
  'amena',
  'ameno',
  'amete',
  'amial',
  'amico',
  'amido',
  'amiel',
  'amiga',
  'amigo',
  'amilo',
  'amima',
  'amime',
  'amimo',
  'amino',
  'amios',
  'amito',
  'amnio',
  'amola',
  'amole',
  'amolo',
  'amora',
  'amore',
  'amovo',
  'ampla',
  'amplo',
  'amrao',
  'amuar',
  'amuca',
  'amuos',
  'amura',
  'amure',
  'amuso',
  'amuxa',
  'anabe',
  'anabo',
  'anaca',
  'anace',
  'anaco',
  'anael',
  'anafa',
  'anafe',
  'anage',
  'anahy',
  'anaia',
  'anais',
  'anaja',
  'anama',
  'aname',
  'anami',
  'anamo',
  'anana',
  'anano',
  'anapu',
  'anari',
  'anati',
  'anaua',
  'anaue',
  'anavi',
  'anbar',
  'ancas',
  'ancel',
  'anceu',
  'ancia',
  'ancil',
  'ancol',
  'andai',
  'andam',
  'andar',
  'andas',
  'andei',
  'andem',
  'andes',
  'andim',
  'andir',
  'andor',
  'andou',
  'andre',
  'anear',
  'aneci',
  'anedo',
  'aneel',
  'aneia',
  'aneis',
  'anejo',
  'anela',
  'anele',
  'anelo',
  'anema',
  'anesa',
  'anete',
  'aneto',
  'anexa',
  'anexe',
  'anexo',
  'aneza',
  'angaa',
  'angas',
  'angau',
  'angel',
  'anglo',
  'angor',
  'angra',
  'angul',
  'aniao',
  'aniba',
  'aniel',
  'anima',
  'anime',
  'animi',
  'animo',
  'anion',
  'anira',
  'aniso',
  'anita',
  'anite',
  'anito',
  'anixi',
  'anixo',
  'anjao',
  'anjos',
  'anjum',
  'anodo',
  'anoes',
  'anona',
  'anori',
  'anoso',
  'anota',
  'anote',
  'anoto',
  'anrao',
  'ansar',
  'ansel',
  'ansia',
  'antal',
  'antao',
  'antar',
  'antas',
  'antes',
  'anteu',
  'antia',
  'antro',
  'anuai',
  'anual',
  'anuam',
  'anuar',
  'anuas',
  'anube',
  'anuem',
  'anuia',
  'anuir',
  'anuis',
  'anuiu',
  'anuja',
  'anula',
  'anule',
  'anulo',
  'anuns',
  'anuri',
  'anuro',
  'anzol',
  'aoita',
  'aonde',
  'aonio',
  'aoqui',
  'aorta',
  'apaga',
  'apage',
  'apago',
  'apaim',
  'apama',
  'apana',
  'apapa',
  'apara',
  'apare',
  'aparo',
  'apart',
  'apeai',
  'apear',
  'apecu',
  'apeei',
  'apega',
  'apego',
  'apeia',
  'apeie',
  'apeio',
  'apeja',
  'apela',
  'apele',
  'apelo',
  'apena',
  'apene',
  'apeno',
  'apeou',
  'aperu',
  'apeto',
  'apiaa',
  'apiai',
  'apice',
  'apida',
  'apiol',
  'apiro',
  'apita',
  'apite',
  'apito',
  'aplao',
  'apoca',
  'apode',
  'apodi',
  'apodo',
  'apofe',
  'apoia',
  'apoie',
  'apoio',
  'apolo',
  'apone',
  'apono',
  'apora',
  'apore',
  'aporo',
  'apota',
  'apoto',
  'aprea',
  'aptar',
  'aptas',
  'aptos',
  'apuie',
  'apuim',
  'apulo',
  'apupo',
  'apura',
  'apure',
  'apuro',
  'aquem',
  'aqueo',
  'aqueu',
  'aquim',
  'aquis',
  'araao',
  'araba',
  'arabe',
  'arabo',
  'arabu',
  'araca',
  'arace',
  'araci',
  'aracu',
  'arada',
  'arado',
  'araia',
  'araio',
  'arais',
  'araiu',
  'aralo',
  'arama',
  'arame',
  'aramo',
  'arana',
  'arani',
  'arapu',
  'arara',
  'arare',
  'arari',
  'arata',
  'arato',
  'aratu',
  'araua',
  'araue',
  'arava',
  'aravo',
  'araxa',
  'araza',
  'arbel',
  'arbim',
  'arbio',
  'arcai',
  'arcam',
  'arcao',
  'arcar',
  'arcas',
  'arcaz',
  'arceu',
  'archa',
  'arcio',
  'arcos',
  'arcou',
  'ardam',
  'ardas',
  'ardea',
  'ardei',
  'ardem',
  'arder',
  'ardes',
  'ardeu',
  'ardia',
  'ardil',
  'ardis',
  'ardom',
  'ardor',
  'ardra',
  'ardua',
  'arduo',
  'areal',
  'areao',
  'arear',
  'areas',
  'areca',
  'arede',
  'areia',
  'areis',
  'areja',
  'areje',
  'arejo',
  'areli',
  'arelo',
  'aremo',
  'arena',
  'areno',
  'areol',
  'arere',
  'aretu',
  'arfai',
  'arfam',
  'arfar',
  'arfas',
  'arfei',
  'arfem',
  'arfes',
  'arfil',
  'arfir',
  'arfou',
  'arfur',
  'argal',
  'argao',
  'argas',
  'argau',
  'argel',
  'argem',
  'arges',
  'argeu',
  'argio',
  'argol',
  'argon',
  'argos',
  'argua',
  'argue',
  'argui',
  'arguo',
  'ariao',
  'ariar',
  'arias',
  'aribe',
  'arica',
  'arico',
  'aricu',
  'arida',
  'arido',
  'ariel',
  'aries',
  'arife',
  'arigo',
  'arilo',
  'arima',
  'arimo',
  'arina',
  'ariri',
  'arita',
  'arite',
  'ariti',
  'arito',
  'aritu',
  'arjao',
  'arjoz',
  'arlei',
  'arles',
  'armai',
  'armam',
  'armao',
  'armar',
  'armas',
  'armei',
  'armem',
  'armes',
  'armeu',
  'armia',
  'armim',
  'armio',
  'armou',
  'armum',
  'armur',
  'arnal',
  'arnaz',
  'arnes',
  'arode',
  'arodi',
  'aroer',
  'arolo',
  'aroma',
  'aroto',
  'arpao',
  'arpar',
  'arpeu',
  'arpoa',
  'arpoe',
  'arpoo',
  'arque',
  'arqui',
  'arral',
  'arras',
  'arrea',
  'arria',
  'arrie',
  'arrio',
  'arroz',
  'arsio',
  'arsis',
  'artes',
  'artro',
  'artur',
  'aruai',
  'aruas',
  'aruau',
  'aruba',
  'arube',
  'aruca',
  'aruga',
  'aruja',
  'arujo',
  'arula',
  'aruma',
  'arupa',
  'arura',
  'aruru',
  'aruto',
  'arval',
  'arxar',
  'arzel',
  'asael',
  'asafe',
  'asana',
  'asaro',
  'asase',
  'asbel',
  'ascal',
  'ascii',
  'ascio',
  'ascon',
  'ascra',
  'ascua',
  'aseia',
  'aselo',
  'aseno',
  'asiel',
  'asila',
  'asile',
  'asilo',
  'asnal',
  'asnar',
  'asnil',
  'asnos',
  'aspar',
  'aspas',
  'aspis',
  'aspre',
  'assai',
  'assam',
  'assar',
  'assas',
  'assaz',
  'assea',
  'assei',
  'assem',
  'asses',
  'assim',
  'assir',
  'assis',
  'assoa',
  'assoe',
  'assoo',
  'assos',
  'assou',
  'assua',
  'assur',
  'aster',
  'astil',
  'astim',
  'astor',
  'astre',
  'astro',
  'astur',
  'asura',
  'atabi',
  'ataca',
  'ataco',
  'atada',
  'atado',
  'ataga',
  'atais',
  'atala',
  'atalo',
  'atama',
  'atamo',
  'atana',
  'atapu',
  'atara',
  'atare',
  'atava',
  'atavo',
  'ateai',
  'atear',
  'ateco',
  'ateei',
  'ateia',
  'ateie',
  'ateio',
  'ateis',
  'atela',
  'atele',
  'atemo',
  'atena',
  'atens',
  'ateou',
  'atera',
  'ateus',
  'ateve',
  'atica',
  'atice',
  'atico',
  'aticu',
  'atida',
  'atido',
  'atila',
  'atimo',
  'atina',
  'atine',
  'atino',
  'atins',
  'atipo',
  'atira',
  'atire',
  'atiro',
  'ativa',
  'ative',
  'ativo',
  'atlai',
  'atlas',
  'atoba',
  'atofa',
  'atola',
  'atole',
  'atolo',
  'atomo',
  'atona',
  'atono',
  'atoto',
  'atrai',
  'atras',
  'atreo',
  'atreu',
  'atril',
  'atrio',
  'atriz',
  'atroo',
  'atroz',
  'atuai',
  'atual',
  'atuam',
  'atuar',
  'atuas',
  'atubo',
  'atuei',
  'atuem',
  'atues',
  'atuir',
  'atuns',
  'atuou',
  'atura',
  'ature',
  'aturo',
  'atuso',
  'auacu',
  'auais',
  'auaiu',
  'auati',
  'audaz',
  'audio',
  'auete',
  'aueti',
  'aueto',
  'aufue',
  'augar',
  'augir',
  'augiu',
  'auiba',
  'auina',
  'auiti',
  'aulas',
  'aulax',
  'auleu',
  'aulir',
  'aumai',
  'aural',
  'aurar',
  'auras',
  'aurea',
  'aureo',
  'aurir',
  'ausao',
  'ausco',
  'ausio',
  'auste',
  'autor',
  'autos',
  'autua',
  'autue',
  'autuo',
  'auuva',
  'auxia',
  'auzao',
  'avaca',
  'avais',
  'avano',
  'avant',
  'avara',
  'avare',
  'avaro',
  'avati',
  'aveal',
  'aveao',
  'aveia',
  'aveio',
  'avela',
  'avena',
  'avens',
  'aveus',
  'aviai',
  'aviam',
  'aviao',
  'aviar',
  'avias',
  'avida',
  'avido',
  'aviei',
  'aviem',
  'avier',
  'avies',
  'avila',
  'avimo',
  'avios',
  'aviou',
  'avira',
  'avisa',
  'avise',
  'aviso',
  'avite',
  'avito',
  'avium',
  'aviva',
  'avive',
  'avivo',
  'avoao',
  'awuna',
  'axada',
  'axexe',
  'axial',
  'axico',
  'axila',
  'axilo',
  'axino',
  'axixa',
  'axone',
  'axoxo',
  'ayres',
  'azabe',
  'azaca',
  'azado',
  'azael',
  'azaia',
  'azair',
  'azapa',
  'azara',
  'azare',
  'azaro',
  'azava',
  'azeca',
  'azeda',
  'azede',
  'azedo',
  'azegi',
  'azeri',
  'azeva',
  'aziar',
  'azias',
  'azibo',
  'azida',
  'aziel',
  'azigo',
  'azimo',
  'azina',
  'azize',
  'azmom',
  'azoia',
  'azoma',
  'azono',
  'azoto',
  'azuba',
  'azubo',
  'azuis',
  'azula',
  'azule',
  'azulo',
  'azuru',
  'baali',
  'babai',
  'babal',
  'babam',
  'babao',
  'babar',
  'babas',
  'babau',
  'babei',
  'babel',
  'babem',
  'baber',
  'babes',
  'babia',
  'babis',
  'babla',
  'bable',
  'babou',
  'babua',
  'babui',
  'babul',
  'bacaa',
  'bacal',
  'bacao',
  'bacar',
  'bacen',
  'baces',
  'bachi',
  'bacia',
  'bacio',
  'bacis',
  'bacon',
  'bacos',
  'bacta',
  'bacus',
  'badal',
  'badem',
  'badia',
  'badil',
  'badio',
  'badri',
  'badua',
  'badui',
  'badur',
  'baeco',
  'baele',
  'baena',
  'baeta',
  'bafar',
  'bafer',
  'bafia',
  'bafio',
  'bafos',
  'bafum',
  'bagar',
  'bagas',
  'bagda',
  'bagem',
  'bagoa',
  'bagos',
  'bagre',
  'bagua',
  'bague',
  'bahia',
  'baiao',
  'baiar',
  'baias',
  'baiau',
  'baibe',
  'baila',
  'baile',
  'bailo',
  'baios',
  'baira',
  'baire',
  'bairi',
  'bairu',
  'baita',
  'baite',
  'baixa',
  'baixe',
  'baixo',
  'bajar',
  'bajia',
  'balam',
  'balao',
  'balar',
  'balas',
  'balau',
  'balbo',
  'balca',
  'balco',
  'balde',
  'balea',
  'balem',
  'bales',
  'balia',
  'balim',
  'balio',
  'balir',
  'balis',
  'baliu',
  'balsa',
  'balso',
  'balta',
  'balto',
  'bamba',
  'bambe',
  'bambi',
  'bambo',
  'bambu',
  'bamia',
  'banal',
  'banar',
  'banas',
  'banca',
  'banco',
  'banda',
  'bando',
  'banem',
  'banes',
  'bango',
  'bangu',
  'banha',
  'banhe',
  'banho',
  'bania',
  'banir',
  'banis',
  'baniu',
  'banjo',
  'bansa',
  'banta',
  'bante',
  'banti',
  'banto',
  'bantu',
  'banua',
  'banze',
  'baoba',
  'bapte',
  'baque',
  'barao',
  'baras',
  'barau',
  'barba',
  'barbi',
  'barbo',
  'barca',
  'barco',
  'bardo',
  'barea',
  'barem',
  'bares',
  'barga',
  'bargu',
  'baria',
  'baril',
  'barim',
  'bario',
  'barma',
  'baroa',
  'barra',
  'barre',
  'barro',
  'barsa',
  'barsi',
  'barso',
  'basal',
  'basar',
  'basco',
  'basea',
  'bases',
  'basim',
  'basio',
  'basis',
  'basra',
  'basse',
  'basso',
  'basta',
  'baste',
  'basto',
  'basua',
  'batam',
  'batao',
  'batas',
  'batea',
  'batei',
  'batel',
  'batem',
  'bater',
  'bates',
  'bateu',
  'batia',
  'batim',
  'batis',
  'batmo',
  'batom',
  'bator',
  'batos',
  'batul',
  'baule',
  'bauma',
  'baume',
  'bauna',
  'bauru',
  'bavio',
  'baxim',
  'bayes',
  'bazar',
  'beaba',
  'beach',
  'beata',
  'beato',
  'bebai',
  'bebam',
  'bebas',
  'bebei',
  'bebel',
  'bebem',
  'beber',
  'bebes',
  'bebeu',
  'bebia',
  'bebra',
  'bebum',
  'becas',
  'beche',
  'becos',
  'becua',
  'bedao',
  'bedel',
  'bedem',
  'bedro',
  'bedui',
  'bedum',
  'beens',
  'begbe',
  'beges',
  'begua',
  'begue',
  'begum',
  'beico',
  'beija',
  'beije',
  'beiji',
  'beijo',
  'beiju',
  'beira',
  'beire',
  'beiro',
  'beiru',
  'beisa',
  'bejel',
  'belao',
  'belas',
  'belau',
  'belaz',
  'belem',
  'belfo',
  'belga',
  'belgo',
  'belho',
  'belio',
  'belis',
  'beliz',
  'bello',
  'belos',
  'belro',
  'belua',
  'belus',
  'bembe',
  'bemol',
  'benca',
  'benco',
  'bencu',
  'benda',
  'bende',
  'bendi',
  'bendo',
  'benge',
  'bengo',
  'benha',
  'benia',
  'benim',
  'benin',
  'benta',
  'bento',
  'benza',
  'benze',
  'benzi',
  'benzo',
  'beola',
  'beque',
  'berbe',
  'berco',
  'berda',
  'bereu',
  'bergo',
  'beril',
  'beris',
  'berix',
  'berma',
  'berna',
  'berne',
  'berno',
  'beroe',
  'berol',
  'berra',
  'berre',
  'berro',
  'berta',
  'berto',
  'besai',
  'bespe',
  'bessi',
  'besso',
  'besta',
  'betal',
  'betao',
  'betar',
  'betel',
  'betim',
  'betis',
  'betle',
  'betoi',
  'betol',
  'betom',
  'betre',
  'betsi',
  'betum',
  'bezar',
  'bezau',
  'bezoo',
  'biaba',
  'biaco',
  'biami',
  'biana',
  'biara',
  'biari',
  'biaro',
  'biata',
  'bibia',
  'bibio',
  'bibla',
  'biboi',
  'bibra',
  'bicai',
  'bical',
  'bicam',
  'bicao',
  'bicar',
  'bicas',
  'bicha',
  'bicho',
  'bicia',
  'bicos',
  'bicou',
  'bidao',
  'bidum',
  'biduo',
  'biela',
  'bielo',
  'biene',
  'bieno',
  'bifar',
  'bifre',
  'bigas',
  'bigle',
  'bigua',
  'bigue',
  'bijou',
  'bijui',
  'bilac',
  'bilar',
  'bilha',
  'bilho',
  'bilia',
  'bilis',
  'bilmo',
  'bilro',
  'bilsa',
  'bilva',
  'bimao',
  'bimar',
  'bimba',
  'binar',
  'binde',
  'binea',
  'bingo',
  'binha',
  'binho',
  'biniu',
  'binui',
  'bioba',
  'bioco',
  'bioma',
  'biose',
  'biota',
  'bipar',
  'bique',
  'birba',
  'birco',
  'birgo',
  'birma',
  'birra',
  'birro',
  'birsa',
  'birus',
  'bisao',
  'bisar',
  'bisbi',
  'bisel',
  'bisga',
  'bisma',
  'bisol',
  'bispa',
  'bispo',
  'bisso',
  'bitar',
  'biter',
  'bitia',
  'bitio',
  'bitis',
  'bitre',
  'biura',
  'bivio',
  'bizer',
  'blase',
  'blefa',
  'blefe',
  'blefo',
  'bleno',
  'bleso',
  'blitz',
  'bloco',
  'bluco',
  'blues',
  'blufo',
  'blusa',
  'bndes',
  'boaba',
  'boaco',
  'boais',
  'boana',
  'boane',
  'boari',
  'boate',
  'boato',
  'bobai',
  'bobal',
  'bobao',
  'bobar',
  'bobas',
  'bobea',
  'bobem',
  'bobes',
  'bobos',
  'bocal',
  'bocao',
  'bocar',
  'bocas',
  'bocel',
  'bocha',
  'boche',
  'bocim',
  'bocio',
  'bocoa',
  'bocue',
  'bodar',
  'bodas',
  'bodes',
  'bodum',
  'boeio',
  'boelo',
  'boers',
  'boeta',
  'bofar',
  'bofas',
  'bofes',
  'bofia',
  'bogar',
  'bogas',
  'bogue',
  'bogum',
  'boiai',
  'boiam',
  'boiao',
  'boiar',
  'boias',
  'boibi',
  'boicu',
  'boiei',
  'boiem',
  'boies',
  'boiga',
  'boiil',
  'boime',
  'boina',
  'boiou',
  'boiru',
  'boita',
  'boite',
  'bojao',
  'bojar',
  'bojui',
  'bolai',
  'bolam',
  'bolao',
  'bolar',
  'bolas',
  'bolbo',
  'bolco',
  'boldo',
  'bolei',
  'bolem',
  'boles',
  'boleu',
  'bolgo',
  'bolha',
  'bolho',
  'bolim',
  'bolor',
  'bolos',
  'bolou',
  'bolsa',
  'bolso',
  'bomba',
  'bombo',
  'bonar',
  'bonde',
  'bondi',
  'bones',
  'bonge',
  'bongo',
  'bonho',
  'bonjo',
  'bonus',
  'bonze',
  'bonzo',
  'boope',
  'boora',
  'boral',
  'boras',
  'borax',
  'borba',
  'borda',
  'borde',
  'bordo',
  'borel',
  'borga',
  'boris',
  'borja',
  'borla',
  'borne',
  'borno',
  'boroa',
  'borra',
  'borre',
  'borro',
  'bosao',
  'bosca',
  'bosco',
  'boson',
  'bossa',
  'bosta',
  'botai',
  'botal',
  'botam',
  'botao',
  'botar',
  'botas',
  'botei',
  'botem',
  'botes',
  'botim',
  'botio',
  'botos',
  'botou',
  'botox',
  'bouba',
  'bouda',
  'bouga',
  'bouri',
  'bovis',
  'boxar',
  'boxea',
  'bozra',
  'bozum',
  'braba',
  'brabo',
  'braca',
  'braco',
  'brada',
  'brade',
  'bradi',
  'brado',
  'braga',
  'braja',
  'brala',
  'brama',
  'brame',
  'brami',
  'bramo',
  'brasa',
  'brava',
  'bravo',
  'break',
  'breal',
  'brear',
  'breba',
  'breca',
  'breco',
  'bredo',
  'brefo',
  'brega',
  'brejo',
  'brema',
  'breno',
  'breta',
  'brete',
  'breus',
  'breve',
  'brezo',
  'brial',
  'briba',
  'brica',
  'brico',
  'brics',
  'brida',
  'briea',
  'briga',
  'brigo',
  'brins',
  'briol',
  'brion',
  'brios',
  'brisa',
  'brita',
  'brito',
  'brive',
  'briza',
  'broas',
  'broca',
  'broco',
  'broda',
  'bromo',
  'brona',
  'brota',
  'brote',
  'broto',
  'bruaa',
  'bruce',
  'bruco',
  'brueu',
  'brugo',
  'bruma',
  'brumo',
  'bruno',
  'bruta',
  'bruto',
  'bruxa',
  'bruxo',
  'buacu',
  'buada',
  'buaiz',
  'buama',
  'buano',
  'buara',
  'buase',
  'buava',
  'bubal',
  'bubao',
  'bucal',
  'bucha',
  'bucho',
  'buchu',
  'bucil',
  'bucim',
  'bucle',
  'bucre',
  'bucue',
  'bucui',
  'budin',
  'budur',
  'buena',
  'bueno',
  'buete',
  'bufai',
  'bufam',
  'bufao',
  'bufar',
  'bufas',
  'bufei',
  'bufem',
  'bufes',
  'bufir',
  'bufos',
  'bufou',
  'bufra',
  'bufri',
  'bugio',
  'bugle',
  'bugre',
  'buige',
  'builo',
  'bujao',
  'bulam',
  'bular',
  'bulas',
  'bulbo',
  'buldo',
  'bules',
  'bulha',
  'bulia',
  'bulio',
  'bulir',
  'bulis',
  'buliu',
  'bumba',
  'bumbo',
  'bunce',
  'bunda',
  'bunde',
  'bundo',
  'buque',
  'buqui',
  'burao',
  'burca',
  'burco',
  'burdo',
  'burel',
  'burgo',
  'buril',
  'buris',
  'burla',
  'burle',
  'burlo',
  'burno',
  'burnu',
  'burra',
  'burro',
  'bursa',
  'burue',
  'burum',
  'busca',
  'busco',
  'busil',
  'busso',
  'busto',
  'butao',
  'butar',
  'butau',
  'buteo',
  'butia',
  'butil',
  'butim',
  'butio',
  'butir',
  'butre',
  'butua',
  'buuno',
  'buvar',
  'buxal',
  'buxos',
  'buzio',
  'buzos',
  'byron',
  'caaba',
  'caaco',
  'caacu',
  'caaee',
  'caaia',
  'caama',
  'caami',
  'caapi',
  'caaxi',
  'cabal',
  'cabas',
  'cabau',
  'cabaz',
  'cabei',
  'cabem',
  'caber',
  'cabes',
  'cabia',
  'cabiu',
  'cabom',
  'cabos',
  'caboz',
  'cabra',
  'cabui',
  'cabul',
  'cabur',
  'cacai',
  'cacam',
  'cacao',
  'cacar',
  'cacas',
  'cacau',
  'cacei',
  'cacem',
  'caces',
  'cacha',
  'cache',
  'cacho',
  'cachu',
  'cacim',
  'caciz',
  'cacoa',
  'cacoe',
  'cacoo',
  'cacos',
  'cacou',
  'cacre',
  'cacto',
  'cacua',
  'cadai',
  'cadea',
  'cades',
  'cadoz',
  'cadue',
  'caete',
  'cafal',
  'cafes',
  'cafiz',
  'cafre',
  'cafta',
  'cafua',
  'cafus',
  'cafuz',
  'cagai',
  'cagam',
  'cagao',
  'cagar',
  'cagas',
  'cagoa',
  'cagom',
  'cagou',
  'cague',
  'caiai',
  'caiam',
  'caiar',
  'caias',
  'caiba',
  'caibi',
  'caibo',
  'caica',
  'caico',
  'caicu',
  'caida',
  'caide',
  'caido',
  'caiei',
  'caiem',
  'caies',
  'caimo',
  'caina',
  'caiou',
  'caipa',
  'caira',
  'caire',
  'cairi',
  'cairo',
  'cairu',
  'caite',
  'caito',
  'caiua',
  'caiue',
  'caius',
  'caixa',
  'caixe',
  'cajas',
  'cajua',
  'cajui',
  'cajus',
  'calai',
  'calam',
  'calao',
  'calar',
  'calas',
  'calau',
  'calca',
  'calce',
  'calco',
  'calda',
  'caldo',
  'calei',
  'calem',
  'cales',
  'calfe',
  'calha',
  'calhe',
  'calho',
  'calil',
  'calim',
  'calix',
  'calma',
  'calmo',
  'caloi',
  'calom',
  'calor',
  'calos',
  'calou',
  'calpo',
  'calta',
  'calua',
  'calum',
  'calva',
  'calvo',
  'camaa',
  'camal',
  'camao',
  'camas',
  'cambe',
  'camim',
  'camol',
  'camom',
  'campe',
  'campo',
  'camua',
  'canaa',
  'canal',
  'canas',
  'canaz',
  'canca',
  'cande',
  'candi',
  'cando',
  'caner',
  'canez',
  'canga',
  'cangu',
  'canha',
  'canho',
  'canil',
  'canja',
  'canje',
  'canjo',
  'canoa',
  'canoe',
  'canon',
  'canos',
  'cansa',
  'canse',
  'canso',
  'canta',
  'cante',
  'canto',
  'cantu',
  'canvi',
  'canvo',
  'canza',
  'canzo',
  'caoba',
  'caobi',
  'caoco',
  'caori',
  'capai',
  'capam',
  'capao',
  'capar',
  'capas',
  'capaz',
  'capea',
  'capei',
  'capem',
  'capes',
  'capia',
  'capim',
  'capom',
  'capos',
  'capou',
  'capra',
  'capro',
  'capso',
  'capta',
  'capte',
  'capto',
  'capua',
  'caput',
  'capuz',
  'caqui',
  'caraa',
  'carai',
  'caral',
  'carao',
  'caras',
  'carbo',
  'carca',
  'cardo',
  'carel',
  'caren',
  'careu',
  'carga',
  'cargo',
  'caria',
  'carie',
  'carii',
  'caril',
  'cario',
  'caris',
  'cariz',
  'carla',
  'carlo',
  'carme',
  'carmi',
  'carmo',
  'carne',
  'caroa',
  'caroe',
  'carol',
  'caros',
  'carpa',
  'carpe',
  'carpi',
  'carre',
  'carri',
  'carro',
  'carta',
  'carua',
  'carus',
  'carva',
  'carvi',
  'casai',
  'casal',
  'casam',
  'casao',
  'casar',
  'casas',
  'casba',
  'casca',
  'casco',
  'casei',
  'casem',
  'cases',
  'casos',
  'casou',
  'caspa',
  'cassa',
  'casse',
  'casso',
  'casta',
  'casti',
  'casto',
  'catai',
  'catam',
  'catao',
  'catar',
  'catas',
  'catau',
  'catei',
  'catem',
  'cates',
  'catia',
  'catim',
  'catos',
  'catou',
  'catre',
  'catua',
  'cauas',
  'cauba',
  'caubi',
  'cauda',
  'cauim',
  'caule',
  'caulo',
  'cauma',
  'cauna',
  'cauno',
  'caupi',
  'caure',
  'cauri',
  'causa',
  'cause',
  'causo',
  'cauta',
  'cauto',
  'cauxi',
  'cavai',
  'cavam',
  'cavao',
  'cavar',
  'cavas',
  'cavea',
  'cavei',
  'cavem',
  'caves',
  'cavia',
  'cavie',
  'cavio',
  'cavou',
  'caxao',
  'caxes',
  'caxim',
  'caxua',
  'cazim',
  'cazol',
  'cccii',
  'ccciv',
  'cccix',
  'cccli',
  'ccclv',
  'ccclx',
  'cccvi',
  'cccxc',
  'cccxi',
  'cccxl',
  'cccxv',
  'cccxx',
  'ccead',
  'cciii',
  'cclii',
  'ccliv',
  'cclix',
  'cclvi',
  'cclxi',
  'cclxv',
  'cclxx',
  'ccvii',
  'ccxci',
  'ccxcv',
  'ccxii',
  'ccxiv',
  'ccxix',
  'ccxli',
  'ccxlv',
  'ccxvi',
  'ccxxi',
  'ccxxv',
  'ccxxx',
  'cdiii',
  'cdlii',
  'cdliv',
  'cdlix',
  'cdlvi',
  'cdlxi',
  'cdlxv',
  'cdlxx',
  'cdvii',
  'cdxci',
  'cdxcv',
  'cdxii',
  'cdxiv',
  'cdxix',
  'cdxli',
  'cdxlv',
  'cdxvi',
  'cdxxi',
  'cdxxv',
  'cdxxx',
  'ceada',
  'ceado',
  'ceais',
  'ceamo',
  'ceara',
  'ceasa',
  'ceata',
  'ceava',
  'cebar',
  'cebas',
  'cebio',
  'cebus',
  'cecal',
  'cecao',
  'cecem',
  'cecil',
  'cecum',
  'cedam',
  'cedar',
  'cedas',
  'cedei',
  'cedem',
  'ceder',
  'cedes',
  'cedeu',
  'cedia',
  'cedro',
  'ceeis',
  'ceemo',
  'cefas',
  'cefet',
  'cegai',
  'cegam',
  'cegar',
  'cegas',
  'cegos',
  'cegou',
  'cegue',
  'ceiam',
  'ceias',
  'ceibo',
  'ceiem',
  'ceies',
  'ceifa',
  'ceife',
  'ceifo',
  'ceira',
  'ceita',
  'celao',
  'celas',
  'celeo',
  'celga',
  'celha',
  'celia',
  'celie',
  'celio',
  'celso',
  'celta',
  'celte',
  'celto',
  'ceman',
  'cenas',
  'cenda',
  'cendi',
  'cenho',
  'cenio',
  'cenis',
  'ceniz',
  'censo',
  'centi',
  'cento',
  'ceomo',
  'cerar',
  'ceras',
  'cerca',
  'cerce',
  'cerco',
  'cerda',
  'cerdo',
  'cereo',
  'ceres',
  'cerio',
  'ceris',
  'cerne',
  'cerni',
  'cerol',
  'cerra',
  'cerre',
  'cerro',
  'certa',
  'certo',
  'cervo',
  'cerze',
  'cerzi',
  'cesar',
  'cesca',
  'cesio',
  'cessa',
  'cesse',
  'cesso',
  'cesta',
  'cesto',
  'cetal',
  'cetef',
  'cetes',
  'cetil',
  'cetim',
  'cetol',
  'cetro',
  'ceuci',
  'ceuta',
  'cevao',
  'cevar',
  'chabo',
  'chabu',
  'chaco',
  'chada',
  'chade',
  'chaem',
  'chaga',
  'chago',
  'chaia',
  'chaim',
  'chaja',
  'chale',
  'chalo',
  'chama',
  'chame',
  'chamo',
  'chana',
  'chang',
  'chaos',
  'chapa',
  'chape',
  'chari',
  'charo',
  'charu',
  'chata',
  'chate',
  'chato',
  'chaua',
  'chaui',
  'chave',
  'chavo',
  'chebe',
  'checa',
  'checo',
  'chede',
  'chefa',
  'chefe',
  'chega',
  'chego',
  'cheia',
  'cheio',
  'chela',
  'chena',
  'chepe',
  'chesf',
  'cheto',
  'chiai',
  'chiam',
  'chiao',
  'chiar',
  'chias',
  'chibe',
  'chibo',
  'chica',
  'chico',
  'chicu',
  'chiei',
  'chiem',
  'chies',
  'chifu',
  'chila',
  'chile',
  'chili',
  'chilo',
  'chima',
  'chimu',
  'china',
  'ching',
  'chino',
  'chiou',
  'chipo',
  'chira',
  'chiru',
  'chita',
  'chite',
  'chiuo',
  'choca',
  'choco',
  'choes',
  'chogo',
  'chole',
  'cholo',
  'chona',
  'chone',
  'chopa',
  'chope',
  'chopi',
  'chopp',
  'chora',
  'chore',
  'choro',
  'chota',
  'choti',
  'chova',
  'chove',
  'chovi',
  'chovo',
  'chuca',
  'chuco',
  'chule',
  'chulo',
  'chuna',
  'chupa',
  'chupe',
  'chupo',
  'churi',
  'chuta',
  'chute',
  'chuto',
  'chuva',
  'chuxo',
  'ciano',
  'ciato',
  'cibar',
  'ciber',
  'ciboa',
  'cibum',
  'cicco',
  'ciclo',
  'cicro',
  'cidno',
  'cidra',
  'cidro',
  'ciena',
  'ciese',
  'cifar',
  'cifra',
  'cifre',
  'cifro',
  'cigas',
  'cigno',
  'cilas',
  'cilea',
  'cileu',
  'cilha',
  'cilio',
  'cimba',
  'cimbi',
  'cimbo',
  'cimno',
  'cimol',
  'cimue',
  'cinco',
  'cinda',
  'cinde',
  'cindi',
  'cindo',
  'cinge',
  'cingi',
  'cinja',
  'cinjo',
  'cinta',
  'cinto',
  'cinza',
  'cioba',
  'cioco',
  'ciona',
  'ciosa',
  'cioso',
  'cioto',
  'cipos',
  'cirao',
  'cirba',
  'circo',
  'cirio',
  'cirne',
  'cirno',
  'cirpo',
  'cirro',
  'cirso',
  'cirto',
  'cirza',
  'cirzo',
  'cisao',
  'cisar',
  'cisca',
  'cisco',
  'cisel',
  'cisma',
  'cisme',
  'cismo',
  'cisne',
  'ciste',
  'citai',
  'citam',
  'citar',
  'citas',
  'citei',
  'citem',
  'cites',
  'citeu',
  'citna',
  'citou',
  'citro',
  'ciume',
  'ciuro',
  'civel',
  'civil',
  'civis',
  'cizel',
  'clade',
  'clado',
  'clama',
  'clame',
  'clamo',
  'clape',
  'clara',
  'claro',
  'clava',
  'clear',
  'cleia',
  'clena',
  'clero',
  'clete',
  'cleto',
  'clide',
  'cliii',
  'clima',
  'cline',
  'clino',
  'clipe',
  'clips',
  'clise',
  'clita',
  'clito',
  'clone',
  'clori',
  'cloro',
  'cloto',
  'clube',
  'cluso',
  'cluva',
  'clvii',
  'clxii',
  'clxiv',
  'clxix',
  'clxvi',
  'clxxi',
  'clxxv',
  'clxxx',
  'cmiii',
  'cmlii',
  'cmliv',
  'cmlix',
  'cmlvi',
  'cmlxi',
  'cmlxv',
  'cmlxx',
  'cmvii',
  'cmxci',
  'cmxcv',
  'cmxii',
  'cmxiv',
  'cmxix',
  'cmxli',
  'cmxlv',
  'cmxvi',
  'cmxxi',
  'cmxxv',
  'cmxxx',
  'cnesf',
  'cntss',
  'coada',
  'coado',
  'coagi',
  'coais',
  'coaja',
  'coajo',
  'coale',
  'coamo',
  'coano',
  'coara',
  'coari',
  'coata',
  'coava',
  'coaxa',
  'coaxe',
  'coaxo',
  'cobio',
  'coboi',
  'cobol',
  'cobra',
  'cobre',
  'cobri',
  'cobro',
  'cobua',
  'cobus',
  'cocai',
  'cocal',
  'cocam',
  'cocao',
  'cocar',
  'cocas',
  'cocei',
  'cocem',
  'coces',
  'cochi',
  'cocho',
  'cocoa',
  'cocos',
  'cocou',
  'cocre',
  'cocta',
  'cocto',
  'codal',
  'codao',
  'codea',
  'codeo',
  'codex',
  'codio',
  'codon',
  'codra',
  'coege',
  'coeis',
  'coemo',
  'coesa',
  'coeso',
  'coeva',
  'coevo',
  'cofap',
  'cofea',
  'cofia',
  'cofio',
  'cofre',
  'coger',
  'cogia',
  'cohab',
  'coiba',
  'coibe',
  'coibi',
  'coibo',
  'coice',
  'coifa',
  'coiol',
  'coios',
  'coiro',
  'coisa',
  'coite',
  'coito',
  'colai',
  'colam',
  'colar',
  'colas',
  'colau',
  'colco',
  'colei',
  'colem',
  'coleo',
  'coles',
  'colha',
  'colhe',
  'colhi',
  'colho',
  'colia',
  'colim',
  'colir',
  'colon',
  'color',
  'colos',
  'colou',
  'colpo',
  'colua',
  'colva',
  'colza',
  'comam',
  'comao',
  'comas',
  'combe',
  'combi',
  'combo',
  'comea',
  'comei',
  'comem',
  'comer',
  'comes',
  'comeu',
  'comia',
  'comil',
  'comio',
  'compo',
  'comua',
  'comum',
  'conab',
  'conan',
  'conap',
  'conca',
  'conde',
  'cones',
  'conga',
  'congo',
  'conho',
  'conia',
  'conin',
  'conio',
  'conja',
  'conta',
  'conte',
  'conto',
  'cooge',
  'copal',
  'copar',
  'copas',
  'copel',
  'copia',
  'copie',
  'copio',
  'copla',
  'copos',
  'coppe',
  'copta',
  'copte',
  'copto',
  'corai',
  'coral',
  'coram',
  'corao',
  'corar',
  'coras',
  'corba',
  'corca',
  'corco',
  'corda',
  'cordo',
  'corei',
  'corem',
  'cores',
  'coreu',
  'corgo',
  'coria',
  'coril',
  'corio',
  'coris',
  'corja',
  'corla',
  'cormo',
  'corne',
  'corno',
  'coroa',
  'coroe',
  'coroo',
  'coros',
  'corou',
  'corpo',
  'corra',
  'corre',
  'corri',
  'corro',
  'corsi',
  'corso',
  'corta',
  'corte',
  'corti',
  'corto',
  'corvo',
  'cosam',
  'cosas',
  'cosco',
  'cosei',
  'cosem',
  'coser',
  'coses',
  'coseu',
  'cosia',
  'cosme',
  'cosmo',
  'cospe',
  'cossi',
  'cosso',
  'costa',
  'costo',
  'cotai',
  'cotam',
  'cotao',
  'cotar',
  'cotas',
  'cotei',
  'cotem',
  'cotes',
  'cotia',
  'cotil',
  'cotim',
  'cotio',
  'cotos',
  'cotou',
  'cotra',
  'coube',
  'couca',
  'couce',
  'couco',
  'couma',
  'coupe',
  'couro',
  'cousa',
  'couto',
  'couve',
  'couzo',
  'coval',
  'covao',
  'covas',
  'covil',
  'covoa',
  'coxal',
  'coxao',
  'coxas',
  'coxia',
  'coxim',
  'coxos',
  'cozam',
  'cozas',
  'cozei',
  'cozem',
  'cozer',
  'cozes',
  'cozeu',
  'cozia',
  'craca',
  'crack',
  'crada',
  'crapo',
  'crase',
  'crato',
  'craua',
  'crava',
  'crave',
  'cravo',
  'cream',
  'crede',
  'credo',
  'creem',
  'crega',
  'creia',
  'creie',
  'creio',
  'crema',
  'creme',
  'cremo',
  'crepe',
  'crera',
  'creso',
  'creta',
  'crete',
  'creto',
  'creve',
  'criai',
  'criam',
  'criar',
  'crias',
  'crico',
  'crida',
  'crido',
  'criei',
  'criem',
  'cries',
  'crila',
  'crime',
  'crina',
  'crino',
  'criou',
  'crise',
  'criso',
  'criva',
  'crive',
  'crivo',
  'crmmg',
  'croce',
  'croco',
  'croio',
  'croma',
  'crome',
  'cromo',
  'crono',
  'crota',
  'cruas',
  'cruca',
  'crude',
  'crudo',
  'cruel',
  'cruga',
  'crume',
  'cruor',
  'crupe',
  'cruro',
  'cruto',
  'cruza',
  'cruze',
  'cruzi',
  'cruzo',
  'ctasp',
  'ctena',
  'cuaca',
  'cuaco',
  'cuada',
  'cuaga',
  'cuaje',
  'cuale',
  'cualo',
  'cuane',
  'cuapa',
  'cuara',
  'cuari',
  'cuaro',
  'cuata',
  'cuati',
  'cuaxi',
  'cubal',
  'cubar',
  'cubas',
  'cubem',
  'cubie',
  'cubio',
  'cubiu',
  'cubla',
  'cubos',
  'cubra',
  'cubre',
  'cubro',
  'cucal',
  'cucar',
  'cucas',
  'cuche',
  'cuchi',
  'cucho',
  'cucio',
  'cucos',
  'cucri',
  'cudia',
  'cudzu',
  'cueba',
  'cueca',
  'cuele',
  'cuena',
  'cuera',
  'cufar',
  'cufea',
  'cufia',
  'cugar',
  'cuiao',
  'cuias',
  'cuiba',
  'cuica',
  'cuice',
  'cuida',
  'cuide',
  'cuido',
  'cuine',
  'cuini',
  'cuipe',
  'cuira',
  'cuita',
  'cuite',
  'cuito',
  'cujas',
  'cujos',
  'cujus',
  'culeo',
  'culex',
  'culna',
  'culpa',
  'culpe',
  'culpo',
  'culta',
  'culto',
  'cumai',
  'cumas',
  'cumba',
  'cumbe',
  'cumbo',
  'cumbu',
  'cumel',
  'cumes',
  'cumim',
  'cumis',
  'cumpo',
  'cunau',
  'cunco',
  'cuneo',
  'cunha',
  'cunhe',
  'cunho',
  'cunia',
  'cunto',
  'cuobe',
  'cupai',
  'cupao',
  'cupez',
  'cupim',
  'cupio',
  'cupom',
  'curai',
  'curam',
  'curao',
  'curar',
  'curas',
  'curau',
  'curca',
  'curdo',
  'curei',
  'curem',
  'cures',
  'curia',
  'curie',
  'curii',
  'curio',
  'curou',
  'curre',
  'curro',
  'curry',
  'cursa',
  'curse',
  'curso',
  'curta',
  'curte',
  'curti',
  'curto',
  'curua',
  'curul',
  'curva',
  'curve',
  'curvo',
  'cusco',
  'cuscu',
  'cusma',
  'cuspa',
  'cuspe',
  'cuspi',
  'cuspo',
  'cusso',
  'custa',
  'custe',
  'custo',
  'cutao',
  'cuter',
  'cutia',
  'cutim',
  'cutis',
  'cutiu',
  'cutra',
  'cuxiu',
  'cviii',
  'cxcii',
  'cxciv',
  'cxcix',
  'cxcvi',
  'cxiii',
  'cxlii',
  'cxliv',
  'cxlix',
  'cxlvi',
  'cxvii',
  'cxxii',
  'cxxiv',
  'cxxix',
  'cxxvi',
  'cxxxi',
  'cxxxv',
  'dabio',
  'dabom',
  'dabua',
  'dacao',
  'dacar',
  'dacia',
  'dacio',
  'dacma',
  'dacoa',
  'dacpo',
  'dadas',
  'dados',
  'dadra',
  'dafne',
  'dagas',
  'dagom',
  'daima',
  'daime',
  'daiza',
  'dakar',
  'dalai',
  'dalan',
  'dalem',
  'dalgo',
  'dalia',
  'dalmo',
  'dalva',
  'dalvo',
  'damao',
  'damar',
  'damas',
  'dambe',
  'damos',
  'dampa',
  'danai',
  'danam',
  'danar',
  'danas',
  'danca',
  'dance',
  'danco',
  'dande',
  'dandi',
  'dando',
  'danei',
  'danem',
  'danes',
  'danos',
  'danou',
  'danta',
  'dante',
  'daome',
  'daqui',
  'darao',
  'daras',
  'darci',
  'dardo',
  'darei',
  'darem',
  'dares',
  'daria',
  'dario',
  'darma',
  'daros',
  'dassa',
  'datai',
  'datal',
  'datam',
  'datar',
  'datas',
  'datei',
  'datem',
  'dates',
  'datil',
  'datou',
  'davam',
  'davas',
  'david',
  'dayse',
  'dccci',
  'dcccl',
  'dcccv',
  'dcccx',
  'dccii',
  'dcciv',
  'dccix',
  'dccli',
  'dcclv',
  'dcclx',
  'dccvi',
  'dccxc',
  'dccxi',
  'dccxl',
  'dccxv',
  'dccxx',
  'dciii',
  'dclii',
  'dcliv',
  'dclix',
  'dclvi',
  'dclxi',
  'dclxv',
  'dclxx',
  'dcvii',
  'dcxci',
  'dcxcv',
  'dcxii',
  'dcxiv',
  'dcxix',
  'dcxli',
  'dcxlv',
  'dcxvi',
  'dcxxi',
  'dcxxv',
  'dcxxx',
  'deado',
  'debar',
  'debil',
  'decai',
  'decil',
  'decio',
  'dedal',
  'dedao',
  'dedar',
  'dedos',
  'deduz',
  'deise',
  'deita',
  'deite',
  'deito',
  'deixa',
  'deixe',
  'deixo',
  'delas',
  'deles',
  'delio',
  'delir',
  'delma',
  'delon',
  'delta',
  'delza',
  'demao',
  'demar',
  'dembe',
  'dembo',
  'demia',
  'demos',
  'dende',
  'dendo',
  'dengo',
  'denis',
  'densa',
  'denso',
  'dente',
  'depoe',
  'depor',
  'depos',
  'depus',
  'deram',
  'deras',
  'derbi',
  'derem',
  'deres',
  'derme',
  'dermo',
  'derre',
  'desar',
  'desca',
  'desce',
  'desci',
  'desco',
  'desde',
  'desma',
  'desmo',
  'despe',
  'despi',
  'dessa',
  'desse',
  'desta',
  'deste',
  'detem',
  'deter',
  'deteu',
  'detre',
  'deusa',
  'devam',
  'devas',
  'devei',
  'devem',
  'dever',
  'deves',
  'deveu',
  'devia',
  'devio',
  'devir',
  'dewar',
  'dexia',
  'dhabi',
  'diabo',
  'diaco',
  'diade',
  'diafa',
  'diale',
  'diana',
  'diane',
  'diara',
  'diate',
  'diazo',
  'dicao',
  'dicar',
  'dicas',
  'dicaz',
  'dicha',
  'didea',
  'didio',
  'didis',
  'dieco',
  'diego',
  'dieno',
  'diese',
  'dieta',
  'difia',
  'difos',
  'digam',
  'digar',
  'digas',
  'digna',
  'digne',
  'digno',
  'dilma',
  'dilua',
  'dilui',
  'diluo',
  'dilza',
  'dimal',
  'dimba',
  'dimeu',
  'dinar',
  'dinas',
  'dinca',
  'dindo',
  'dingo',
  'dinie',
  'dinio',
  'dinis',
  'diniz',
  'diodo',
  'dioga',
  'diogo',
  'dioma',
  'diona',
  'dione',
  'diopa',
  'diope',
  'diore',
  'diose',
  'dioso',
  'diote',
  'diple',
  'diplo',
  'dipso',
  'dique',
  'dirao',
  'diras',
  'dirca',
  'dirce',
  'direi',
  'direm',
  'dirfo',
  'diria',
  'dirra',
  'disca',
  'disco',
  'disel',
  'disga',
  'disna',
  'dispo',
  'disse',
  'disso',
  'dista',
  'diste',
  'disto',
  'disue',
  'ditai',
  'ditam',
  'ditar',
  'ditas',
  'ditei',
  'ditem',
  'dites',
  'ditos',
  'ditou',
  'ditua',
  'diula',
  'diuli',
  'diuso',
  'diuta',
  'diuti',
  'divao',
  'divas',
  'dixis',
  'dizei',
  'dizem',
  'dizer',
  'dizes',
  'dizia',
  'djapa',
  'djico',
  'djola',
  'dliii',
  'dlvii',
  'dlxii',
  'dlxiv',
  'dlxix',
  'dlxvi',
  'dlxxi',
  'dlxxv',
  'dlxxx',
  'doada',
  'doado',
  'doais',
  'doamo',
  'doara',
  'doava',
  'dobar',
  'doble',
  'dobra',
  'dobre',
  'dobro',
  'docal',
  'docar',
  'docas',
  'docem',
  'doces',
  'docil',
  'docto',
  'dodoi',
  'dodol',
  'doeis',
  'doemo',
  'doera',
  'dogal',
  'dogas',
  'dogma',
  'dogon',
  'dogre',
  'dogue',
  'dohrn',
  'doiam',
  'doias',
  'doida',
  'doido',
  'doilo',
  'dolar',
  'dolce',
  'dolim',
  'dolio',
  'dolma',
  'dolor',
  'domai',
  'domam',
  'domar',
  'domas',
  'dombe',
  'dombo',
  'domei',
  'domem',
  'domes',
  'domno',
  'domos',
  'domou',
  'donas',
  'donax',
  'donde',
  'dondo',
  'donez',
  'dongo',
  'donia',
  'donos',
  'donte',
  'dopai',
  'dopam',
  'dopar',
  'dopas',
  'dopei',
  'dopem',
  'dopes',
  'dopou',
  'dorco',
  'dorea',
  'dores',
  'doria',
  'dorio',
  'dorir',
  'dorme',
  'dormi',
  'dormo',
  'dorna',
  'dorso',
  'dosai',
  'dosam',
  'dosar',
  'dosas',
  'dosei',
  'dosem',
  'doses',
  'dosou',
  'dotai',
  'dotal',
  'dotam',
  'dotar',
  'dotas',
  'dotei',
  'dotem',
  'dotes',
  'dotim',
  'dotou',
  'doudo',
  'doula',
  'doulo',
  'doura',
  'doure',
  'douro',
  'douta',
  'douto',
  'dozao',
  'dozem',
  'draga',
  'drago',
  'drama',
  'drena',
  'drene',
  'dreno',
  'drica',
  'drive',
  'droga',
  'drogo',
  'drupa',
  'druso',
  'duais',
  'dualo',
  'duana',
  'dubai',
  'dubia',
  'dubio',
  'dubla',
  'duble',
  'dublo',
  'ducal',
  'ducha',
  'ducra',
  'ducto',
  'dueca',
  'duela',
  'duele',
  'duelo',
  'duere',
  'dueto',
  'dugao',
  'dugni',
  'duila',
  'duina',
  'duipa',
  'duita',
  'dulce',
  'dulia',
  'dumas',
  'dumba',
  'dumbo',
  'dunam',
  'dunar',
  'dunas',
  'dundu',
  'dunfa',
  'dunga',
  'dungo',
  'dunha',
  'dunia',
  'dupla',
  'duplo',
  'duque',
  'durai',
  'dural',
  'duram',
  'durao',
  'durar',
  'duras',
  'duraz',
  'durei',
  'durem',
  'dures',
  'durex',
  'durez',
  'durga',
  'durim',
  'durio',
  'durma',
  'durmo',
  'durne',
  'durol',
  'duros',
  'durou',
  'durra',
  'dutis',
  'dutos',
  'dutra',
  'dutro',
  'duvol',
  'duzia',
  'duzil',
  'duzir',
  'dviii',
  'dxcii',
  'dxciv',
  'dxcix',
  'dxcvi',
  'dxiii',
  'dxlii',
  'dxliv',
  'dxlix',
  'dxlvi',
  'dxvii',
  'dxxii',
  'dxxiv',
  'dxxix',
  'dxxvi',
  'dxxxi',
  'dxxxv',
  'dzeta',
  'eanes',
  'eanto',
  'earia',
  'ebame',
  'ebami',
  'ebani',
  'ebano',
  'ebeno',
  'ebiri',
  'eblis',
  'ebola',
  'ebome',
  'ebomi',
  'ebrio',
  'ebulo',
  'ecano',
  'ecapa',
  'ecato',
  'ecese',
  'ecfma',
  'ecico',
  'ecler',
  'ecoai',
  'ecoam',
  'ecoar',
  'ecoas',
  'ecoei',
  'ecoem',
  'ecoes',
  'ecoou',
  'ecope',
  'ecoto',
  'ecrom',
  'ectal',
  'ectol',
  'ecubo',
  'eculo',
  'ecuru',
  'edace',
  'edalo',
  'edeia',
  'edema',
  'edens',
  'edere',
  'edgar',
  'edico',
  'edila',
  'edime',
  'edina',
  'edipo',
  'edita',
  'edite',
  'edito',
  'edjan',
  'edmar',
  'ednei',
  'ednie',
  'edoma',
  'edono',
  'edrei',
  'edson',
  'educa',
  'educo',
  'edule',
  'edulo',
  'eduto',
  'edvan',
  'efata',
  'efebo',
  'efeso',
  'efeta',
  'efipo',
  'efiro',
  'efode',
  'efodo',
  'eforo',
  'efrem',
  'efuco',
  'efues',
  'efuns',
  'efuso',
  'egeao',
  'egesa',
  'egide',
  'egipa',
  'egito',
  'egrio',
  'eguar',
  'eguas',
  'eguci',
  'eguem',
  'eicar',
  'eiche',
  'eicho',
  'eider',
  'eimar',
  'eiras',
  'eivao',
  'eivar',
  'eixar',
  'eixos',
  'ejeta',
  'ejete',
  'ejeto',
  'elafa',
  'elafo',
  'elami',
  'elane',
  'elano',
  'elate',
  'elbio',
  'elche',
  'elcio',
  'elder',
  'elear',
  'elebo',
  'eleda',
  'elede',
  'elege',
  'elegi',
  'eleis',
  'eleja',
  'elejo',
  'elemi',
  'eleni',
  'elepe',
  'eleto',
  'eleva',
  'eleve',
  'elevo',
  'elias',
  'elice',
  'elico',
  'eliel',
  'elime',
  'elimo',
  'elina',
  'eliri',
  'elisa',
  'elise',
  'elite',
  'eliud',
  'elixa',
  'elixe',
  'elixi',
  'elixo',
  'elmar',
  'elmer',
  'elmos',
  'eloar',
  'eloim',
  'elona',
  'elope',
  'elson',
  'elton',
  'elueu',
  'eluir',
  'eluro',
  'elvia',
  'elvis',
  'emamo',
  'emana',
  'emane',
  'emano',
  'emapu',
  'emaus',
  'embeu',
  'embia',
  'embiu',
  'emboa',
  'embua',
  'embui',
  'embuu',
  'emebe',
  'emeio',
  'emerj',
  'emero',
  'emese',
  'emexo',
  'emfim',
  'emico',
  'emide',
  'emile',
  'emins',
  'emita',
  'emite',
  'emiti',
  'emito',
  'emoco',
  'emoto',
  'empar',
  'empis',
  'empos',
  'emula',
  'emule',
  'emulo',
  'enalo',
  'enano',
  'enase',
  'encha',
  'enche',
  'enchi',
  'encho',
  'enchu',
  'endes',
  'endez',
  'endro',
  'endua',
  'eneas',
  'enema',
  'eneus',
  'enfia',
  'enfie',
  'enfim',
  'enfio',
  'engar',
  'engos',
  'enibu',
  'enico',
  'enino',
  'enito',
  'enjoa',
  'enjoe',
  'enjoo',
  'enoja',
  'enoje',
  'enojo',
  'enomo',
  'enone',
  'enora',
  'enque',
  'ensis',
  'entao',
  'enter',
  'entes',
  'enteu',
  'entoa',
  'entoe',
  'entoo',
  'entra',
  'entre',
  'entro',
  'enula',
  'enves',
  'envia',
  'envie',
  'envio',
  'enxui',
  'enxus',
  'eoipo',
  'eolia',
  'eolio',
  'eones',
  'epata',
  'epeia',
  'epena',
  'epica',
  'epico',
  'epiro',
  'epoca',
  'epodo',
  'epoxi',
  'epula',
  'epura',
  'epuxa',
  'equeu',
  'equio',
  'equis',
  'erano',
  'erati',
  'erato',
  'erbia',
  'erbio',
  'erebo',
  'ereis',
  'erere',
  'ereso',
  'ereta',
  'ereto',
  'ergam',
  'ergas',
  'ergia',
  'ergio',
  'ergol',
  'ergue',
  'ergui',
  'erica',
  'erice',
  'erico',
  'erige',
  'erigi',
  'erija',
  'erijo',
  'erina',
  'erino',
  'erion',
  'ermal',
  'ermar',
  'ermos',
  'eroca',
  'eroda',
  'erode',
  'erodi',
  'erodo',
  'eroge',
  'erone',
  'erope',
  'eroso',
  'errai',
  'erram',
  'errar',
  'erras',
  'errei',
  'errem',
  'erres',
  'error',
  'erros',
  'errou',
  'eruca',
  'eruga',
  'eruir',
  'eruru',
  'erval',
  'ervar',
  'ervas',
  'escoa',
  'escoe',
  'escol',
  'escoo',
  'esdra',
  'esere',
  'esfex',
  'esgar',
  'esima',
  'esimo',
  'esipo',
  'esmar',
  'esoce',
  'esopo',
  'espia',
  'espie',
  'espim',
  'espio',
  'espru',
  'esqui',
  'esron',
  'essas',
  'esses',
  'essuo',
  'estai',
  'estao',
  'estar',
  'estas',
  'estau',
  'ester',
  'estes',
  'estio',
  'estol',
  'estou',
  'estro',
  'esula',
  'esvai',
  'esvao',
  'etano',
  'etapa',
  'etego',
  'etemo',
  'eteno',
  'etera',
  'ethos',
  'etica',
  'etico',
  'etigo',
  'etilo',
  'etimo',
  'etino',
  'etipe',
  'etite',
  'etito',
  'etnia',
  'etusa',
  'etutu',
  'eubeu',
  'eubio',
  'eudes',
  'eudia',
  'eudor',
  'euler',
  'eumbo',
  'euria',
  'eurio',
  'evada',
  'evade',
  'evadi',
  'evado',
  'evair',
  'evale',
  'evano',
  'evase',
  'evigo',
  'evipa',
  'evita',
  'evite',
  'evito',
  'evoca',
  'evoco',
  'evodo',
  'evora',
  'exaco',
  'exala',
  'exale',
  'exalo',
  'exame',
  'exara',
  'exare',
  'exaro',
  'exata',
  'exato',
  'exiba',
  'exibe',
  'exibi',
  'exibo',
  'exido',
  'exige',
  'exigi',
  'exija',
  'exijo',
  'exila',
  'exile',
  'exilo',
  'exima',
  'exime',
  'eximi',
  'eximo',
  'exina',
  'exito',
  'exodo',
  'expia',
  'expie',
  'expio',
  'expoe',
  'expor',
  'expos',
  'expus',
  'extra',
  'exule',
  'exuma',
  'exume',
  'exumo',
  'eziom',
  'fabal',
  'faber',
  'fabio',
  'fabro',
  'facam',
  'facao',
  'facas',
  'faces',
  'fachi',
  'facho',
  'facie',
  'facil',
  'facto',
  'fadar',
  'fadas',
  'fadia',
  'fadim',
  'fados',
  'faece',
  'fagal',
  'fagar',
  'fagea',
  'fagia',
  'faial',
  'faiao',
  'faiar',
  'faias',
  'faido',
  'faina',
  'faire',
  'faite',
  'faixa',
  'fajau',
  'fajea',
  'falai',
  'falam',
  'falar',
  'falas',
  'falaz',
  'falci',
  'falco',
  'falda',
  'faleg',
  'falei',
  'falem',
  'fales',
  'falha',
  'falhe',
  'falho',
  'falia',
  'falir',
  'falis',
  'faliu',
  'faloa',
  'falou',
  'falsa',
  'falso',
  'falta',
  'falte',
  'falto',
  'falua',
  'falum',
  'falus',
  'faluz',
  'famas',
  'fanal',
  'fanao',
  'fanar',
  'fanca',
  'fando',
  'faneu',
  'fanfa',
  'fanga',
  'fanho',
  'fanio',
  'fanoa',
  'fanta',
  'fante',
  'fanti',
  'faqui',
  'farad',
  'farao',
  'farar',
  'faras',
  'faraz',
  'farda',
  'fardo',
  'farei',
  'farel',
  'fareu',
  'farfa',
  'faria',
  'fario',
  'farme',
  'farno',
  'faroe',
  'farol',
  'faros',
  'farpa',
  'farra',
  'farsa',
  'farta',
  'farte',
  'farto',
  'farum',
  'fasco',
  'faseo',
  'fases',
  'fasia',
  'fasma',
  'fasor',
  'fatal',
  'fatao',
  'fatia',
  'fatie',
  'fatio',
  'fator',
  'fatos',
  'fatuo',
  'fauce',
  'faula',
  'fauna',
  'fauno',
  'faval',
  'favao',
  'favar',
  'favas',
  'favio',
  'favor',
  'favos',
  'faxes',
  'fazei',
  'fazem',
  'fazer',
  'fazes',
  'fazia',
  'feace',
  'feaco',
  'febeu',
  'febra',
  'febre',
  'fecal',
  'fecha',
  'feche',
  'fecho',
  'fedam',
  'fedas',
  'fedei',
  'fedem',
  'feder',
  'fedes',
  'fedeu',
  'fedia',
  'fedor',
  'fedra',
  'feiao',
  'feias',
  'feijo',
  'feila',
  'feios',
  'feira',
  'feita',
  'feito',
  'feixe',
  'felar',
  'feleo',
  'feles',
  'feleu',
  'felga',
  'felix',
  'feliz',
  'femea',
  'femeo',
  'femte',
  'femto',
  'femur',
  'fenaj',
  'fenal',
  'fenar',
  'fenda',
  'fende',
  'fendi',
  'fendo',
  'fenim',
  'fenix',
  'fenol',
  'fenos',
  'fento',
  'feofo',
  'feona',
  'feoso',
  'feral',
  'feras',
  'feraz',
  'ferem',
  'feres',
  'fereu',
  'ferga',
  'feria',
  'ferir',
  'feris',
  'feriu',
  'fermi',
  'fermo',
  'feroz',
  'ferpa',
  'ferra',
  'ferre',
  'ferro',
  'ferto',
  'ferva',
  'ferve',
  'fervi',
  'fervo',
  'festa',
  'fetag',
  'fetal',
  'fetao',
  'fetim',
  'fetos',
  'fetus',
  'feudo',
  'fevra',
  'fezal',
  'fezes',
  'fiaco',
  'fiada',
  'fiado',
  'fiais',
  'fiala',
  'fiamo',
  'fiapo',
  'fiara',
  'fiava',
  'fiber',
  'fibra',
  'fibro',
  'ficai',
  'ficam',
  'ficao',
  'ficar',
  'ficas',
  'ficeo',
  'ficha',
  'fiche',
  'ficho',
  'fichu',
  'ficis',
  'ficou',
  'ficto',
  'ficus',
  'fidel',
  'fideu',
  'fidia',
  'fidji',
  'fieis',
  'fiemo',
  'fiesp',
  'fieza',
  'fifia',
  'figar',
  'figas',
  'figle',
  'figos',
  'filai',
  'filam',
  'filao',
  'filar',
  'filas',
  'filei',
  'filem',
  'fileo',
  'filer',
  'files',
  'filha',
  'filho',
  'filia',
  'filie',
  'filio',
  'filma',
  'filme',
  'filmo',
  'filos',
  'filou',
  'fimbo',
  'fimia',
  'fimpi',
  'final',
  'finar',
  'finas',
  'finca',
  'finco',
  'finda',
  'finde',
  'findo',
  'fines',
  'finge',
  'fingi',
  'fingo',
  'finja',
  'finjo',
  'finos',
  'finta',
  'finte',
  'finto',
  'fiofo',
  'fiose',
  'fiote',
  'fioto',
  'fique',
  'firam',
  'firas',
  'firma',
  'firme',
  'firmo',
  'firpo',
  'fisal',
  'fisco',
  'fisga',
  'fisgo',
  'fisio',
  'fisma',
  'fitai',
  'fitam',
  'fitao',
  'fitar',
  'fitas',
  'fitei',
  'fitem',
  'fites',
  'fitol',
  'fitou',
  'fiusa',
  'fiuzo',
  'fixai',
  'fixam',
  'fixar',
  'fixas',
  'fixei',
  'fixem',
  'fixes',
  'fixez',
  'fixos',
  'fixou',
  'fizer',
  'flaco',
  'flama',
  'flape',
  'flare',
  'flash',
  'flato',
  'flavo',
  'flebe',
  'flebo',
  'fleme',
  'flexo',
  'flieu',
  'floco',
  'flogo',
  'flome',
  'flora',
  'flori',
  'flosa',
  'floxo',
  'flozo',
  'fluam',
  'fluas',
  'fluem',
  'fluia',
  'fluir',
  'fluis',
  'fluiu',
  'flume',
  'fluor',
  'fluta',
  'fluxo',
  'fobar',
  'fobia',
  'focai',
  'focal',
  'focam',
  'focao',
  'focar',
  'focas',
  'foceu',
  'focho',
  'focio',
  'focos',
  'focou',
  'focus',
  'foder',
  'fodia',
  'foeta',
  'fofar',
  'fofas',
  'fofos',
  'fogal',
  'fogao',
  'fogar',
  'fogem',
  'foges',
  'fogos',
  'foice',
  'foide',
  'foila',
  'foina',
  'foito',
  'folao',
  'folar',
  'foles',
  'folga',
  'folgo',
  'folha',
  'folho',
  'folia',
  'folio',
  'folis',
  'foloe',
  'fomes',
  'fomos',
  'fonao',
  'fones',
  'fonia',
  'fonix',
  'fonjo',
  'fonon',
  'fonte',
  'foque',
  'foral',
  'foram',
  'foras',
  'forca',
  'force',
  'forco',
  'forde',
  'fordo',
  'forem',
  'fores',
  'foria',
  'forja',
  'forje',
  'forjo',
  'forma',
  'forme',
  'formi',
  'formo',
  'forno',
  'foros',
  'forra',
  'forre',
  'forro',
  'forte',
  'forum',
  'fosca',
  'fosco',
  'fosga',
  'fosia',
  'fossa',
  'fosse',
  'fosso',
  'foste',
  'fotao',
  'foton',
  'fotos',
  'fouce',
  'foula',
  'foupa',
  'fovea',
  'foxim',
  'fraca',
  'fraco',
  'frade',
  'fraga',
  'frago',
  'frama',
  'frape',
  'frase',
  'freai',
  'frear',
  'freei',
  'frege',
  'freia',
  'freie',
  'freio',
  'freis',
  'freme',
  'fremi',
  'freni',
  'freon',
  'freou',
  'fresa',
  'frese',
  'freso',
  'freta',
  'frete',
  'freto',
  'freud',
  'frevo',
  'frial',
  'frias',
  'friez',
  'frige',
  'frigi',
  'frigo',
  'frija',
  'frijo',
  'frila',
  'frimo',
  'frine',
  'frino',
  'frios',
  'frisa',
  'frise',
  'friso',
  'frita',
  'frite',
  'frito',
  'friul',
  'frixo',
  'froco',
  'froge',
  'frois',
  'frota',
  'frozo',
  'fruam',
  'fruas',
  'fruem',
  'fruia',
  'fruir',
  'fruis',
  'fruiu',
  'fruro',
  'fruta',
  'fruto',
  'fruxu',
  'fucai',
  'fucal',
  'fucam',
  'fucar',
  'fucas',
  'fucea',
  'fucei',
  'fucem',
  'fuces',
  'fucia',
  'fucim',
  'fucou',
  'fucus',
  'fueta',
  'fufio',
  'fugao',
  'fugar',
  'fugas',
  'fugaz',
  'fugel',
  'fugia',
  'fugir',
  'fugis',
  'fugiu',
  'fujam',
  'fujao',
  'fujas',
  'fujem',
  'fujes',
  'fujia',
  'fujir',
  'fujis',
  'fujiu',
  'fulao',
  'fular',
  'fulas',
  'fulbe',
  'fulda',
  'fuler',
  'fulge',
  'fulgi',
  'fulha',
  'fulix',
  'fulos',
  'fulvo',
  'fumai',
  'fumal',
  'fumam',
  'fumao',
  'fumar',
  'fumas',
  'fumbo',
  'fumei',
  'fumem',
  'fumeo',
  'fumes',
  'fumos',
  'fumou',
  'funar',
  'funce',
  'funco',
  'funda',
  'funde',
  'fundi',
  'fundo',
  'funel',
  'funeu',
  'funga',
  'funge',
  'fungo',
  'funil',
  'funis',
  'funje',
  'fuque',
  'furai',
  'fural',
  'furam',
  'furao',
  'furar',
  'furas',
  'furco',
  'furda',
  'furei',
  'furem',
  'fures',
  'furia',
  'furil',
  'furna',
  'furor',
  'furos',
  'furou',
  'furta',
  'furte',
  'furto',
  'fusao',
  'fusar',
  'fusca',
  'fusco',
  'fusel',
  'fusil',
  'fusor',
  'fusos',
  'fuste',
  'fusto',
  'futil',
  'futre',
  'futum',
  'fuzil',
  'fuzis',
  'fuzue',
  'gabai',
  'gabam',
  'gabao',
  'gabar',
  'gabas',
  'gabei',
  'gabem',
  'gabes',
  'gabeu',
  'gabio',
  'gabou',
  'gabro',
  'gacho',
  'gadao',
  'gadar',
  'gados',
  'gaeta',
  'gafar',
  'gafem',
  'gafio',
  'gagao',
  'gagas',
  'gagau',
  'gages',
  'gagos',
  'gague',
  'gaial',
  'gaiar',
  'gaios',
  'gaipo',
  'gairo',
  'gaita',
  'gaiza',
  'gajao',
  'gajar',
  'gajas',
  'gajos',
  'galao',
  'galar',
  'galas',
  'galax',
  'galbo',
  'galdo',
  'galeo',
  'gales',
  'galga',
  'galgo',
  'galha',
  'galho',
  'galia',
  'galio',
  'galos',
  'gamao',
  'gamar',
  'gamba',
  'gambo',
  'gambu',
  'gamia',
  'gamos',
  'ganau',
  'gando',
  'gandu',
  'ganem',
  'ganes',
  'ganga',
  'gango',
  'ganha',
  'ganhe',
  'ganho',
  'gania',
  'ganim',
  'ganir',
  'ganis',
  'ganiu',
  'ganiz',
  'ganja',
  'gansa',
  'ganso',
  'ganta',
  'ganto',
  'ganza',
  'ganze',
  'ganzi',
  'garao',
  'garau',
  'garbo',
  'garca',
  'garco',
  'garde',
  'garfa',
  'garfe',
  'garfo',
  'gargo',
  'garis',
  'garoa',
  'garoe',
  'garoo',
  'garra',
  'garvo',
  'gaseo',
  'gases',
  'gaspa',
  'gasta',
  'gaste',
  'gasto',
  'gatal',
  'gatao',
  'gatar',
  'gatas',
  'gateu',
  'gatil',
  'gatos',
  'gatum',
  'gaudo',
  'gaulo',
  'gauro',
  'gauss',
  'gauza',
  'gavao',
  'gavar',
  'gavea',
  'gazal',
  'gazao',
  'gazar',
  'gazel',
  'gazeo',
  'gazeu',
  'gazia',
  'gazil',
  'gazio',
  'gazua',
  'geada',
  'geado',
  'geais',
  'geamo',
  'geara',
  'gearo',
  'gease',
  'geava',
  'gebal',
  'gebar',
  'gebia',
  'gebre',
  'geeis',
  'geemo',
  'geena',
  'geiam',
  'geias',
  'geiem',
  'geies',
  'geina',
  'gelai',
  'gelam',
  'gelar',
  'gelas',
  'gelba',
  'gelda',
  'gelei',
  'gelem',
  'geles',
  'gelfo',
  'gelha',
  'gelio',
  'gelis',
  'gelmo',
  'gelos',
  'gelou',
  'gelva',
  'gemam',
  'gemar',
  'gemas',
  'gemea',
  'gemei',
  'gemem',
  'gemeo',
  'gemer',
  'gemes',
  'gemeu',
  'gemia',
  'genal',
  'genes',
  'genge',
  'genia',
  'genio',
  'genoa',
  'genol',
  'genro',
  'gente',
  'geode',
  'geodo',
  'geoso',
  'gerai',
  'geral',
  'geram',
  'gerar',
  'geras',
  'gerbi',
  'gerbo',
  'gerei',
  'gerem',
  'geres',
  'geria',
  'gerir',
  'geris',
  'geriu',
  'germe',
  'germo',
  'gerno',
  'geron',
  'gerou',
  'gerre',
  'gesio',
  'gesso',
  'gesta',
  'gesto',
  'geteu',
  'giaur',
  'gibao',
  'gibea',
  'gibio',
  'gibli',
  'gicle',
  'giclo',
  'gidel',
  'gidio',
  'gigia',
  'gigis',
  'gigle',
  'gilas',
  'gildo',
  'gilia',
  'gimao',
  'gimbo',
  'gimel',
  'ginco',
  'gindo',
  'gines',
  'ginga',
  'ginge',
  'gingo',
  'ginja',
  'giota',
  'gipso',
  'girai',
  'giram',
  'girao',
  'girar',
  'giras',
  'girau',
  'girei',
  'girem',
  'gires',
  'gireu',
  'giria',
  'girio',
  'giros',
  'girou',
  'girua',
  'giteu',
  'gizar',
  'glace',
  'glaro',
  'gleba',
  'gleia',
  'glena',
  'glete',
  'glial',
  'glide',
  'glifo',
  'glino',
  'globo',
  'glomo',
  'glosa',
  'glose',
  'gloso',
  'glote',
  'gluao',
  'gluma',
  'gluon',
  'glute',
  'gneto',
  'gnomo',
  'gnose',
  'goane',
  'goano',
  'gobio',
  'gocho',
  'godao',
  'godel',
  'godes',
  'godia',
  'godoi',
  'godos',
  'godoy',
  'goela',
  'goete',
  'gofer',
  'gogos',
  'goiar',
  'goias',
  'goiim',
  'goita',
  'goiti',
  'goiva',
  'goivo',
  'golar',
  'golas',
  'goldi',
  'golea',
  'goles',
  'golfo',
  'golga',
  'golim',
  'golpe',
  'gomal',
  'gomar',
  'gomas',
  'gombe',
  'gombo',
  'gomes',
  'gomia',
  'gomil',
  'gomor',
  'gomos',
  'gonar',
  'gonde',
  'gondi',
  'gondo',
  'gonel',
  'gonga',
  'gongo',
  'gonio',
  'gonis',
  'gonzo',
  'gorai',
  'goral',
  'goram',
  'gorao',
  'gorar',
  'goras',
  'goraz',
  'gorca',
  'gorda',
  'gordo',
  'gorei',
  'gorem',
  'gores',
  'gorga',
  'gorja',
  'gorou',
  'gorro',
  'gosba',
  'gosma',
  'gospe',
  'gosta',
  'goste',
  'gosto',
  'gotao',
  'gotas',
  'gotra',
  'gotri',
  'gouda',
  'gouli',
  'goulo',
  'gouro',
  'gouve',
  'govar',
  'gozai',
  'gozam',
  'gozao',
  'gozar',
  'gozas',
  'gozei',
  'gozem',
  'gozes',
  'gozim',
  'gozos',
  'gozou',
  'graal',
  'grabe',
  'graca',
  'graco',
  'grade',
  'grado',
  'grafa',
  'grafe',
  'grafo',
  'graio',
  'grajo',
  'grama',
  'grana',
  'grand',
  'graos',
  'grapa',
  'grata',
  'grato',
  'graus',
  'grava',
  'grave',
  'gravo',
  'graxa',
  'graxo',
  'grebe',
  'grebo',
  'greco',
  'grede',
  'grega',
  'grege',
  'grego',
  'greia',
  'gremo',
  'grena',
  'grepo',
  'greva',
  'greve',
  'grias',
  'grifa',
  'grife',
  'grifo',
  'grila',
  'grile',
  'grilo',
  'grima',
  'gripe',
  'grise',
  'grisu',
  'grita',
  'grite',
  'grito',
  'grode',
  'groes',
  'groir',
  'grolo',
  'gromo',
  'grota',
  'grous',
  'groxo',
  'grual',
  'gruau',
  'gruda',
  'grude',
  'grudo',
  'gruim',
  'gruir',
  'grumo',
  'grupo',
  'gruta',
  'guaca',
  'guaco',
  'guacu',
  'guado',
  'guaia',
  'guaio',
  'guaiu',
  'guaja',
  'guaje',
  'guaju',
  'guama',
  'guame',
  'guana',
  'guano',
  'guape',
  'guapo',
  'guara',
  'guaru',
  'guato',
  'guaxe',
  'guaxi',
  'guaxo',
  'gubro',
  'guebo',
  'guede',
  'gueja',
  'gueli',
  'guere',
  'gueri',
  'guete',
  'gueto',
  'gueve',
  'guexa',
  'guiai',
  'guiam',
  'guiao',
  'guiar',
  'guias',
  'guiba',
  'guibe',
  'guibo',
  'guico',
  'guiei',
  'guiem',
  'guies',
  'guife',
  'guigo',
  'guina',
  'guine',
  'guiou',
  'guipa',
  'guira',
  'guiri',
  'guisa',
  'guiti',
  'guito',
  'guizo',
  'gular',
  'gulue',
  'gumba',
  'gumbe',
  'gumbo',
  'gumes',
  'gunda',
  'gunde',
  'gundi',
  'gundu',
  'gunga',
  'gungo',
  'gunho',
  'gunji',
  'gunto',
  'gural',
  'gurbi',
  'gurca',
  'gurde',
  'guria',
  'guris',
  'gurma',
  'gurra',
  'gusla',
  'gutar',
  'gutas',
  'habia',
  'habil',
  'habor',
  'habur',
  'hacer',
  'hadar',
  'haden',
  'hadji',
  'hadom',
  'hagar',
  'hagri',
  'haide',
  'haifa',
  'haiti',
  'hajam',
  'hajar',
  'hajas',
  'hajul',
  'halde',
  'haler',
  'halex',
  'halfe',
  'halia',
  'halim',
  'halis',
  'halma',
  'halos',
  'halul',
  'halux',
  'halva',
  'hamal',
  'hamas',
  'hamba',
  'hamim',
  'hamom',
  'hamsa',
  'handa',
  'hanes',
  'hango',
  'hanha',
  'hanio',
  'hanoa',
  'hanoi',
  'hansa',
  'haoma',
  'hapax',
  'haplo',
  'hapto',
  'harao',
  'haras',
  'harda',
  'harem',
  'harim',
  'harlo',
  'haroe',
  'harpa',
  'harto',
  'hartz',
  'hasem',
  'hasor',
  'haste',
  'hasum',
  'hatil',
  'hatim',
  'hauca',
  'haura',
  'haure',
  'hauri',
  'havai',
  'havei',
  'haver',
  'havia',
  'hazar',
  'hazel',
  'hazer',
  'hazim',
  'hazor',
  'heard',
  'heber',
  'hebro',
  'hecto',
  'hedia',
  'hedra',
  'heduo',
  'hefer',
  'hegar',
  'hegel',
  'heide',
  'heidi',
  'helam',
  'helba',
  'helem',
  'helen',
  'helez',
  'helga',
  'helio',
  'helix',
  'helma',
  'helom',
  'hemal',
  'hemam',
  'hemia',
  'hemor',
  'henda',
  'henoc',
  'henos',
  'henri',
  'henry',
  'hepia',
  'hepta',
  'heras',
  'herda',
  'herde',
  'herdo',
  'hereu',
  'heril',
  'herma',
  'heroi',
  'heroo',
  'heros',
  'herse',
  'herta',
  'hertz',
  'hesli',
  'hesse',
  'hetar',
  'heteu',
  'hetma',
  'hetol',
  'hevea',
  'heveu',
  'hexal',
  'hexil',
  'hexol',
  'hiala',
  'hialo',
  'hiato',
  'hiava',
  'hicso',
  'hidai',
  'hideo',
  'hidno',
  'hidra',
  'hidro',
  'hiena',
  'hiero',
  'hieto',
  'hifal',
  'hifen',
  'higgs',
  'higro',
  'hilal',
  'hilar',
  'hilde',
  'hildo',
  'hilel',
  'hilem',
  'hileu',
  'hilia',
  'hilmi',
  'himba',
  'himen',
  'hindi',
  'hindu',
  'hingo',
  'hinir',
  'hinom',
  'hinos',
  'hiper',
  'hipno',
  'hipte',
  'hiram',
  'hirao',
  'hirax',
  'hirco',
  'hiria',
  'hirom',
  'hirpo',
  'histo',
  'hoare',
  'hobby',
  'hofra',
  'hogla',
  'holao',
  'holco',
  'holom',
  'homao',
  'hombo',
  'homem',
  'homeo',
  'homer',
  'homia',
  'homum',
  'honda',
  'hongo',
  'honor',
  'honos',
  'honra',
  'honre',
  'honro',
  'hopea',
  'hopia',
  'hoplo',
  'horal',
  'horao',
  'horar',
  'horas',
  'horda',
  'horem',
  'horeu',
  'horma',
  'horom',
  'horra',
  'horsa',
  'horta',
  'horto',
  'hosco',
  'hosea',
  'hossi',
  'hoste',
  'hotao',
  'hotel',
  'hotir',
  'houri',
  'houve',
  'hovea',
  'https',
  'huala',
  'huama',
  'huane',
  'huari',
  'huela',
  'huido',
  'huila',
  'huini',
  'hulda',
  'hulha',
  'humam',
  'humbe',
  'humbi',
  'humbo',
  'humea',
  'humil',
  'humor',
  'humus',
  'hungo',
  'hunos',
  'hunta',
  'hupda',
  'hupim',
  'hurai',
  'hurao',
  'hurra',
  'husai',
  'husam',
  'husao',
  'husim',
  'husma',
  'husna',
  'iacio',
  'iacri',
  'iaera',
  'iafis',
  'iagua',
  'iaias',
  'iambe',
  'iambo',
  'iamem',
  'iameu',
  'iamos',
  'ianam',
  'iande',
  'iandu',
  'ianga',
  'iansa',
  'ianvo',
  'iaque',
  'iaras',
  'iasio',
  'iasis',
  'iatai',
  'iatal',
  'iates',
  'iatio',
  'iatro',
  'iauma',
  'iaupe',
  'iauvo',
  'ibale',
  'ibama',
  'ibaro',
  'ibase',
  'ibate',
  'ibeji',
  'ibema',
  'ibere',
  'ibero',
  'ibiai',
  'ibiam',
  'ibice',
  'ibira',
  'ibiri',
  'ibiro',
  'iblea',
  'iboga',
  'ibope',
  'iboza',
  'ibsao',
  'ibsen',
  'icaco',
  'icacu',
  'icada',
  'icado',
  'icais',
  'icala',
  'icamo',
  'icana',
  'icara',
  'icaro',
  'icatu',
  'icava',
  'icbal',
  'iceis',
  'icelo',
  'icemo',
  'iceno',
  'ichao',
  'icica',
  'icipo',
  'icolo',
  'icomo',
  'icone',
  'icono',
  'icore',
  'ictal',
  'ictio',
  'ictis',
  'ictus',
  'icuns',
  'icure',
  'idade',
  'idala',
  'idate',
  'idbas',
  'ideal',
  'idear',
  'ideia',
  'idolo',
  'idosa',
  'idose',
  'idoso',
  'idria',
  'idrol',
  'iduna',
  'ieixa',
  'ielmo',
  'iemen',
  'ienes',
  'iento',
  'ietim',
  'ifata',
  'igabo',
  'igaci',
  'igala',
  'igape',
  'igapo',
  'igara',
  'igbas',
  'igbim',
  'igeal',
  'igino',
  'ignea',
  'igneo',
  'igoga',
  'iguai',
  'igual',
  'iguar',
  'iguem',
  'igupa',
  'iiaba',
  'iicai',
  'iinis',
  'iinxe',
  'iirsa',
  'ijaci',
  'ijebu',
  'ijexa',
  'ijoco',
  'ijuis',
  'ilais',
  'ilana',
  'ilena',
  'ileon',
  'ilesa',
  'ilese',
  'ileso',
  'ileus',
  'ilhai',
  'ilhal',
  'ilham',
  'ilhar',
  'ilhas',
  'ilhei',
  'ilhem',
  'ilhes',
  'ilheu',
  'ilhoa',
  'ilhou',
  'ilice',
  'ilion',
  'ilipe',
  'ilium',
  'iliza',
  'ilona',
  'ilota',
  'ilson',
  'ilton',
  'iltra',
  'iluca',
  'iluda',
  'ilude',
  'iludi',
  'iludo',
  'iluso',
  'imago',
  'imala',
  'imame',
  'imamo',
  'imbau',
  'imbes',
  'imboa',
  'imbus',
  'imene',
  'imido',
  'imita',
  'imite',
  'imito',
  'imola',
  'imole',
  'imolo',
  'impar',
  'impia',
  'impio',
  'impoe',
  'impol',
  'impor',
  'impos',
  'impus',
  'imune',
  'imuno',
  'inaco',
  'inaja',
  'inaje',
  'inala',
  'inale',
  'inalo',
  'iname',
  'inamu',
  'inara',
  'inare',
  'inari',
  'inata',
  'inato',
  'inaya',
  'incar',
  'incas',
  'incha',
  'inche',
  'incho',
  'incoa',
  'incoe',
  'incoo',
  'incra',
  'incro',
  'index',
  'india',
  'indio',
  'indjo',
  'indri',
  'indua',
  'induz',
  'inexo',
  'infer',
  'infla',
  'infle',
  'inflo',
  'infra',
  'ingai',
  'ingas',
  'ingea',
  'ingre',
  'ingua',
  'ingue',
  'inhes',
  'inhua',
  'iniba',
  'inibe',
  'inibi',
  'inibo',
  'inion',
  'inite',
  'injou',
  'inlas',
  'inoma',
  'inope',
  'inova',
  'inove',
  'inovo',
  'insta',
  'inste',
  'insto',
  'insua',
  'intel',
  'inter',
  'intra',
  'intua',
  'intui',
  'intuo',
  'inube',
  'inubo',
  'inula',
  'invar',
  'inves',
  'invio',
  'iobar',
  'iodar',
  'iodis',
  'iodol',
  'iodos',
  'iogas',
  'iogue',
  'iolau',
  'iolco',
  'ionio',
  'ionte',
  'iotio',
  'ipaba',
  'ipaca',
  'ipacu',
  'ipadu',
  'ipcbr',
  'ipeca',
  'ipecu',
  'ipero',
  'ipete',
  'ipeui',
  'ipiau',
  'ipida',
  'ipira',
  'ipiti',
  'ipora',
  'ippar',
  'ippur',
  'ipsis',
  'ipubi',
  'ipuca',
  'iques',
  'iraci',
  'iracu',
  'irada',
  'irade',
  'irado',
  'iraja',
  'irani',
  'irara',
  'irari',
  'irati',
  'irdes',
  'irece',
  'ireis',
  'iremo',
  'irene',
  'ireno',
  'irere',
  'irial',
  'iriam',
  'iriar',
  'irias',
  'iriju',
  'irino',
  'irira',
  'irite',
  'iriva',
  'irmao',
  'irmas',
  'irmos',
  'iroco',
  'irone',
  'irono',
  'iroso',
  'irpex',
  'irucu',
  'irupi',
  'iruri',
  'isaac',
  'isala',
  'isaro',
  'isate',
  'iscar',
  'iscas',
  'iseio',
  'isele',
  'islao',
  'isnar',
  'isoca',
  'isola',
  'isole',
  'isolo',
  'isops',
  'issei',
  'isseu',
  'istmo',
  'istro',
  'isuro',
  'isuso',
  'itabi',
  'itaca',
  'itaco',
  'itagi',
  'itaim',
  'itaja',
  'itaju',
  'itala',
  'italo',
  'itame',
  'itamo',
  'itano',
  'itape',
  'itapu',
  'itati',
  'itaua',
  'itens',
  'itera',
  'itere',
  'itero',
  'itiel',
  'itobi',
  'itral',
  'itrio',
  'itrol',
  'ituas',
  'ituis',
  'ituna',
  'iuane',
  'iucea',
  'iulas',
  'iupac',
  'iupua',
  'iuque',
  'iurte',
  'ivana',
  'ivani',
  'ivaol',
  'ivate',
  'iveca',
  'ivesa',
  'ivete',
  'ivira',
  'iviro',
  'ivone',
  'ivora',
  'ivoti',
  'ixalo',
  'ixiao',
  'ixiea',
  'ixode',
  'ixodo',
  'ixora',
  'izuzo',
  'jaaca',
  'jaala',
  'jaare',
  'jaate',
  'jabal',
  'jabao',
  'jabim',
  'jabre',
  'jacai',
  'jacas',
  'jacea',
  'jacio',
  'jacir',
  'jacob',
  'jacre',
  'jacua',
  'jacui',
  'jacus',
  'jadai',
  'jadao',
  'jades',
  'jaera',
  'jafar',
  'jaffe',
  'jafia',
  'jagaz',
  'jagre',
  'jagua',
  'jague',
  'jaiba',
  'jaico',
  'jaime',
  'jaina',
  'jairo',
  'jalao',
  'jalde',
  'jaldo',
  'jaleo',
  'jales',
  'jaleu',
  'jalia',
  'jalne',
  'jamai',
  'jamal',
  'jambe',
  'jambi',
  'jambo',
  'jambu',
  'james',
  'jamie',
  'jamil',
  'jamim',
  'jamir',
  'janal',
  'janar',
  'janau',
  'jande',
  'jandu',
  'janfa',
  'janga',
  'jango',
  'janie',
  'janim',
  'janio',
  'janir',
  'janja',
  'janoa',
  'janta',
  'jante',
  'janto',
  'janua',
  'japao',
  'japim',
  'japiu',
  'japix',
  'japue',
  'japui',
  'japus',
  'jaqua',
  'jaque',
  'jaras',
  'jarda',
  'jardo',
  'jared',
  'jario',
  'jarir',
  'jaroa',
  'jarra',
  'jarro',
  'jasao',
  'jasen',
  'jasom',
  'jason',
  'jaspe',
  'jasso',
  'jatai',
  'jatar',
  'jatei',
  'jater',
  'jatir',
  'jatis',
  'jator',
  'jatos',
  'jaula',
  'jauna',
  'jauru',
  'javae',
  'jayme',
  'jazam',
  'jazas',
  'jazei',
  'jazem',
  'jazer',
  'jazes',
  'jazeu',
  'jazia',
  'jeans',
  'jecas',
  'jegre',
  'jegue',
  'jeico',
  'jeira',
  'jeito',
  'jejes',
  'jejua',
  'jejue',
  'jejum',
  'jejuo',
  'jembe',
  'jemia',
  'jenia',
  'jeova',
  'jepia',
  'jepio',
  'jeque',
  'jequi',
  'jerra',
  'jesse',
  'jesua',
  'jesus',
  'jetai',
  'jeter',
  'jetom',
  'jetro',
  'jeuba',
  'jeude',
  'jezer',
  'jicao',
  'jicui',
  'jiefo',
  'jievo',
  'jilan',
  'jiles',
  'jimba',
  'jimbe',
  'jimbo',
  'jinga',
  'jinge',
  'jingo',
  'jinje',
  'jipao',
  'jipio',
  'jipis',
  'jique',
  'jiqui',
  'jirau',
  'jiroe',
  'jitai',
  'jitsu',
  'jizar',
  'joabe',
  'joaci',
  'joada',
  'joana',
  'joane',
  'jobar',
  'jobim',
  'jocal',
  'jocos',
  'jocsa',
  'jocta',
  'jodie',
  'joede',
  'joela',
  'joeta',
  'jofre',
  'jogai',
  'jogam',
  'jogao',
  'jogar',
  'jogas',
  'jogla',
  'jogos',
  'jogou',
  'jogue',
  'jogui',
  'joias',
  'joiba',
  'joica',
  'joice',
  'joina',
  'joint',
  'joios',
  'jolar',
  'jolco',
  'jolda',
  'jolga',
  'joliz',
  'jomar',
  'jonao',
  'joncu',
  'jongo',
  'jonio',
  'jooes',
  'jorao',
  'jorge',
  'jorim',
  'jorne',
  'jorra',
  'jorre',
  'jorro',
  'joses',
  'josia',
  'josie',
  'josue',
  'jotao',
  'jotas',
  'jotba',
  'jouca',
  'jouja',
  'joule',
  'jovem',
  'juami',
  'juana',
  'juara',
  'juari',
  'juati',
  'jubai',
  'jubal',
  'jubas',
  'jubeu',
  'jucal',
  'jucas',
  'judas',
  'judeo',
  'judeu',
  'judia',
  'judie',
  'judio',
  'judum',
  'jugal',
  'jugar',
  'jugos',
  'jugum',
  'juina',
  'juiza',
  'juizo',
  'julga',
  'julgo',
  'julho',
  'julio',
  'jumbo',
  'junca',
  'junco',
  'jundo',
  'jundu',
  'jungo',
  'junho',
  'junia',
  'junta',
  'junte',
  'junto',
  'jupao',
  'jupia',
  'jupua',
  'juqui',
  'jurai',
  'juram',
  'jurao',
  'jurar',
  'juras',
  'jurei',
  'jurem',
  'jures',
  'juros',
  'jurou',
  'jurua',
  'jusao',
  'justa',
  'justo',
  'jutai',
  'jutas',
  'juuna',
  'juvia',
  'kafka',
  'karla',
  'katia',
  'kazuo',
  'kelda',
  'keops',
  'kevin',
  'khmer',
  'kioto',
  'klaus',
  'klein',
  'kuala',
  'kuito',
  'kwait',
  'kyoto',
  'labao',
  'labas',
  'labeo',
  'labeu',
  'labia',
  'labil',
  'labio',
  'labma',
  'labor',
  'labro',
  'lacai',
  'lacam',
  'lacao',
  'lacar',
  'lacas',
  'lacei',
  'lacem',
  'laces',
  'lacha',
  'lacio',
  'lacmo',
  'lacni',
  'lacno',
  'lacol',
  'lacon',
  'lacos',
  'lacou',
  'lacra',
  'lacre',
  'lacro',
  'ladao',
  'ladim',
  'lados',
  'ladra',
  'ladre',
  'ladro',
  'lagao',
  'lagar',
  'lages',
  'lagis',
  'lagoa',
  'lagos',
  'laica',
  'laico',
  'laija',
  'laika',
  'laila',
  'lailo',
  'laime',
  'laisa',
  'laitu',
  'lajao',
  'lajar',
  'lajea',
  'lajem',
  'lajes',
  'lalar',
  'lalas',
  'lalau',
  'lalia',
  'lalna',
  'lamar',
  'lamas',
  'lamba',
  'lambe',
  'lambi',
  'lambo',
  'lambu',
  'lamec',
  'lamel',
  'lamim',
  'lamio',
  'lamna',
  'lampo',
  'lanar',
  'lanas',
  'lanca',
  'lance',
  'lanco',
  'lande',
  'landi',
  'lando',
  'landu',
  'laneo',
  'lange',
  'langi',
  'lango',
  'lanho',
  'lanio',
  'lanka',
  'lante',
  'lanti',
  'lapao',
  'lapar',
  'lapas',
  'lapia',
  'lapim',
  'lapis',
  'lapre',
  'lapso',
  'lapuz',
  'laque',
  'larau',
  'lardo',
  'lares',
  'lareu',
  'larga',
  'largo',
  'laria',
  'larim',
  'larix',
  'laroz',
  'larro',
  'larva',
  'lasca',
  'lasco',
  'laser',
  'lasio',
  'lasmo',
  'latao',
  'latas',
  'latem',
  'lateo',
  'later',
  'lates',
  'latex',
  'latia',
  'latim',
  'latir',
  'latis',
  'latiu',
  'lauda',
  'laude',
  'laudo',
  'lauie',
  'laule',
  'laura',
  'lauro',
  'lauto',
  'lauza',
  'lavai',
  'lavam',
  'lavar',
  'lavas',
  'lavei',
  'lavem',
  'laves',
  'lavor',
  'lavou',
  'lavra',
  'lavre',
  'lavro',
  'laxar',
  'lazao',
  'lazer',
  'leais',
  'lebia',
  'lebon',
  'lebre',
  'lecal',
  'lecia',
  'lecre',
  'ledea',
  'ledes',
  'ledol',
  'ledor',
  'ledos',
  'ledra',
  'leeia',
  'legai',
  'legal',
  'legam',
  'legao',
  'legar',
  'legas',
  'legba',
  'legis',
  'legou',
  'legua',
  'legue',
  'leiam',
  'leias',
  'leide',
  'leiga',
  'leigo',
  'leila',
  'leino',
  'leite',
  'leito',
  'leiva',
  'leixa',
  'leixe',
  'leles',
  'lelia',
  'lelio',
  'lelis',
  'lemas',
  'lemba',
  'lembo',
  'lemea',
  'lemes',
  'lemna',
  'lemos',
  'lempa',
  'lemur',
  'lenao',
  'lenca',
  'lenco',
  'lenda',
  'lende',
  'lendo',
  'lendu',
  'leneo',
  'leneu',
  'lenga',
  'lenha',
  'lenho',
  'lenin',
  'lenio',
  'lenir',
  'lenis',
  'lenoa',
  'lenta',
  'lente',
  'lento',
  'leoas',
  'leoba',
  'leoes',
  'leone',
  'leoni',
  'leops',
  'leote',
  'lepas',
  'lepes',
  'lepis',
  'lepra',
  'lepto',
  'lepus',
  'leque',
  'leram',
  'lerao',
  'leras',
  'lerca',
  'lerda',
  'lerdo',
  'lerei',
  'lerem',
  'leres',
  'leria',
  'lerio',
  'lermo',
  'lerna',
  'leroi',
  'lerpe',
  'lesai',
  'lesam',
  'lesao',
  'lesar',
  'lesas',
  'lesco',
  'lesei',
  'lesem',
  'leses',
  'lesim',
  'lesma',
  'lesos',
  'lesou',
  'lessa',
  'lesse',
  'leste',
  'letal',
  'letao',
  'letas',
  'leteu',
  'letra',
  'letro',
  'leuco',
  'leude',
  'levai',
  'levam',
  'levar',
  'levas',
  'levei',
  'levem',
  'leves',
  'levez',
  'levou',
  'lexia',
  'lexis',
  'lhama',
  'lhano',
  'liabo',
  'liaca',
  'liame',
  'liana',
  'liane',
  'liase',
  'libar',
  'libau',
  'liber',
  'libia',
  'libio',
  'libra',
  'libre',
  'libua',
  'libuo',
  'licao',
  'licea',
  'liceu',
  'lichi',
  'licio',
  'licne',
  'licor',
  'licra',
  'licto',
  'lidai',
  'lidam',
  'lidar',
  'lidas',
  'lidei',
  'lidem',
  'lider',
  'lides',
  'lidia',
  'lidio',
  'lidos',
  'lidou',
  'liege',
  'lieis',
  'liene',
  'ligai',
  'ligal',
  'ligam',
  'ligar',
  'ligas',
  'ligbi',
  'ligeo',
  'ligeu',
  'light',
  'ligio',
  'ligou',
  'ligue',
  'lilas',
  'lilia',
  'limai',
  'limam',
  'limao',
  'limar',
  'limas',
  'limax',
  'limbo',
  'limei',
  'limem',
  'limeo',
  'limes',
  'limos',
  'limou',
  'limpa',
  'limpe',
  'limpo',
  'lince',
  'linco',
  'linda',
  'lindb',
  'linde',
  'lindo',
  'lineo',
  'lineu',
  'linfa',
  'linfo',
  'linha',
  'linho',
  'linus',
  'linux',
  'liode',
  'liopo',
  'lioto',
  'lipes',
  'lipia',
  'lipoa',
  'lipse',
  'liral',
  'lirao',
  'liras',
  'lirio',
  'lisas',
  'lises',
  'lisga',
  'lisim',
  'lisio',
  'lismo',
  'lisol',
  'lisos',
  'lispa',
  'lispe',
  'lissa',
  'lisso',
  'lista',
  'liste',
  'listo',
  'litao',
  'litar',
  'litio',
  'litor',
  'litro',
  'lituo',
  'livel',
  'livia',
  'livio',
  'livor',
  'livra',
  'livre',
  'livro',
  'lixai',
  'lixam',
  'lixao',
  'lixar',
  'lixas',
  'lixei',
  'lixem',
  'lixes',
  'lixos',
  'lixou',
  'lizar',
  'lizia',
  'loaco',
  'loasa',
  'lobal',
  'lobao',
  'lobar',
  'lobas',
  'lobaz',
  'lobby',
  'lobos',
  'locai',
  'local',
  'locam',
  'locao',
  'locar',
  'locas',
  'locou',
  'locus',
  'lodao',
  'loena',
  'lofio',
  'logar',
  'logba',
  'logea',
  'login',
  'logon',
  'logra',
  'logre',
  'logro',
  'logue',
  'loibe',
  'loica',
  'loide',
  'loios',
  'loira',
  'loiro',
  'loisa',
  'lojas',
  'lojia',
  'lolio',
  'loman',
  'lomba',
  'lombe',
  'lombi',
  'lombo',
  'lomis',
  'lomue',
  'lonas',
  'lonca',
  'londo',
  'longa',
  'longe',
  'longo',
  'lonja',
  'lopes',
  'lopso',
  'loque',
  'loqui',
  'loral',
  'lorca',
  'lorde',
  'lorfo',
  'lorga',
  'lorio',
  'loros',
  'lorpa',
  'lorto',
  'losia',
  'losma',
  'losna',
  'lotai',
  'lotam',
  'lotar',
  'lotas',
  'lotea',
  'lotei',
  'lotem',
  'loteo',
  'lotes',
  'lotos',
  'lotou',
  'lotur',
  'lotus',
  'louca',
  'louco',
  'louis',
  'loura',
  'louro',
  'lousa',
  'louva',
  'louve',
  'louvo',
  'lovia',
  'loxia',
  'luaco',
  'luada',
  'luale',
  'luane',
  'luano',
  'lucao',
  'lucas',
  'lucha',
  'lucia',
  'lucio',
  'lucmo',
  'lucra',
  'lucre',
  'lucro',
  'ludar',
  'ludim',
  'ludio',
  'ludro',
  'lueda',
  'lueea',
  'luena',
  'lueta',
  'lufar',
  'lufia',
  'lufre',
  'lugar',
  'lugol',
  'lugre',
  'luico',
  'luigi',
  'luila',
  'luime',
  'luina',
  'luisa',
  'luiza',
  'lulao',
  'lulas',
  'lulus',
  'lumbo',
  'lumen',
  'lumes',
  'lumia',
  'lumpo',
  'lunar',
  'lunda',
  'lundu',
  'lunel',
  'lunfa',
  'lungo',
  'lunil',
  'lupae',
  'lupar',
  'lupas',
  'lupia',
  'lupus',
  'luque',
  'lurar',
  'lurda',
  'lurgo',
  'luria',
  'lurta',
  'lusas',
  'lusco',
  'lusia',
  'lusol',
  'lusos',
  'lutai',
  'lutam',
  'lutar',
  'lutas',
  'lutei',
  'lutem',
  'luteo',
  'lutes',
  'lutos',
  'lutou',
  'lutra',
  'lutse',
  'luvar',
  'luvas',
  'luxar',
  'luxaz',
  'luxos',
  'luyse',
  'luzam',
  'luzas',
  'luzem',
  'luzes',
  'luzia',
  'luzie',
  'luzio',
  'luzir',
  'luzis',
  'luziu',
  'lviii',
  'lxiii',
  'lxvii',
  'lxxii',
  'lxxiv',
  'lxxix',
  'lxxvi',
  'lxxxi',
  'lxxxv',
  'maari',
  'mabea',
  'mabel',
  'maber',
  'mabiu',
  'mabre',
  'macaa',
  'macae',
  'macal',
  'macao',
  'macar',
  'macas',
  'macau',
  'macaz',
  'macea',
  'maces',
  'macha',
  'mache',
  'macho',
  'macia',
  'macie',
  'macio',
  'macis',
  'macla',
  'macoa',
  'macom',
  'macos',
  'macro',
  'macua',
  'madia',
  'madim',
  'madje',
  'madom',
  'madre',
  'madri',
  'maeio',
  'maeta',
  'mafau',
  'mafia',
  'mafor',
  'mafra',
  'mafua',
  'magai',
  'magal',
  'magba',
  'magda',
  'magia',
  'magis',
  'magma',
  'magna',
  'magne',
  'magno',
  'magoa',
  'magoe',
  'magoo',
  'magos',
  'magra',
  'magro',
  'mahal',
  'mahdi',
  'maiao',
  'maiar',
  'maias',
  'maica',
  'maida',
  'mails',
  'maina',
  'maino',
  'maior',
  'maios',
  'maira',
  'maire',
  'mairi',
  'mairu',
  'maisa',
  'maita',
  'maite',
  'maiua',
  'majil',
  'major',
  'malar',
  'malas',
  'malba',
  'malco',
  'males',
  'malgo',
  'malha',
  'malhe',
  'malho',
  'malim',
  'malta',
  'malua',
  'malus',
  'malva',
  'malvo',
  'mamae',
  'mamai',
  'mamal',
  'mamam',
  'mamao',
  'mamar',
  'mamas',
  'mambe',
  'mambi',
  'mambo',
  'mamei',
  'mamem',
  'mames',
  'mamoa',
  'mamou',
  'mamua',
  'mamui',
  'mamum',
  'manai',
  'manal',
  'manar',
  'manas',
  'manau',
  'manca',
  'manco',
  'manda',
  'mande',
  'mandi',
  'mando',
  'mandu',
  'manea',
  'manel',
  'manes',
  'manga',
  'mango',
  'mangu',
  'manha',
  'manho',
  'mania',
  'manil',
  'manir',
  'maniu',
  'manja',
  'manje',
  'manjo',
  'manju',
  'manoa',
  'manos',
  'mansa',
  'manso',
  'manta',
  'mante',
  'manto',
  'manua',
  'manue',
  'manul',
  'maome',
  'maona',
  'maore',
  'maori',
  'mapao',
  'mapas',
  'mapea',
  'maple',
  'mapua',
  'maque',
  'maqui',
  'maraa',
  'marao',
  'marar',
  'marau',
  'marca',
  'marco',
  'mardo',
  'marel',
  'mares',
  'mareu',
  'marfi',
  'marga',
  'margo',
  'maria',
  'marie',
  'marii',
  'marim',
  'mario',
  'mariz',
  'marja',
  'marla',
  'marli',
  'marly',
  'marmo',
  'marno',
  'maroa',
  'marra',
  'marso',
  'marta',
  'marte',
  'marto',
  'marua',
  'marui',
  'masal',
  'masca',
  'masco',
  'maseo',
  'maser',
  'maseu',
  'masio',
  'massa',
  'masto',
  'masue',
  'matai',
  'matal',
  'matam',
  'matao',
  'matar',
  'matas',
  'matat',
  'matau',
  'matei',
  'matem',
  'mater',
  'mates',
  'mateu',
  'matia',
  'matiz',
  'matos',
  'matou',
  'matri',
  'matue',
  'mauba',
  'mauca',
  'maues',
  'maujo',
  'maula',
  'maulo',
  'maune',
  'maura',
  'mauro',
  'mavia',
  'mavua',
  'maxim',
  'mayen',
  'mayer',
  'mazar',
  'mazeu',
  'mazia',
  'mccci',
  'mcccl',
  'mcccv',
  'mcccx',
  'mccii',
  'mcciv',
  'mccix',
  'mccli',
  'mcclv',
  'mcclx',
  'mccvi',
  'mccxc',
  'mccxi',
  'mccxl',
  'mccxv',
  'mccxx',
  'mcdii',
  'mcdiv',
  'mcdix',
  'mcdli',
  'mcdlv',
  'mcdlx',
  'mcdvi',
  'mcdxc',
  'mcdxi',
  'mcdxl',
  'mcdxv',
  'mcdxx',
  'mciii',
  'mclii',
  'mcliv',
  'mclix',
  'mclvi',
  'mclxi',
  'mclxv',
  'mclxx',
  'mcmii',
  'mcmiv',
  'mcmix',
  'mcmli',
  'mcmlv',
  'mcmlx',
  'mcmvi',
  'mcmxc',
  'mcmxi',
  'mcmxl',
  'mcmxv',
  'mcmxx',
  'mcvii',
  'mcxci',
  'mcxcv',
  'mcxii',
  'mcxiv',
  'mcxix',
  'mcxli',
  'mcxlv',
  'mcxvi',
  'mcxxi',
  'mcxxv',
  'mcxxx',
  'mdccc',
  'mdcci',
  'mdccl',
  'mdccv',
  'mdccx',
  'mdcii',
  'mdciv',
  'mdcix',
  'mdcli',
  'mdclv',
  'mdclx',
  'mdcvi',
  'mdcxc',
  'mdcxi',
  'mdcxl',
  'mdcxv',
  'mdcxx',
  'mdiii',
  'mdlii',
  'mdliv',
  'mdlix',
  'mdlvi',
  'mdlxi',
  'mdlxv',
  'mdlxx',
  'mdvii',
  'mdxci',
  'mdxcv',
  'mdxii',
  'mdxiv',
  'mdxix',
  'mdxli',
  'mdxlv',
  'mdxvi',
  'mdxxi',
  'mdxxv',
  'mdxxx',
  'meaco',
  'meada',
  'meado',
  'meago',
  'meano',
  'meapo',
  'meari',
  'meato',
  'mebia',
  'mebou',
  'mecam',
  'mecas',
  'mecha',
  'mecia',
  'mecru',
  'mecum',
  'medao',
  'medea',
  'medem',
  'medes',
  'media',
  'medio',
  'medir',
  'medis',
  'mediu',
  'medol',
  'medon',
  'medos',
  'medra',
  'medre',
  'medro',
  'megan',
  'meiao',
  'meias',
  'meier',
  'meiga',
  'meigo',
  'meiju',
  'meios',
  'meira',
  'meire',
  'meiri',
  'meiru',
  'melai',
  'melam',
  'melao',
  'melar',
  'melas',
  'melba',
  'melca',
  'meldo',
  'melea',
  'melei',
  'melem',
  'meleo',
  'meles',
  'melez',
  'melfo',
  'melga',
  'melgo',
  'melha',
  'melia',
  'meloa',
  'meloe',
  'melou',
  'melro',
  'membe',
  'membi',
  'menar',
  'menas',
  'mende',
  'mendi',
  'mendo',
  'menea',
  'menes',
  'meneu',
  'menga',
  'menha',
  'menia',
  'menim',
  'menir',
  'menor',
  'menos',
  'menso',
  'menta',
  'mente',
  'menti',
  'mento',
  'menus',
  'meola',
  'meolo',
  'meoma',
  'meone',
  'meono',
  'meote',
  'meras',
  'merca',
  'merce',
  'merda',
  'merem',
  'merim',
  'merio',
  'merlo',
  'meroe',
  'meros',
  'merro',
  'merua',
  'merue',
  'merui',
  'mesao',
  'mesas',
  'meses',
  'mesio',
  'mesma',
  'mesmo',
  'mesna',
  'meson',
  'messe',
  'messu',
  'mesua',
  'metal',
  'metam',
  'metas',
  'metei',
  'metem',
  'meter',
  'metes',
  'meteu',
  'metia',
  'metie',
  'metil',
  'metim',
  'metol',
  'metro',
  'meuco',
  'meudo',
  'meule',
  'meuns',
  'mevia',
  'mevio',
  'mexam',
  'mexao',
  'mexas',
  'mexei',
  'mexem',
  'mexer',
  'mexes',
  'mexeu',
  'mexia',
  'mexir',
  'meyer',
  'mezeu',
  'mezio',
  'mezzo',
  'mfeca',
  'mfiti',
  'mhari',
  'miada',
  'miado',
  'miais',
  'miama',
  'miami',
  'miamo',
  'miana',
  'miaos',
  'miara',
  'miaro',
  'miava',
  'micao',
  'micar',
  'micas',
  'miche',
  'micho',
  'micio',
  'micos',
  'micra',
  'micro',
  'midau',
  'mideu',
  'midia',
  'midim',
  'mieis',
  'mielo',
  'miemo',
  'migar',
  'migas',
  'migma',
  'migra',
  'migre',
  'migro',
  'miina',
  'miite',
  'mijai',
  'mijam',
  'mijao',
  'mijar',
  'mijas',
  'mijei',
  'mijem',
  'mijes',
  'mijos',
  'mijou',
  'mijui',
  'milao',
  'milca',
  'milde',
  'mileo',
  'mileu',
  'milha',
  'milho',
  'milio',
  'milpa',
  'milvo',
  'mimai',
  'mimam',
  'mimar',
  'mimas',
  'mimbi',
  'mimbo',
  'mimei',
  'mimem',
  'mimes',
  'mimon',
  'mimos',
  'mimou',
  'minai',
  'minam',
  'minar',
  'minas',
  'minaz',
  'minda',
  'minei',
  'minem',
  'mines',
  'mineu',
  'mingo',
  'mingu',
  'minha',
  'minho',
  'minio',
  'minis',
  'minje',
  'minke',
  'minol',
  'minor',
  'minou',
  'minsk',
  'minta',
  'minto',
  'miode',
  'mioga',
  'miojo',
  'miolo',
  'mioma',
  'miopa',
  'miope',
  'miose',
  'mioto',
  'mioxo',
  'mique',
  'miqui',
  'mirai',
  'miram',
  'mirao',
  'mirar',
  'miras',
  'mirei',
  'mirem',
  'mires',
  'mirga',
  'miria',
  'mirim',
  'mirio',
  'miris',
  'mirma',
  'mirmo',
  'mirna',
  'miroe',
  'mirou',
  'mirra',
  'mirto',
  'mirui',
  'mirza',
  'misal',
  'misgo',
  'misio',
  'misis',
  'misma',
  'misna',
  'mispa',
  'missa',
  'misso',
  'mista',
  'miste',
  'misto',
  'mitim',
  'mitis',
  'mitos',
  'mitra',
  'mitua',
  'miuca',
  'miuda',
  'miude',
  'miudo',
  'miufa',
  'miulo',
  'miuro',
  'miuva',
  'mixar',
  'mixia',
  'mixna',
  'mixos',
  'mixto',
  'mizeu',
  'mliii',
  'mlvii',
  'mlxii',
  'mlxiv',
  'mlxix',
  'mlxvi',
  'mlxxi',
  'mlxxv',
  'mlxxx',
  'mmiii',
  'mmlii',
  'mmliv',
  'mmlix',
  'mmlvi',
  'mmlxi',
  'mmlxv',
  'mmlxx',
  'mmvii',
  'mmxci',
  'mmxcv',
  'mmxii',
  'mmxiv',
  'mmxix',
  'mmxli',
  'mmxlv',
  'mmxvi',
  'mmxxi',
  'mmxxv',
  'mmxxx',
  'mnese',
  'moabe',
  'moabi',
  'moaca',
  'moadi',
  'moado',
  'moafa',
  'moais',
  'moali',
  'moamo',
  'moana',
  'mobil',
  'mocao',
  'mocar',
  'mocas',
  'mocha',
  'mochi',
  'mocho',
  'mocim',
  'mocis',
  'mocoa',
  'mocos',
  'mocsa',
  'mocui',
  'modal',
  'modas',
  'modem',
  'modio',
  'modos',
  'modus',
  'moeca',
  'moeda',
  'moego',
  'moeis',
  'moela',
  'moema',
  'moemo',
  'moera',
  'moere',
  'moeso',
  'moesu',
  'moeta',
  'mofai',
  'mofam',
  'mofar',
  'mofas',
  'mofei',
  'mofem',
  'mofes',
  'mofos',
  'mofou',
  'mogai',
  'mogao',
  'mogem',
  'moges',
  'mogno',
  'mogol',
  'mogor',
  'mogue',
  'moiam',
  'moiao',
  'moias',
  'moico',
  'moida',
  'moido',
  'moira',
  'moire',
  'moiro',
  'moita',
  'moixe',
  'mojar',
  'mojui',
  'molal',
  'molar',
  'molas',
  'molda',
  'molde',
  'moldo',
  'molei',
  'moles',
  'molge',
  'molha',
  'molhe',
  'molho',
  'molia',
  'molim',
  'molio',
  'molir',
  'molua',
  'momar',
  'mombe',
  'momos',
  'mompe',
  'monas',
  'monco',
  'monde',
  'monel',
  'monfa',
  'monfi',
  'monge',
  'mongi',
  'mongo',
  'mongu',
  'monhe',
  'monho',
  'monir',
  'moniz',
  'monja',
  'monjo',
  'monos',
  'monox',
  'monso',
  'monta',
  'monte',
  'monto',
  'monvo',
  'monza',
  'mooca',
  'moogo',
  'mopla',
  'moqua',
  'moqui',
  'morai',
  'moral',
  'moram',
  'morao',
  'morar',
  'moras',
  'morbo',
  'morco',
  'morda',
  'morde',
  'mordi',
  'mordo',
  'morea',
  'morei',
  'morel',
  'morem',
  'mores',
  'morfe',
  'morfo',
  'moria',
  'moril',
  'morim',
  'morio',
  'mormo',
  'morna',
  'morno',
  'moros',
  'morou',
  'morra',
  'morre',
  'morri',
  'morro',
  'morsa',
  'morse',
  'morso',
  'morta',
  'morte',
  'morto',
  'morxi',
  'mosba',
  'mosca',
  'moses',
  'mossa',
  'mossi',
  'mosto',
  'motar',
  'motel',
  'motim',
  'motor',
  'motos',
  'motum',
  'mouco',
  'moule',
  'moura',
  'mouro',
  'mouse',
  'mouta',
  'mouxe',
  'movam',
  'movas',
  'movei',
  'movel',
  'movem',
  'mover',
  'moves',
  'moveu',
  'movia',
  'moxar',
  'mozar',
  'mscol',
  'muaco',
  'muadi',
  'muafo',
  'muaje',
  'mualo',
  'muame',
  'muana',
  'muane',
  'muani',
  'muari',
  'muata',
  'muave',
  'mucao',
  'muche',
  'muchi',
  'mucia',
  'mucio',
  'mucol',
  'mucor',
  'mucos',
  'mucro',
  'mucua',
  'mucue',
  'mucui',
  'mucum',
  'mudai',
  'mudam',
  'mudar',
  'mudas',
  'mudei',
  'mudem',
  'mudes',
  'mudez',
  'mudir',
  'mudos',
  'mudou',
  'mudra',
  'mueba',
  'muela',
  'muele',
  'muene',
  'muere',
  'muezo',
  'muflo',
  'mufti',
  'mufui',
  'mugem',
  'mugia',
  'mugil',
  'mugir',
  'mugis',
  'mugiu',
  'mugre',
  'mugue',
  'muieu',
  'muila',
  'muira',
  'muita',
  'muito',
  'mujam',
  'mujas',
  'mujau',
  'mujem',
  'mujia',
  'mujil',
  'mujio',
  'mulai',
  'mulas',
  'mulei',
  'muleo',
  'muleu',
  'mulgu',
  'mulim',
  'mulio',
  'mulme',
  'muloi',
  'mulso',
  'multa',
  'multe',
  'multi',
  'multo',
  'mulua',
  'mulum',
  'mumbe',
  'mumbo',
  'mumia',
  'mumua',
  'munde',
  'mundi',
  'mundo',
  'munem',
  'munes',
  'mungo',
  'mungu',
  'munho',
  'munia',
  'munir',
  'munis',
  'muniu',
  'muniz',
  'munje',
  'munsi',
  'munto',
  'munus',
  'muoco',
  'muolo',
  'mupim',
  'muque',
  'muqui',
  'murai',
  'mural',
  'muram',
  'murar',
  'muras',
  'murca',
  'murco',
  'murei',
  'murem',
  'mures',
  'murex',
  'muria',
  'murim',
  'murle',
  'muros',
  'murou',
  'murre',
  'murro',
  'murso',
  'murta',
  'murto',
  'murua',
  'murui',
  'murum',
  'murza',
  'musal',
  'musas',
  'musca',
  'musco',
  'museu',
  'musgo',
  'musgu',
  'musme',
  'musse',
  'mussi',
  'musto',
  'mutel',
  'mutia',
  'mutoa',
  'mutoe',
  'mutom',
  'mutra',
  'mutua',
  'mutum',
  'mutuo',
  'muuba',
  'muviu',
  'muxem',
  'muzua',
  'mvdol',
  'mviii',
  'mxcii',
  'mxciv',
  'mxcix',
  'mxcvi',
  'mxiii',
  'mxlii',
  'mxliv',
  'mxlix',
  'mxlvi',
  'mxvii',
  'mxxii',
  'mxxiv',
  'mxxix',
  'mxxvi',
  'mxxxi',
  'mxxxv',
  'nabal',
  'nabao',
  'nabis',
  'nabla',
  'nablo',
  'nabor',
  'nabos',
  'nacao',
  'nacar',
  'nache',
  'nacib',
  'nacip',
  'nacor',
  'nacos',
  'nacre',
  'nacua',
  'nadai',
  'nadal',
  'nadam',
  'nadar',
  'nadas',
  'nadei',
  'nadem',
  'nader',
  'nades',
  'nadia',
  'nadim',
  'nadir',
  'nadja',
  'nados',
  'nadou',
  'nafil',
  'nafir',
  'nafta',
  'nagao',
  'nagar',
  'nagas',
  'nager',
  'nagia',
  'nagoa',
  'nagor',
  'nagos',
  'nagua',
  'nagui',
  'nagul',
  'naibe',
  'naida',
  'naide',
  'naife',
  'naifo',
  'naipe',
  'naire',
  'nairo',
  'naive',
  'naixo',
  'najir',
  'naldo',
  'nalgo',
  'namar',
  'namau',
  'namaz',
  'nambe',
  'nambi',
  'nambu',
  'namia',
  'namir',
  'nanai',
  'nanal',
  'nanam',
  'nanar',
  'nanas',
  'nanci',
  'nancy',
  'nanda',
  'nandi',
  'nando',
  'nandu',
  'nanei',
  'nanem',
  'nanes',
  'nanga',
  'nania',
  'nanja',
  'nanou',
  'naoto',
  'napeu',
  'naque',
  'naraz',
  'narca',
  'narco',
  'nardo',
  'narea',
  'nargo',
  'nario',
  'nariz',
  'narlo',
  'narra',
  'narre',
  'narro',
  'narsa',
  'narte',
  'narva',
  'nasal',
  'nasca',
  'nasce',
  'nasci',
  'nasco',
  'naseo',
  'nasim',
  'nasio',
  'nasir',
  'nasse',
  'nassi',
  'nasso',
  'nasua',
  'natal',
  'natan',
  'natas',
  'natel',
  'natia',
  'natil',
  'natio',
  'natos',
  'natro',
  'nauai',
  'naues',
  'nauga',
  'naulo',
  'nauro',
  'nauru',
  'nauta',
  'naval',
  'navao',
  'navea',
  'navem',
  'naves',
  'navia',
  'navim',
  'navio',
  'naxio',
  'nazia',
  'nazir',
  'ndala',
  'ndulu',
  'nebai',
  'nebel',
  'nebri',
  'necro',
  'necto',
  'nedai',
  'nedio',
  'nedji',
  'nedum',
  'neera',
  'nefro',
  'negai',
  'negam',
  'negao',
  'negar',
  'negas',
  'negou',
  'negra',
  'negro',
  'negue',
  'negus',
  'neide',
  'neido',
  'neiva',
  'nelas',
  'neldo',
  'neles',
  'nelia',
  'nelio',
  'nelma',
  'nembo',
  'nemeu',
  'nenax',
  'nende',
  'nendi',
  'nenem',
  'nenes',
  'nengo',
  'nenho',
  'nenio',
  'nente',
  'neone',
  'nepal',
  'neper',
  'nepia',
  'nepos',
  'neque',
  'neral',
  'nereo',
  'neres',
  'nereu',
  'nerio',
  'nerol',
  'nerto',
  'nervo',
  'nervu',
  'neseo',
  'neseu',
  'nesga',
  'nessa',
  'nesse',
  'nesta',
  'neste',
  'nesto',
  'netas',
  'netos',
  'neuma',
  'neuri',
  'neuro',
  'neusa',
  'neuza',
  'nevai',
  'nevam',
  'nevao',
  'nevar',
  'nevas',
  'nevei',
  'nevem',
  'neves',
  'nevio',
  'nevis',
  'nevoa',
  'nevou',
  'nevri',
  'nexos',
  'ngana',
  'ngola',
  'ngues',
  'nguta',
  'nhaca',
  'nhama',
  'nhati',
  'nhato',
  'nhele',
  'nhimo',
  'nhoca',
  'nhole',
  'niaia',
  'nialo',
  'niama',
  'niboe',
  'nicar',
  'nicas',
  'nices',
  'nicho',
  'nicia',
  'nicol',
  'nicti',
  'nidar',
  'nidia',
  'nidor',
  'nielo',
  'nifal',
  'nifao',
  'nigel',
  'niger',
  'nigra',
  'nigua',
  'nilgo',
  'nilio',
  'nilma',
  'nilva',
  'nimas',
  'nimbo',
  'nimio',
  'nimpa',
  'ninai',
  'ninam',
  'ninar',
  'ninas',
  'ninei',
  'ninem',
  'nines',
  'ninfa',
  'ninga',
  'ninho',
  'ninja',
  'ninji',
  'ninou',
  'niopo',
  'nipao',
  'niple',
  'nipoa',
  'nipto',
  'nirex',
  'nisao',
  'nisco',
  'nisei',
  'niseu',
  'nisia',
  'nisio',
  'nispo',
  'nisso',
  'nisto',
  'nital',
  'niton',
  'nitro',
  'nival',
  'nivel',
  'niveo',
  'nivia',
  'nobel',
  'nobil',
  'nobis',
  'noble',
  'nobre',
  'nocao',
  'nocar',
  'nocha',
  'nocio',
  'nocir',
  'nodal',
  'nodar',
  'nodio',
  'nodoa',
  'nodos',
  'noeis',
  'noema',
  'noemi',
  'noeno',
  'noese',
  'noete',
  'nogai',
  'nogal',
  'nogao',
  'noise',
  'noite',
  'noiva',
  'noive',
  'noivo',
  'nojaf',
  'nojar',
  'nojos',
  'nolan',
  'nolha',
  'nomea',
  'nomeo',
  'nomes',
  'nomia',
  'nonal',
  'nonas',
  'nonde',
  'nondo',
  'nonea',
  'nones',
  'nonex',
  'nonga',
  'nonio',
  'nonos',
  'nooca',
  'nopal',
  'nopra',
  'noras',
  'norbu',
  'norca',
  'norio',
  'norma',
  'norna',
  'norso',
  'norte',
  'nossa',
  'nosso',
  'notai',
  'notal',
  'notam',
  'notar',
  'notas',
  'notei',
  'notem',
  'noteo',
  'notes',
  'notou',
  'notre',
  'notro',
  'noute',
  'noval',
  'novar',
  'novas',
  'novea',
  'novel',
  'novio',
  'novoa',
  'novos',
  'noxal',
  'noxio',
  'nozes',
  'nozul',
  'nubeu',
  'nubil',
  'nubio',
  'nucal',
  'nucao',
  'nucas',
  'nucio',
  'nudez',
  'nuelo',
  'nueza',
  'nufar',
  'nuima',
  'nujol',
  'nulas',
  'nulos',
  'numas',
  'numbe',
  'numbu',
  'numen',
  'numes',
  'nunca',
  'nunce',
  'nundo',
  'nunes',
  'nungo',
  'nuper',
  'nuria',
  'nuruz',
  'nutar',
  'nutra',
  'nutre',
  'nutri',
  'nutro',
  'nutum',
  'nuvem',
  'nuveo',
  'nuvol',
  'nuxia',
  'nyhan',
  'nylon',
  'nzila',
  'oanda',
  'oasis',
  'obane',
  'obeba',
  'obelo',
  'obesa',
  'obeso',
  'obice',
  'obito',
  'obmep',
  'oboaz',
  'obolo',
  'obori',
  'obrar',
  'obras',
  'obsia',
  'obsta',
  'obste',
  'obsto',
  'obtem',
  'obter',
  'obulo',
  'obutu',
  'obvia',
  'obvie',
  'obvio',
  'obvir',
  'ocaia',
  'ocano',
  'ocapi',
  'ocara',
  'ocaso',
  'ocelo',
  'ocida',
  'ocoto',
  'ocrea',
  'octal',
  'octil',
  'oculo',
  'ocume',
  'ocupa',
  'ocupe',
  'ocupo',
  'odair',
  'odara',
  'odeao',
  'odede',
  'odeia',
  'odeie',
  'odeio',
  'odele',
  'odeon',
  'odete',
  'odiai',
  'odiar',
  'odico',
  'odiei',
  'odila',
  'odilo',
  'odino',
  'odios',
  'odiou',
  'odite',
  'odito',
  'odjas',
  'odone',
  'odule',
  'oecio',
  'oeone',
  'oeste',
  'ofaie',
  'ofato',
  'ofeso',
  'ofira',
  'ofite',
  'ofito',
  'ofris',
  'ofuro',
  'ogamo',
  'ogano',
  'ogiva',
  'ogodo',
  'ogoni',
  'ogude',
  'oguim',
  'oguxo',
  'oiaca',
  'oiana',
  'oiapi',
  'oiara',
  'oicam',
  'oidio',
  'oigar',
  'oinca',
  'oirar',
  'oiros',
  'oitao',
  'oitis',
  'olabo',
  'olada',
  'olaia',
  'olala',
  'olare',
  'olavo',
  'oleal',
  'olear',
  'olede',
  'olele',
  'oleno',
  'oleol',
  'oleos',
  'olere',
  'olhai',
  'olhal',
  'olham',
  'olhao',
  'olhar',
  'olhas',
  'olhei',
  'olhem',
  'olhes',
  'olhos',
  'olhou',
  'oligo',
  'olina',
  'olira',
  'oliva',
  'olmos',
  'olobo',
  'oloco',
  'olode',
  'oloma',
  'oluas',
  'olubo',
  'omado',
  'omael',
  'omaha',
  'omaia',
  'omala',
  'omalo',
  'omani',
  'omano',
  'omaso',
  'omata',
  'ombra',
  'ombro',
  'omega',
  'omele',
  'omeme',
  'omete',
  'omiri',
  'omita',
  'omite',
  'omiti',
  'omito',
  'omnes',
  'omolu',
  'omoma',
  'omulu',
  'onaga',
  'oncas',
  'ondar',
  'ondas',
  'ondea',
  'ondim',
  'onera',
  'onere',
  'onero',
  'oneta',
  'onfra',
  'onfua',
  'ongue',
  'onias',
  'onico',
  'oniro',
  'onixe',
  'onoba',
  'onone',
  'ontem',
  'opaba',
  'opaca',
  'opaco',
  'opaie',
  'opala',
  'opalo',
  'opcao',
  'opele',
  'opera',
  'opere',
  'opero',
  'opiar',
  'opido',
  'opimo',
  'opina',
  'opine',
  'opino',
  'opoem',
  'opoes',
  'opomo',
  'opora',
  'opsia',
  'optai',
  'optam',
  'optar',
  'optas',
  'optei',
  'optem',
  'optes',
  'optou',
  'oquea',
  'oquie',
  'oquim',
  'oraca',
  'orada',
  'orado',
  'orago',
  'orais',
  'oramo',
  'orano',
  'orara',
  'orate',
  'orava',
  'orcai',
  'orcam',
  'orcar',
  'orcas',
  'orcaz',
  'orcei',
  'orcem',
  'orces',
  'orcou',
  'ordem',
  'orear',
  'orebe',
  'orega',
  'oreis',
  'orela',
  'oremo',
  'orete',
  'orfao',
  'orfas',
  'orfeu',
  'orfia',
  'orfno',
  'orgao',
  'orgem',
  'orges',
  'orgia',
  'oribi',
  'orica',
  'orico',
  'oriel',
  'orion',
  'orite',
  'orixa',
  'oriza',
  'orjal',
  'orkut',
  'orlai',
  'orlam',
  'orlar',
  'orlas',
  'orlea',
  'orlei',
  'orlem',
  'orles',
  'orlon',
  'orlou',
  'ormuz',
  'ornai',
  'ornam',
  'ornar',
  'ornas',
  'ornei',
  'ornem',
  'ornes',
  'orneu',
  'ornis',
  'ornou',
  'orobo',
  'oroco',
  'oromo',
  'oropa',
  'orque',
  'orqui',
  'orseu',
  'orson',
  'ortiz',
  'ortol',
  'oruco',
  'orupa',
  'oruzu',
  'osaca',
  'osana',
  'oscar',
  'oseas',
  'osela',
  'osias',
  'osido',
  'osler',
  'osmar',
  'osmia',
  'osmio',
  'osmol',
  'osona',
  'osram',
  'ossae',
  'ossea',
  'osseo',
  'ossos',
  'osteo',
  'ostia',
  'ostio',
  'ostra',
  'ostro',
  'otava',
  'oteca',
  'otelo',
  'otica',
  'otico',
  'otima',
  'otimo',
  'otina',
  'otins',
  'otite',
  'otoba',
  'otojo',
  'otoma',
  'otomi',
  'otona',
  'otoni',
  'otoro',
  'otose',
  'ototo',
  'ouari',
  'ouaru',
  'oucam',
  'oucao',
  'oucas',
  'oudre',
  'ougar',
  'ourar',
  'ourem',
  'ouros',
  'ousai',
  'ousam',
  'ousar',
  'ousas',
  'ousei',
  'ousem',
  'ouses',
  'ousou',
  'outao',
  'outar',
  'outra',
  'outro',
  'ouvem',
  'ouves',
  'ouvia',
  'ouvir',
  'ouvis',
  'ouviu',
  'ovais',
  'ovalo',
  'ovano',
  'ovate',
  'oveva',
  'ovino',
  'oviua',
  'ovulo',
  'oxala',
  'oxale',
  'oxeba',
  'oxeol',
  'oxera',
  'oxiao',
  'oxida',
  'oxide',
  'oxido',
  'oxilo',
  'oxima',
  'oxina',
  'oxono',
  'oxura',
  'ozear',
  'ozena',
  'ozias',
  'oziel',
  'ozolo',
  'ozueu',
  'paari',
  'pablo',
  'pacaa',
  'pacai',
  'pacal',
  'pacao',
  'pacas',
  'pacau',
  'pacem',
  'pacha',
  'pacho',
  'pacol',
  'pacos',
  'pacto',
  'pacua',
  'pacui',
  'pacus',
  'pader',
  'pades',
  'padeu',
  'padma',
  'padoa',
  'padre',
  'padua',
  'paete',
  'pafio',
  'pafua',
  'pagai',
  'pagam',
  'pagao',
  'pagar',
  'pagas',
  'pagel',
  'pagem',
  'pager',
  'pageu',
  'pagos',
  'pagou',
  'pagro',
  'pagua',
  'pague',
  'paial',
  'paiao',
  'paiba',
  'paica',
  'paigc',
  'pailo',
  'paina',
  'pains',
  'paiol',
  'paira',
  'paire',
  'pairo',
  'paism',
  'paita',
  'paiva',
  'paixa',
  'pajao',
  'pajau',
  'pajea',
  'pajem',
  'pajes',
  'pajeu',
  'palal',
  'palao',
  'palar',
  'palas',
  'palau',
  'palco',
  'palea',
  'paleo',
  'palha',
  'palio',
  'palma',
  'palmo',
  'palor',
  'palos',
  'palta',
  'pambo',
  'pamoa',
  'pampa',
  'pampo',
  'panal',
  'panao',
  'panar',
  'panax',
  'panca',
  'panco',
  'panda',
  'panga',
  'pango',
  'panhe',
  'panhi',
  'panis',
  'panje',
  'panos',
  'panri',
  'panro',
  'panta',
  'panto',
  'panus',
  'paolo',
  'paolu',
  'papai',
  'papal',
  'papam',
  'papao',
  'papar',
  'papas',
  'papea',
  'papei',
  'papel',
  'papem',
  'papes',
  'papio',
  'papos',
  'papou',
  'papua',
  'papuo',
  'paqua',
  'paque',
  'paqui',
  'parai',
  'paral',
  'param',
  'parao',
  'parar',
  'paras',
  'parau',
  'parba',
  'parca',
  'parco',
  'parda',
  'pardo',
  'parea',
  'parei',
  'parem',
  'pareo',
  'pares',
  'pargo',
  'paria',
  'pario',
  'parir',
  'paris',
  'pariu',
  'parma',
  'parne',
  'parno',
  'parol',
  'parou',
  'parse',
  'parsi',
  'parta',
  'parte',
  'parti',
  'parto',
  'parua',
  'parui',
  'parum',
  'parus',
  'parvi',
  'parvo',
  'parvu',
  'pasea',
  'pasep',
  'pasma',
  'pasme',
  'pasmo',
  'passa',
  'passe',
  'passo',
  'pasta',
  'paste',
  'pasto',
  'patai',
  'patao',
  'patas',
  'patau',
  'pataz',
  'patel',
  'patem',
  'pateo',
  'pater',
  'patia',
  'patim',
  'patio',
  'patis',
  'patoa',
  'patos',
  'patua',
  'patum',
  'paual',
  'pauda',
  'pauis',
  'paula',
  'paulo',
  'pauls',
  'pauna',
  'paura',
  'pausa',
  'pauta',
  'paute',
  'pauto',
  'pauxi',
  'paval',
  'pavao',
  'pavia',
  'pavio',
  'pavoa',
  'pavol',
  'pavor',
  'paxau',
  'paxia',
  'pazao',
  'pazes',
  'pcdob',
  'pdvsa',
  'peaca',
  'peana',
  'peane',
  'peano',
  'pebas',
  'pecai',
  'pecam',
  'pecar',
  'pecas',
  'pecem',
  'pecoi',
  'pecou',
  'pecte',
  'pedal',
  'pedem',
  'pedes',
  'pedia',
  'pedir',
  'pedis',
  'pediu',
  'pedra',
  'pedro',
  'pegai',
  'pegam',
  'pegao',
  'pegar',
  'pegas',
  'pegma',
  'pegol',
  'pegos',
  'pegou',
  'pegue',
  'peida',
  'peide',
  'peido',
  'peipa',
  'peita',
  'peite',
  'peito',
  'peixe',
  'pejar',
  'pejos',
  'pelai',
  'pelam',
  'pelao',
  'pelar',
  'pelas',
  'pelei',
  'pelem',
  'peles',
  'peleu',
  'pelga',
  'pelia',
  'pelio',
  'pelma',
  'pelor',
  'pelos',
  'pelou',
  'pelta',
  'pelve',
  'pembe',
  'pemom',
  'pempa',
  'penai',
  'penal',
  'penam',
  'penao',
  'penar',
  'penas',
  'penca',
  'pence',
  'penco',
  'penda',
  'pende',
  'pendi',
  'pendo',
  'penea',
  'penei',
  'penem',
  'penes',
  'penha',
  'penia',
  'penio',
  'penis',
  'penol',
  'penou',
  'pensa',
  'pense',
  'penso',
  'penta',
  'pente',
  'peoes',
  'peona',
  'peone',
  'peote',
  'pepel',
  'pepes',
  'pepia',
  'peplo',
  'pepse',
  'peque',
  'pequi',
  'peral',
  'peras',
  'perau',
  'perca',
  'perce',
  'perco',
  'perda',
  'perde',
  'perdi',
  'perea',
  'perez',
  'perna',
  'perne',
  'perno',
  'peroa',
  'peros',
  'perre',
  'perro',
  'persa',
  'perto',
  'perua',
  'perum',
  'perus',
  'pesai',
  'pesam',
  'pesao',
  'pesar',
  'pesas',
  'pesca',
  'pesco',
  'pesei',
  'pesem',
  'peses',
  'pesme',
  'pesos',
  'pesou',
  'pespe',
  'pesse',
  'peste',
  'petal',
  'petao',
  'petar',
  'petas',
  'petem',
  'peter',
  'petia',
  'petim',
  'petit',
  'petiu',
  'petiz',
  'petos',
  'petra',
  'petri',
  'petro',
  'petum',
  'peuco',
  'peuga',
  'peule',
  'peumo',
  'peuva',
  'pexao',
  'pexem',
  'pexia',
  'pexim',
  'pezao',
  'pezes',
  'phnom',
  'piaba',
  'piaca',
  'piaco',
  'piada',
  'piade',
  'piado',
  'piafe',
  'piaga',
  'piais',
  'piamo',
  'piano',
  'piape',
  'piara',
  'piata',
  'piaui',
  'piaus',
  'piava',
  'picai',
  'pical',
  'picam',
  'picao',
  'picar',
  'picas',
  'picau',
  'piceo',
  'picha',
  'piche',
  'pichi',
  'picho',
  'picle',
  'picoa',
  'picos',
  'picou',
  'picre',
  'picro',
  'picto',
  'picua',
  'picui',
  'picul',
  'picum',
  'pidao',
  'piego',
  'pieis',
  'pielo',
  'piemo',
  'piere',
  'piero',
  'piese',
  'pieta',
  'pieze',
  'piezo',
  'pifai',
  'pifam',
  'pifao',
  'pifar',
  'pifas',
  'pifei',
  'pifem',
  'pifes',
  'pifio',
  'pifou',
  'pigal',
  'pigar',
  'pigeo',
  'pigeu',
  'pigia',
  'pigma',
  'pigro',
  'pigua',
  'piina',
  'pijin',
  'pilao',
  'pilar',
  'pilau',
  'pileo',
  'pileu',
  'pilha',
  'pilhe',
  'pilho',
  'pilim',
  'pilio',
  'pilma',
  'pilra',
  'pinar',
  'pinaz',
  'pinca',
  'pince',
  'pinco',
  'pinda',
  'pindi',
  'pindo',
  'pindu',
  'pinel',
  'pineo',
  'pineu',
  'pinga',
  'pingo',
  'pinha',
  'pinhe',
  'pinho',
  'pinol',
  'pinos',
  'pinta',
  'pinte',
  'pinto',
  'pioca',
  'pioes',
  'pioia',
  'pioie',
  'piola',
  'pioli',
  'piona',
  'piono',
  'piora',
  'piore',
  'pioro',
  'piose',
  'pipal',
  'pipao',
  'pipar',
  'pipas',
  'piper',
  'pipio',
  'pipra',
  'piqua',
  'pique',
  'piqui',
  'pirai',
  'piral',
  'pirao',
  'pirar',
  'piras',
  'pirau',
  'pirca',
  'pirea',
  'pires',
  'pireu',
  'pirex',
  'pirgo',
  'piria',
  'pirio',
  'piris',
  'piriz',
  'piroa',
  'pirol',
  'pirro',
  'pirua',
  'pisai',
  'pisam',
  'pisao',
  'pisar',
  'pisas',
  'pisca',
  'pisco',
  'pisei',
  'pisem',
  'piseo',
  'pises',
  'piseu',
  'pisos',
  'pisou',
  'pissa',
  'pista',
  'pitao',
  'pitar',
  'pitas',
  'pitem',
  'piteu',
  'pitia',
  'pitio',
  'pitis',
  'pitiu',
  'pitom',
  'piton',
  'pitos',
  'pitot',
  'pitua',
  'pitui',
  'piuba',
  'piuca',
  'piugo',
  'piuma',
  'piuna',
  'piuva',
  'pivos',
  'pixel',
  'pixeu',
  'pixis',
  'pixua',
  'pizza',
  'placa',
  'place',
  'plaga',
  'plana',
  'plane',
  'plani',
  'plano',
  'plapo',
  'plato',
  'plebe',
  'plena',
  'pleno',
  'pleon',
  'plexo',
  'ploce',
  'plome',
  'plomo',
  'plota',
  'plote',
  'ploto',
  'pluga',
  'pluge',
  'plugo',
  'pluma',
  'pluri',
  'plush',
  'pluto',
  'pmspa',
  'pneus',
  'pnhis',
  'pnigo',
  'poacu',
  'poaia',
  'poare',
  'pobre',
  'pocal',
  'pocao',
  'pocar',
  'pocas',
  'poche',
  'pocho',
  'pocos',
  'podai',
  'podal',
  'podam',
  'podao',
  'podar',
  'podas',
  'podei',
  'podem',
  'poder',
  'podes',
  'podex',
  'podia',
  'podio',
  'podoa',
  'podoi',
  'podon',
  'podou',
  'podre',
  'poejo',
  'poema',
  'poeta',
  'pofia',
  'pogea',
  'poial',
  'poiar',
  'poica',
  'poiel',
  'poios',
  'poisa',
  'poita',
  'pojar',
  'polao',
  'polar',
  'polau',
  'polca',
  'poldo',
  'polen',
  'polex',
  'polho',
  'polia',
  'polim',
  'polio',
  'polir',
  'polis',
  'poliu',
  'polje',
  'polme',
  'polmo',
  'polom',
  'polos',
  'polpa',
  'polux',
  'polvo',
  'pomal',
  'pomar',
  'pomas',
  'pomba',
  'pombe',
  'pombo',
  'pomes',
  'pomos',
  'pompa',
  'pompo',
  'ponca',
  'ponco',
  'ponda',
  'ponde',
  'pondo',
  'ponei',
  'ponfo',
  'pongo',
  'ponha',
  'ponhe',
  'ponho',
  'ponis',
  'ponje',
  'ponom',
  'ponta',
  'ponte',
  'ponto',
  'poone',
  'popao',
  'popia',
  'popos',
  'porao',
  'poras',
  'porca',
  'porco',
  'porei',
  'porem',
  'pores',
  'poria',
  'porio',
  'porme',
  'porno',
  'poros',
  'porra',
  'porre',
  'porro',
  'porta',
  'porte',
  'porto',
  'posai',
  'posam',
  'posar',
  'posas',
  'posca',
  'posei',
  'posem',
  'poses',
  'posou',
  'pospo',
  'possa',
  'posse',
  'posso',
  'posta',
  'poste',
  'posto',
  'potao',
  'potea',
  'potes',
  'potia',
  'potim',
  'potos',
  'potro',
  'potum',
  'pouca',
  'pouco',
  'poule',
  'poulo',
  'poupa',
  'poupe',
  'poupo',
  'pousa',
  'pouse',
  'pouso',
  'povao',
  'povoa',
  'povoe',
  'povoo',
  'povos',
  'poxim',
  'praca',
  'prado',
  'praga',
  'praia',
  'prais',
  'praja',
  'prana',
  'prase',
  'prata',
  'prato',
  'pravo',
  'praxe',
  'prazo',
  'prear',
  'preas',
  'prece',
  'preco',
  'prega',
  'prego',
  'preia',
  'prelo',
  'prema',
  'preme',
  'premi',
  'premo',
  'preon',
  'prepo',
  'presa',
  'preso',
  'preta',
  'preto',
  'preve',
  'previ',
  'preza',
  'preze',
  'prezo',
  'priao',
  'prima',
  'prime',
  'primi',
  'primo',
  'prino',
  'prion',
  'prior',
  'prita',
  'prius',
  'priva',
  'prive',
  'privo',
  'proal',
  'proar',
  'proas',
  'probo',
  'proca',
  'proco',
  'proiz',
  'prole',
  'propo',
  'prosa',
  'proto',
  'prova',
  'prove',
  'provi',
  'provo',
  'pruca',
  'pruga',
  'pruir',
  'prumo',
  'pruno',
  'psalo',
  'psama',
  'psaro',
  'pseca',
  'pseso',
  'pseto',
  'psico',
  'psilo',
  'psoco',
  'psofo',
  'psolo',
  'psora',
  'ptdob',
  'ptena',
  'ptere',
  'ptero',
  'ptise',
  'ptose',
  'puaco',
  'puacu',
  'puaia',
  'pubar',
  'pubas',
  'pubis',
  'pucas',
  'pucho',
  'pucto',
  'puctu',
  'puder',
  'pudim',
  'pudor',
  'puela',
  'pueri',
  'pugil',
  'puita',
  'pujar',
  'pulai',
  'pulam',
  'pulao',
  'pular',
  'pulas',
  'pulei',
  'pulem',
  'pules',
  'pulex',
  'pulga',
  'pulgo',
  'pulha',
  'pulim',
  'puliz',
  'pulos',
  'pulou',
  'pulsa',
  'pulse',
  'pulso',
  'pumas',
  'pumbo',
  'punam',
  'punas',
  'punde',
  'punem',
  'punes',
  'punga',
  'punha',
  'punho',
  'punia',
  'punir',
  'punis',
  'puniu',
  'punks',
  'punra',
  'pupal',
  'pupar',
  'pural',
  'puras',
  'pureu',
  'purga',
  'purgo',
  'purim',
  'puros',
  'purui',
  'purus',
  'puser',
  'putal',
  'putas',
  'putos',
  'puvia',
  'puxai',
  'puxam',
  'puxao',
  'puxar',
  'puxas',
  'puxei',
  'puxem',
  'puxes',
  'puxos',
  'puxou',
  'puzos',
  'qaeda',
  'qatar',
  'quaco',
  'quado',
  'quaga',
  'quais',
  'quale',
  'quali',
  'quara',
  'quari',
  'quark',
  'quaro',
  'quasa',
  'quase',
  'quata',
  'quati',
  'qubit',
  'quebe',
  'queca',
  'quece',
  'queci',
  'queda',
  'quede',
  'quedo',
  'queia',
  'quele',
  'quemi',
  'quemo',
  'queno',
  'quepe',
  'quepi',
  'quera',
  'quere',
  'queri',
  'quero',
  'quete',
  'queto',
  'queza',
  'quibe',
  'quica',
  'quice',
  'quico',
  'quido',
  'quija',
  'quile',
  'quili',
  'quilo',
  'quime',
  'quimi',
  'quimo',
  'quina',
  'quino',
  'quipa',
  'quipe',
  'quipo',
  'quire',
  'quiri',
  'quiro',
  'quita',
  'quite',
  'quiti',
  'quito',
  'quiui',
  'quivi',
  'quixa',
  'quixo',
  'quiza',
  'quota',
  'rabal',
  'rabao',
  'rabat',
  'rabaz',
  'rabdo',
  'rabel',
  'rabia',
  'rabil',
  'rabos',
  'racao',
  'racas',
  'racau',
  'racha',
  'rache',
  'racho',
  'racio',
  'racor',
  'racum',
  'radao',
  'radar',
  'radia',
  'radie',
  'radio',
  'radom',
  'radon',
  'rafar',
  'rafia',
  'ragau',
  'ragio',
  'ragla',
  'rague',
  'raiai',
  'raial',
  'raiam',
  'raiao',
  'raiar',
  'raias',
  'raide',
  'raiei',
  'raiem',
  'raies',
  'raile',
  'raiom',
  'raios',
  'raiou',
  'raisa',
  'raita',
  'raiva',
  'raivo',
  'rajai',
  'rajam',
  'rajao',
  'rajar',
  'rajas',
  'rajei',
  'rajem',
  'rajes',
  'rajou',
  'ralai',
  'ralam',
  'ralao',
  'ralar',
  'ralas',
  'ralei',
  'ralem',
  'rales',
  'ralha',
  'ralhe',
  'ralho',
  'ralos',
  'ralou',
  'ramal',
  'ramao',
  'ramar',
  'ramas',
  'rambo',
  'rameo',
  'rameu',
  'ramie',
  'ramno',
  'ramon',
  'ramos',
  'rampa',
  'ranal',
  'ranco',
  'range',
  'rangi',
  'ranho',
  'ranja',
  'ranjo',
  'ranus',
  'rapai',
  'rapam',
  'rapao',
  'rapar',
  'rapas',
  'rapaz',
  'rapei',
  'rapel',
  'rapem',
  'rapes',
  'rapia',
  'rapou',
  'rapta',
  'rapte',
  'rapto',
  'raque',
  'raqui',
  'rarar',
  'raras',
  'rarea',
  'raros',
  'rasao',
  'rasar',
  'rasas',
  'rasca',
  'rasga',
  'rasgo',
  'rasos',
  'raspa',
  'raspe',
  'raspo',
  'raste',
  'rasto',
  'ratao',
  'ratar',
  'ratas',
  'ratea',
  'ratel',
  'ratim',
  'ratio',
  'ratos',
  'ratus',
  'rauli',
  'rawls',
  'razao',
  'reagi',
  'reais',
  'reaja',
  'reajo',
  'reale',
  'reata',
  'reate',
  'reato',
  'reave',
  'rebar',
  'rebem',
  'reboa',
  'reboe',
  'reboo',
  'rebus',
  'recai',
  'recao',
  'recar',
  'recea',
  'recem',
  'recha',
  'recho',
  'recio',
  'recos',
  'recto',
  'recua',
  'recue',
  'recuo',
  'redea',
  'redem',
  'redes',
  'redia',
  'redil',
  'redor',
  'redox',
  'reduz',
  'reege',
  'refaz',
  'refem',
  'refez',
  'refil',
  'refiz',
  'regai',
  'regal',
  'regam',
  'regao',
  'regar',
  'regas',
  'regei',
  'regem',
  'reger',
  'reges',
  'regeu',
  'regia',
  'regie',
  'regio',
  'regis',
  'regma',
  'regos',
  'regou',
  'regra',
  'regre',
  'regro',
  'regua',
  'regue',
  'reich',
  'reide',
  'reima',
  'reina',
  'reine',
  'reino',
  'reivo',
  'rejam',
  'rejao',
  'rejas',
  'relar',
  'reler',
  'reles',
  'releu',
  'relfa',
  'relho',
  'relia',
  'relva',
  'remai',
  'remal',
  'remam',
  'remar',
  'remas',
  'remei',
  'remel',
  'remem',
  'remes',
  'remia',
  'remir',
  'remis',
  'remiu',
  'remix',
  'remoa',
  'remoe',
  'remoi',
  'remoo',
  'remos',
  'remou',
  'renal',
  'renan',
  'renas',
  'renda',
  'rende',
  'rendi',
  'rendo',
  'renge',
  'rengo',
  'renio',
  'rente',
  'renzo',
  'reoge',
  'repes',
  'repia',
  'replo',
  'repoe',
  'repor',
  'repos',
  'repto',
  'repus',
  'reque',
  'reriu',
  'reses',
  'resma',
  'respe',
  'respo',
  'ressa',
  'resta',
  'reste',
  'resto',
  'retal',
  'retar',
  'retas',
  'retem',
  'reter',
  'retor',
  'retos',
  'retre',
  'retro',
  'reuma',
  'reuna',
  'reune',
  'reuni',
  'reuno',
  'reuso',
  'revel',
  'revem',
  'rever',
  'reves',
  'revia',
  'revir',
  'reviu',
  'revoa',
  'rexia',
  'rexio',
  'rezai',
  'rezam',
  'rezao',
  'rezar',
  'rezas',
  'rezei',
  'rezem',
  'rezes',
  'rezou',
  'riade',
  'riais',
  'riamo',
  'riana',
  'riane',
  'riano',
  'riata',
  'ribar',
  'ribas',
  'ribla',
  'ricao',
  'ricar',
  'ricas',
  'ricio',
  'ricol',
  'ricos',
  'ricto',
  'ridas',
  'rides',
  'ridos',
  'rieis',
  'riete',
  'rifai',
  'rifam',
  'rifao',
  'rifar',
  'rifas',
  'rifei',
  'rifem',
  'rifes',
  'rifeu',
  'rifle',
  'rifou',
  'rifte',
  'rigar',
  'rigel',
  'rigio',
  'rigol',
  'rigor',
  'rigos',
  'rijal',
  'rijao',
  'rijar',
  'rijas',
  'rijos',
  'rilar',
  'rildo',
  'rilha',
  'rilos',
  'rimai',
  'rimam',
  'rimar',
  'rimas',
  'rimei',
  'rimel',
  'rimem',
  'rimes',
  'rimos',
  'rimou',
  'rinal',
  'rinco',
  'rindo',
  'ringo',
  'rinio',
  'rinto',
  'rioja',
  'riola',
  'ripal',
  'ripao',
  'ripar',
  'ripas',
  'ripeu',
  'ripia',
  'ripio',
  'rique',
  'riram',
  'rirao',
  'riras',
  'rirei',
  'rirem',
  'rires',
  'riria',
  'rirmo',
  'risao',
  'risca',
  'risco',
  'risor',
  'risos',
  'risse',
  'risso',
  'riste',
  'ritao',
  'ritas',
  'ritio',
  'ritmo',
  'riton',
  'ritos',
  'riuta',
  'rival',
  'rivea',
  'rixar',
  'rixas',
  'rizar',
  'roais',
  'roalo',
  'roamo',
  'roana',
  'robao',
  'robaz',
  'rober',
  'robia',
  'robin',
  'roble',
  'robor',
  'robos',
  'robot',
  'robur',
  'rocai',
  'rocal',
  'rocam',
  'rocao',
  'rocar',
  'rocas',
  'rocaz',
  'rocei',
  'rocem',
  'roces',
  'rocha',
  'rocim',
  'rocio',
  'roclo',
  'rocou',
  'rodai',
  'rodal',
  'rodam',
  'rodao',
  'rodar',
  'rodas',
  'rodea',
  'rodei',
  'rodel',
  'rodem',
  'rodeo',
  'rodes',
  'rodim',
  'rodio',
  'rodos',
  'rodou',
  'roeis',
  'roemo',
  'roera',
  'rogai',
  'rogal',
  'rogam',
  'rogar',
  'rogas',
  'rogem',
  'roger',
  'roges',
  'rogos',
  'rogou',
  'rogue',
  'roiam',
  'roias',
  'roida',
  'roido',
  'roila',
  'rojao',
  'rojar',
  'rolai',
  'rolam',
  'rolao',
  'rolar',
  'rolas',
  'rolaz',
  'rolei',
  'rolem',
  'roles',
  'rolha',
  'rolho',
  'rolim',
  'rolos',
  'rolou',
  'romao',
  'romar',
  'romas',
  'rombo',
  'romeu',
  'rompa',
  'rompe',
  'rompi',
  'rompo',
  'ronan',
  'ronca',
  'ronco',
  'ronda',
  'ronde',
  'rondo',
  'ronei',
  'ronga',
  'ronge',
  'rongo',
  'ronha',
  'ronim',
  'ronto',
  'ropia',
  'roque',
  'rorar',
  'rosal',
  'rosao',
  'rosar',
  'rosas',
  'rosca',
  'rosea',
  'roseo',
  'rosio',
  'rosna',
  'rosne',
  'rosno',
  'rosto',
  'rotar',
  'rotas',
  'rotea',
  'rotia',
  'rotim',
  'rotor',
  'rotos',
  'rouba',
  'roube',
  'roubo',
  'rouca',
  'rouco',
  'rouen',
  'rouge',
  'round',
  'roupa',
  'roxas',
  'roxos',
  'ruaca',
  'ruano',
  'rubai',
  'rubao',
  'ruben',
  'rubeo',
  'rubim',
  'rubio',
  'rubis',
  'rublo',
  'rubor',
  'rubra',
  'rubro',
  'rucar',
  'rucas',
  'rucos',
  'rudao',
  'rudes',
  'rudez',
  'ruela',
  'rufai',
  'rufam',
  'rufao',
  'rufar',
  'rufas',
  'rufei',
  'rufem',
  'rufes',
  'rufio',
  'rufol',
  'rufos',
  'rufou',
  'rugai',
  'rugam',
  'rugar',
  'rugas',
  'rugbi',
  'ruges',
  'rugia',
  'rugio',
  'rugir',
  'rugis',
  'rugiu',
  'rugou',
  'rugue',
  'ruiam',
  'ruias',
  'ruida',
  'ruido',
  'ruimo',
  'ruina',
  'ruins',
  'ruira',
  'ruiva',
  'ruivo',
  'rujam',
  'rujas',
  'rular',
  'rulul',
  'rumai',
  'rumam',
  'rumar',
  'rumas',
  'rumei',
  'rumem',
  'rumen',
  'rumes',
  'rumex',
  'rumor',
  'rumos',
  'rumou',
  'rumpi',
  'runco',
  'rundo',
  'runga',
  'runim',
  'runle',
  'runto',
  'ruolz',
  'rupar',
  'rupia',
  'rupsa',
  'rupto',
  'rupul',
  'rural',
  'rusco',
  'rusga',
  'rusma',
  'russa',
  'russo',
  'rutal',
  'rutar',
  'rutas',
  'rutea',
  'rutim',
  'rutul',
  'saara',
  'sabal',
  'sabao',
  'sabei',
  'sabem',
  'saber',
  'sabes',
  'sabeu',
  'sabia',
  'sabin',
  'sabio',
  'sabir',
  'sabiu',
  'sable',
  'saboi',
  'sabor',
  'sabra',
  'sabre',
  'sacai',
  'sacal',
  'sacam',
  'sacao',
  'sacar',
  'sacas',
  'sacha',
  'sache',
  'sacia',
  'sacie',
  'sacio',
  'sacis',
  'sacos',
  'sacou',
  'sacra',
  'sacre',
  'sacro',
  'sacta',
  'sacti',
  'sacue',
  'sadal',
  'sadao',
  'sadia',
  'sadim',
  'sadio',
  'sadoc',
  'sadra',
  'saeta',
  'safai',
  'safam',
  'safao',
  'safar',
  'safas',
  'safei',
  'safem',
  'safeo',
  'safer',
  'safes',
  'safio',
  'safos',
  'safou',
  'safra',
  'safre',
  'sagai',
  'sagas',
  'sagaz',
  'sagez',
  'sagio',
  'sagma',
  'sagra',
  'sagre',
  'sagro',
  'sagua',
  'sagui',
  'sagum',
  'sagus',
  'saial',
  'saiam',
  'saiao',
  'saias',
  'saiba',
  'saibo',
  'saica',
  'saico',
  'saida',
  'saide',
  'saido',
  'saiga',
  'saije',
  'saimo',
  'saine',
  'saino',
  'saint',
  'saipe',
  'saira',
  'saire',
  'sairi',
  'saita',
  'saium',
  'saiva',
  'saixe',
  'sajum',
  'sajus',
  'salam',
  'salao',
  'salas',
  'salaz',
  'salca',
  'salda',
  'salde',
  'saldo',
  'salem',
  'sales',
  'salga',
  'salgo',
  'salio',
  'salix',
  'salma',
  'salmi',
  'salmo',
  'saloa',
  'salol',
  'salpa',
  'salsa',
  'salso',
  'salta',
  'salte',
  'salto',
  'salva',
  'salve',
  'salvo',
  'samao',
  'samba',
  'sambe',
  'sambo',
  'samea',
  'sameu',
  'samio',
  'samoa',
  'sampe',
  'samur',
  'sanai',
  'sanam',
  'sanar',
  'sanas',
  'sanca',
  'sanco',
  'sandi',
  'sanea',
  'sanei',
  'sanem',
  'sanes',
  'sango',
  'sanha',
  'sanie',
  'sanio',
  'sanou',
  'sansa',
  'santa',
  'sante',
  'santo',
  'sapal',
  'sapao',
  'sapar',
  'sapes',
  'sapeu',
  'sapim',
  'sapio',
  'sapos',
  'sapov',
  'sapro',
  'sapua',
  'saque',
  'saqui',
  'sarah',
  'sarai',
  'saram',
  'saran',
  'sarao',
  'sarar',
  'saras',
  'sarau',
  'sarca',
  'sarco',
  'sarda',
  'sarei',
  'sarem',
  'sares',
  'sargo',
  'sarna',
  'sarne',
  'saroe',
  'saros',
  'sarou',
  'sarro',
  'sarta',
  'sarua',
  'sarue',
  'sarug',
  'sauas',
  'sauba',
  'sauco',
  'sauda',
  'saude',
  'saudi',
  'saudo',
  'sauia',
  'sauim',
  'sauis',
  'saulo',
  'sauna',
  'sauni',
  'saupe',
  'saura',
  'sauro',
  'sauva',
  'saval',
  'savel',
  'savia',
  'savio',
  'saxao',
  'saxeo',
  'saxes',
  'sayao',
  'sazao',
  'scene',
  'scone',
  'scope',
  'score',
  'seada',
  'seara',
  'sebas',
  'sebel',
  'sebes',
  'sebos',
  'secad',
  'secai',
  'secal',
  'secam',
  'secao',
  'secar',
  'secas',
  'secia',
  'secio',
  'secos',
  'secou',
  'secto',
  'secua',
  'sedal',
  'sedao',
  'sedar',
  'sedas',
  'sedem',
  'sedes',
  'sedex',
  'sedia',
  'sedie',
  'sedio',
  'seduz',
  'sefaz',
  'sefel',
  'sefia',
  'segao',
  'segar',
  'segne',
  'segno',
  'segue',
  'segui',
  'seibo',
  'seica',
  'seice',
  'seima',
  'seios',
  'seira',
  'seisa',
  'seita',
  'seiva',
  'seixo',
  'sejam',
  'sejas',
  'selai',
  'selam',
  'selao',
  'selar',
  'selas',
  'selei',
  'selem',
  'seles',
  'selha',
  'selia',
  'selic',
  'selim',
  'selio',
  'selma',
  'seloa',
  'selos',
  'selou',
  'selva',
  'sembe',
  'semea',
  'semei',
  'semen',
  'semio',
  'semis',
  'senac',
  'senai',
  'senal',
  'senao',
  'senas',
  'senda',
  'sendo',
  'senes',
  'senha',
  'senho',
  'senil',
  'senio',
  'senir',
  'senna',
  'senos',
  'senra',
  'senso',
  'sensu',
  'senta',
  'sente',
  'senti',
  'sento',
  'sepia',
  'sepio',
  'sepse',
  'septo',
  'seque',
  'sequi',
  'seral',
  'serao',
  'seras',
  'serbo',
  'serei',
  'serem',
  'seres',
  'serfo',
  'seria',
  'serie',
  'serio',
  'seriu',
  'serja',
  'serna',
  'serpa',
  'serpe',
  'serra',
  'serre',
  'serro',
  'serum',
  'serva',
  'serve',
  'servi',
  'servo',
  'serze',
  'serzi',
  'sesai',
  'sesea',
  'seseg',
  'sesgo',
  'sesia',
  'sesta',
  'sesto',
  'setar',
  'setas',
  'seter',
  'setes',
  'setia',
  'setor',
  'setos',
  'setra',
  'seval',
  'sevar',
  'sexar',
  'sexos',
  'sexta',
  'sexto',
  'sezao',
  'short',
  'shows',
  'shoyu',
  'sialo',
  'siame',
  'sibal',
  'sibar',
  'sicaf',
  'sicio',
  'siclo',
  'sicon',
  'sidas',
  'sideo',
  'sidom',
  'sidos',
  'sidra',
  'siena',
  'sifao',
  'sifia',
  'sigam',
  'sigas',
  'sigeu',
  'sigla',
  'sigma',
  'sigmo',
  'signo',
  'siler',
  'silex',
  'silfo',
  'silho',
  'silis',
  'siloe',
  'silos',
  'silte',
  'silva',
  'silve',
  'silvo',
  'simao',
  'simbi',
  'simbo',
  'simei',
  'simel',
  'simil',
  'simio',
  'simon',
  'simum',
  'sinal',
  'sinao',
  'sinar',
  'sinas',
  'sindi',
  'sindo',
  'sineu',
  'singa',
  'sinha',
  'sinho',
  'sinje',
  'sinop',
  'sinos',
  'sinta',
  'sinto',
  'sinus',
  'sinxo',
  'sioba',
  'siode',
  'siote',
  'sipai',
  'sipia',
  'sique',
  'sirex',
  'sirfo',
  'siria',
  'sirio',
  'siris',
  'siriu',
  'sirlo',
  'sirma',
  'sirte',
  'sirva',
  'sirvo',
  'sirza',
  'sirzo',
  'sisal',
  'sisao',
  'sisar',
  'sisgo',
  'sisma',
  'sisme',
  'sismo',
  'sisor',
  'sisos',
  'sisso',
  'sisto',
  'sitar',
  'sitas',
  'sitia',
  'sitie',
  'sitio',
  'sitos',
  'sitra',
  'situa',
  'situe',
  'situo',
  'siusi',
  'sivao',
  'sivia',
  'sivom',
  'sizau',
  'skate',
  'slide',
  'snobe',
  'soada',
  'soado',
  'soais',
  'soaje',
  'soajo',
  'soala',
  'soamo',
  'soara',
  'soava',
  'sobem',
  'sobes',
  'sobeu',
  'sobio',
  'sobpe',
  'sobra',
  'sobre',
  'sobro',
  'socai',
  'socam',
  'socao',
  'socar',
  'socas',
  'socia',
  'socio',
  'soclo',
  'socoi',
  'socol',
  'socos',
  'socou',
  'sodar',
  'sodio',
  'sodra',
  'sodre',
  'soeis',
  'soemo',
  'sofas',
  'sofia',
  'sofra',
  'sofre',
  'sofri',
  'sofro',
  'sogar',
  'sogra',
  'sogro',
  'sogua',
  'soila',
  'soima',
  'soito',
  'sojas',
  'solai',
  'solam',
  'solao',
  'solar',
  'solas',
  'solau',
  'solaz',
  'solda',
  'solde',
  'soldo',
  'solei',
  'solem',
  'soleo',
  'soles',
  'solho',
  'solio',
  'solos',
  'solou',
  'solta',
  'solte',
  'solto',
  'somai',
  'somam',
  'somar',
  'somas',
  'somei',
  'somem',
  'somes',
  'somos',
  'somou',
  'sonar',
  'sonda',
  'sonde',
  'sondo',
  'songa',
  'songo',
  'sonha',
  'sonhe',
  'sonho',
  'sonia',
  'sonos',
  'sonsa',
  'sonso',
  'sopao',
  'sopas',
  'sopes',
  'soplo',
  'sopor',
  'sopra',
  'sopre',
  'sopro',
  'soque',
  'soral',
  'sorar',
  'sorbo',
  'sorca',
  'sorda',
  'sorex',
  'sorgo',
  'soria',
  'sorna',
  'soror',
  'soros',
  'sorri',
  'sorta',
  'sorte',
  'sorti',
  'sorva',
  'sorve',
  'sorvi',
  'sorvo',
  'sosia',
  'sosso',
  'sotaa',
  'sotao',
  'sotar',
  'soter',
  'sotia',
  'sotos',
  'soube',
  'soure',
  'sousa',
  'souto',
  'souza',
  'sovai',
  'sovam',
  'sovar',
  'sovas',
  'sovei',
  'sovem',
  'soves',
  'soveu',
  'sovou',
  'sovro',
  'sozal',
  'spain',
  'spins',
  'split',
  'spray',
  'stafe',
  'stato',
  'stela',
  'steno',
  'stilb',
  'stoma',
  'stops',
  'stout',
  'strip',
  'suaco',
  'suacu',
  'suada',
  'suado',
  'suais',
  'suajo',
  'suamo',
  'suano',
  'suara',
  'suare',
  'suaro',
  'suava',
  'suave',
  'suazi',
  'subam',
  'subas',
  'suber',
  'subia',
  'subir',
  'subis',
  'subiu',
  'sucao',
  'sucar',
  'sucho',
  'sucio',
  'sucos',
  'sucre',
  'sucto',
  'sudao',
  'sudar',
  'sudro',
  'sueca',
  'sueco',
  'suede',
  'sueis',
  'sueli',
  'suely',
  'suemo',
  'sueto',
  'suevo',
  'sufle',
  'sugai',
  'sugam',
  'sugar',
  'sugas',
  'sugou',
  'sugue',
  'suica',
  'suico',
  'suida',
  'suina',
  'suino',
  'suite',
  'sujai',
  'sujam',
  'sujao',
  'sujar',
  'sujas',
  'sujei',
  'sujem',
  'sujes',
  'sujos',
  'sujou',
  'sulao',
  'sular',
  'sulca',
  'sulco',
  'sulfa',
  'sulfo',
  'sulia',
  'sumam',
  'sumas',
  'sumbe',
  'sumbo',
  'sumea',
  'sumia',
  'sumir',
  'sumis',
  'sumiu',
  'sumos',
  'sundo',
  'sunfa',
  'sunga',
  'sunto',
  'suome',
  'suomi',
  'super',
  'supoe',
  'supor',
  'supos',
  'supra',
  'supre',
  'supri',
  'supro',
  'supus',
  'sural',
  'surca',
  'surda',
  'surdo',
  'surfa',
  'surfo',
  'surge',
  'surgi',
  'surgo',
  'surim',
  'surio',
  'surja',
  'surjo',
  'surno',
  'surnu',
  'surra',
  'surre',
  'surro',
  'surta',
  'surte',
  'surto',
  'surui',
  'susan',
  'susao',
  'susep',
  'sushi',
  'susio',
  'sussu',
  'susta',
  'suste',
  'susti',
  'susto',
  'sutao',
  'sutar',
  'sutia',
  'sutil',
  'sutis',
  'sutra',
  'suvao',
  'suxar',
  'svava',
  'taaca',
  'tabai',
  'tabao',
  'tabas',
  'tabaz',
  'tabla',
  'taboa',
  'tabor',
  'tabua',
  'tacai',
  'tacam',
  'tacao',
  'tacar',
  'tacas',
  'tacau',
  'tacha',
  'tache',
  'tacho',
  'tacia',
  'tacio',
  'tacle',
  'tacos',
  'tacou',
  'tacto',
  'tacua',
  'tadeo',
  'tadeu',
  'tafal',
  'tafia',
  'tafio',
  'taful',
  'tagal',
  'tagar',
  'tagaz',
  'tagba',
  'tagma',
  'tagna',
  'tagra',
  'tagua',
  'tahir',
  'taias',
  'taibu',
  'taico',
  'taifa',
  'taifo',
  'taiga',
  'taipa',
  'taipe',
  'taipo',
  'taipu',
  'taira',
  'taisa',
  'taita',
  'taiti',
  'taixi',
  'taixo',
  'tajal',
  'tajas',
  'tajaz',
  'talao',
  'talar',
  'talas',
  'talco',
  'taler',
  'talha',
  'talhe',
  'talho',
  'talia',
  'talim',
  'talin',
  'talio',
  'talma',
  'talmi',
  'talol',
  'talor',
  'talos',
  'talpa',
  'talus',
  'tamam',
  'tamao',
  'tamar',
  'tamba',
  'tambi',
  'tambo',
  'tambu',
  'tamia',
  'tamil',
  'tamim',
  'tamir',
  'tamis',
  'tampa',
  'tampe',
  'tampo',
  'tamul',
  'tamuz',
  'tanal',
  'tanar',
  'tando',
  'tanga',
  'tange',
  'tangi',
  'tango',
  'tanho',
  'tania',
  'tanio',
  'tanis',
  'tanja',
  'tanjo',
  'tanso',
  'tanta',
  'tanto',
  'tanus',
  'taoca',
  'tapai',
  'tapam',
  'tapao',
  'tapar',
  'tapas',
  'tapea',
  'tapei',
  'tapem',
  'tapes',
  'tapia',
  'tapii',
  'tapir',
  'tapiu',
  'tapiz',
  'tapoa',
  'tapou',
  'tapto',
  'tapua',
  'taque',
  'taqui',
  'tarao',
  'tarar',
  'taras',
  'tarau',
  'tarca',
  'tarda',
  'tarde',
  'tardo',
  'taria',
  'tarim',
  'tarja',
  'tarol',
  'tarpa',
  'tarro',
  'tarso',
  'tarta',
  'tarte',
  'tasio',
  'tasna',
  'tasso',
  'tasto',
  'tatai',
  'tatas',
  'tatau',
  'tatea',
  'tatil',
  'tatra',
  'tatsu',
  'tatua',
  'tatue',
  'tatui',
  'tatuo',
  'tatus',
  'tatze',
  'tauna',
  'tauro',
  'tauva',
  'tavao',
  'tavel',
  'tavoa',
  'taxai',
  'taxal',
  'taxam',
  'taxar',
  'taxas',
  'taxei',
  'taxem',
  'taxes',
  'taxis',
  'taxon',
  'taxou',
  'tchad',
  'tchau',
  'teaca',
  'teacu',
  'teada',
  'teame',
  'teano',
  'tearo',
  'tease',
  'teate',
  'tebas',
  'tebeu',
  'tecal',
  'tecam',
  'tecar',
  'tecas',
  'tecei',
  'tecem',
  'tecer',
  'teces',
  'teceu',
  'tecia',
  'tecla',
  'tecle',
  'teclo',
  'tecno',
  'tecto',
  'tecum',
  'tedeu',
  'tedio',
  'teera',
  'tegao',
  'tegbo',
  'tegme',
  'tegui',
  'teias',
  'teigo',
  'teima',
  'teime',
  'teimo',
  'teina',
  'teira',
  'teiro',
  'teiru',
  'teite',
  'teius',
  'teixe',
  'teixo',
  'telao',
  'telar',
  'telas',
  'telem',
  'teles',
  'telex',
  'telha',
  'telim',
  'telio',
  'teliz',
  'telmo',
  'telso',
  'temam',
  'temao',
  'temas',
  'temba',
  'tembe',
  'tembi',
  'temei',
  'temem',
  'temer',
  'temes',
  'temeu',
  'temia',
  'temio',
  'temis',
  'temne',
  'temor',
  'temos',
  'tempe',
  'tempo',
  'tenar',
  'tenaz',
  'tenca',
  'tenda',
  'tende',
  'tendi',
  'tendo',
  'tenea',
  'tengo',
  'tenha',
  'tenho',
  'tenia',
  'tenio',
  'tenis',
  'tenor',
  'tenra',
  'tenro',
  'tensa',
  'tense',
  'tenso',
  'tenta',
  'tente',
  'tento',
  'tenue',
  'teoro',
  'teose',
  'tepor',
  'teque',
  'terai',
  'terao',
  'teras',
  'terbo',
  'terca',
  'terco',
  'terei',
  'terem',
  'teres',
  'tereu',
  'tergo',
  'teria',
  'terio',
  'terlo',
  'terma',
  'termo',
  'terna',
  'terno',
  'terol',
  'terra',
  'terre',
  'terso',
  'tesao',
  'tesar',
  'tesas',
  'tesbi',
  'teses',
  'tesio',
  'tesis',
  'tesla',
  'tesno',
  'tesos',
  'tesse',
  'testa',
  'teste',
  'testo',
  'tesum',
  'tetar',
  'tetas',
  'teteu',
  'tetim',
  'tetio',
  'tetis',
  'tetos',
  'tetra',
  'tetro',
  'tetum',
  'teuba',
  'teudo',
  'teuto',
  'texas',
  'texto',
  'thome',
  'tiace',
  'tiade',
  'tiago',
  'tiano',
  'tiapi',
  'tiara',
  'tiaso',
  'tiata',
  'tibar',
  'tibau',
  'tibel',
  'tiber',
  'tibia',
  'tibio',
  'tibre',
  'tical',
  'ticao',
  'ticar',
  'ticio',
  'ticoa',
  'ticos',
  'ticto',
  'ticum',
  'tidal',
  'tidas',
  'tidio',
  'tidor',
  'tidos',
  'tiela',
  'tiena',
  'tiete',
  'tifao',
  'tifeu',
  'tifia',
  'tifis',
  'tiflo',
  'tigao',
  'tigre',
  'tiito',
  'tilar',
  'tilea',
  'tilho',
  'tilia',
  'tilim',
  'tilio',
  'tiloa',
  'tilos',
  'timao',
  'timar',
  'timba',
  'timbe',
  'timbo',
  'timbu',
  'times',
  'timeu',
  'timia',
  'timio',
  'timol',
  'timon',
  'timor',
  'tinam',
  'tinao',
  'tinas',
  'tinca',
  'tinea',
  'tinem',
  'tiner',
  'tines',
  'tinga',
  'tinge',
  'tingi',
  'tinha',
  'tinho',
  'tinia',
  'tinir',
  'tinis',
  'tiniu',
  'tinja',
  'tinjo',
  'tinor',
  'tinta',
  'tinto',
  'tioco',
  'tioge',
  'tione',
  'tiote',
  'tipas',
  'tipio',
  'tipis',
  'tiple',
  'tipoi',
  'tipos',
  'tique',
  'tiqui',
  'tirai',
  'tiram',
  'tirao',
  'tirar',
  'tiras',
  'tiraz',
  'tirea',
  'tirei',
  'tirem',
  'tireo',
  'tires',
  'tiria',
  'tirio',
  'tiris',
  'tirol',
  'tiros',
  'tirou',
  'tirso',
  'tirta',
  'tirza',
  'tisco',
  'tisso',
  'tissu',
  'titas',
  'titia',
  'titim',
  'titio',
  'tiuba',
  'tiupa',
  'tiuva',
  'tiver',
  'tizio',
  'tiziu',
  'tjdft',
  'tlaco',
  'tmema',
  'tmese',
  'toaca',
  'toada',
  'toano',
  'tobin',
  'tobiu',
  'tocai',
  'tocam',
  'tocar',
  'tocas',
  'tocha',
  'tocho',
  'tocia',
  'toclo',
  'tocos',
  'tocou',
  'todas',
  'todea',
  'todos',
  'toeza',
  'tofel',
  'togar',
  'togas',
  'togoi',
  'togua',
  'toira',
  'toiro',
  'toita',
  'tojal',
  'tojos',
  'tolao',
  'tolar',
  'tolas',
  'tolaz',
  'toldo',
  'toler',
  'tolha',
  'tolhe',
  'tolhi',
  'tolho',
  'tolia',
  'tolil',
  'tolos',
  'tolpe',
  'tolva',
  'tomai',
  'tomam',
  'tomao',
  'tomar',
  'tomas',
  'tomba',
  'tombe',
  'tombo',
  'tomei',
  'tomem',
  'tomes',
  'tomia',
  'tomim',
  'tomio',
  'tomix',
  'tomos',
  'tomou',
  'tonal',
  'tonar',
  'tonca',
  'tondo',
  'tonel',
  'toner',
  'tonfe',
  'tonga',
  'tongo',
  'tonho',
  'tonia',
  'tonoa',
  'tonta',
  'tonto',
  'tonus',
  'toona',
  'topai',
  'topam',
  'topar',
  'topas',
  'topaz',
  'topei',
  'topem',
  'topes',
  'topia',
  'topos',
  'topou',
  'toque',
  'torai',
  'toral',
  'torao',
  'torar',
  'toras',
  'torax',
  'torba',
  'torca',
  'torce',
  'torci',
  'torco',
  'torda',
  'tordo',
  'torem',
  'torga',
  'torgo',
  'torio',
  'torna',
  'torne',
  'torno',
  'torom',
  'toron',
  'toros',
  'torpe',
  'torra',
  'torre',
  'torro',
  'torso',
  'torta',
  'torto',
  'tosai',
  'tosam',
  'tosao',
  'tosar',
  'tosas',
  'tosca',
  'tosco',
  'tosei',
  'tosem',
  'toses',
  'tosga',
  'tosou',
  'tosse',
  'tossi',
  'tosso',
  'tosta',
  'toste',
  'tosto',
  'total',
  'totem',
  'touca',
  'toupa',
  'toura',
  'touro',
  'touta',
  'toxia',
  'tozia',
  'traca',
  'trace',
  'traco',
  'trade',
  'traem',
  'traga',
  'trago',
  'traia',
  'traio',
  'trair',
  'trais',
  'traiu',
  'traja',
  'traje',
  'trajo',
  'trale',
  'tralo',
  'trama',
  'trans',
  'trape',
  'trapo',
  'trara',
  'trata',
  'trate',
  'trato',
  'trava',
  'trave',
  'travo',
  'traze',
  'treco',
  'tredo',
  'trefe',
  'trefo',
  'trema',
  'treme',
  'tremi',
  'tremo',
  'trena',
  'treno',
  'trens',
  'trepa',
  'trepe',
  'trepo',
  'trere',
  'trero',
  'treso',
  'treta',
  'treva',
  'trevo',
  'treze',
  'trial',
  'triao',
  'triar',
  'tribo',
  'trico',
  'tridi',
  'triga',
  'trigo',
  'trina',
  'trino',
  'triol',
  'trios',
  'tripa',
  'tripe',
  'tripo',
  'trips',
  'troar',
  'trobo',
  'troca',
  'troce',
  'troco',
  'trofo',
  'troge',
  'troia',
  'trole',
  'trono',
  'tropa',
  'trota',
  'trote',
  'troto',
  'trova',
  'truao',
  'truco',
  'trude',
  'trudo',
  'trufa',
  'truta',
  'truxu',
  'tsela',
  'tuaca',
  'tuaia',
  'tubal',
  'tubar',
  'tubel',
  'tuber',
  'tubim',
  'tubos',
  'tucao',
  'tucho',
  'tucui',
  'tucum',
  'tudel',
  'tudos',
  'tudro',
  'tudum',
  'tufao',
  'tufar',
  'tufos',
  'tugir',
  'tugra',
  'tugue',
  'tuias',
  'tuins',
  'tuira',
  'tuiue',
  'tuius',
  'tujol',
  'tulha',
  'tulio',
  'tumba',
  'tumor',
  'tunal',
  'tunar',
  'tunas',
  'tunco',
  'tunel',
  'tunes',
  'tungu',
  'tunia',
  'tunis',
  'tupim',
  'tupir',
  'tupis',
  'tuplo',
  'tuque',
  'turai',
  'turar',
  'turba',
  'turbe',
  'turbo',
  'turca',
  'turco',
  'turdo',
  'turfa',
  'turfe',
  'turim',
  'turks',
  'turma',
  'turmo',
  'turne',
  'turno',
  'turva',
  'turve',
  'turvo',
  'tusco',
  'tussa',
  'tussi',
  'tusso',
  'tusto',
  'tutao',
  'tutar',
  'tutor',
  'tutsi',
  'tutti',
  'tutum',
  'uabui',
  'uacai',
  'uacus',
  'uagua',
  'uaiba',
  'uaica',
  'uaima',
  'uaina',
  'uaipi',
  'uaita',
  'uaiua',
  'ualua',
  'uambe',
  'uamoi',
  'uanda',
  'uanga',
  'uanha',
  'uanhi',
  'uania',
  'uapes',
  'uaqui',
  'uaria',
  'uasca',
  'uassu',
  'uaucu',
  'uaupe',
  'uaura',
  'uauri',
  'uaxua',
  'ubaia',
  'ubari',
  'ubata',
  'ubeba',
  'ubele',
  'ubere',
  'ubero',
  'ubins',
  'ubira',
  'ubucu',
  'uceno',
  'uchao',
  'uchoa',
  'ucila',
  'ucima',
  'ucola',
  'ucubu',
  'udana',
  'udasi',
  'udino',
  'udora',
  'ufabc',
  'ufana',
  'ufane',
  'ufano',
  'uffrj',
  'ufoba',
  'ufopa',
  'ufpel',
  'ufrgs',
  'ufrpe',
  'ufrrj',
  'ufssp',
  'ufvjm',
  'ugabe',
  'uiape',
  'uiara',
  'uiari',
  'uibai',
  'uigur',
  'uique',
  'uiras',
  'uiruu',
  'uivai',
  'uivam',
  'uivar',
  'uivas',
  'uivei',
  'uivem',
  'uives',
  'uivos',
  'uivou',
  'ujara',
  'ujica',
  'uladi',
  'ulano',
  'uleda',
  'uleia',
  'ulema',
  'ulhoa',
  'uliia',
  'ulite',
  'ulmer',
  'ulnal',
  'ulnar',
  'ulopa',
  'ulose',
  'ulota',
  'ulrei',
  'ultor',
  'ultra',
  'uluba',
  'uluco',
  'ulufe',
  'ulula',
  'ulule',
  'ululo',
  'umari',
  'umaua',
  'umblo',
  'umboa',
  'umbro',
  'umeri',
  'umero',
  'umida',
  'umido',
  'umiri',
  'unade',
  'unais',
  'unamo',
  'unani',
  'uncao',
  'uncia',
  'uncus',
  'undai',
  'undar',
  'undos',
  'unedo',
  'unelo',
  'unemo',
  'unesa',
  'unesp',
  'ungem',
  'unges',
  'ungia',
  'ungir',
  'ungis',
  'ungiu',
  'ungue',
  'ungui',
  'unhao',
  'unhar',
  'unhas',
  'uniam',
  'uniao',
  'unias',
  'unica',
  'unico',
  'unida',
  'unido',
  'unila',
  'unimo',
  'unioa',
  'unira',
  'unita',
  'unona',
  'unsia',
  'untai',
  'untam',
  'untar',
  'untas',
  'untei',
  'untem',
  'untes',
  'untor',
  'untou',
  'untue',
  'unues',
  'uogem',
  'uoges',
  'uongo',
  'upema',
  'uperu',
  'upiao',
  'upupa',
  'uraca',
  'uraco',
  'uracu',
  'urago',
  'urais',
  'urali',
  'uralo',
  'urano',
  'urare',
  'urari',
  'urase',
  'urban',
  'urceo',
  'urdam',
  'urdas',
  'urdem',
  'urdes',
  'urdia',
  'urdir',
  'urdis',
  'urdiu',
  'uredo',
  'ureia',
  'urgia',
  'urgir',
  'urgis',
  'urgiu',
  'urico',
  'uriel',
  'urien',
  'urina',
  'urine',
  'urino',
  'urjam',
  'urjas',
  'urnas',
  'uropo',
  'urrai',
  'urram',
  'urrar',
  'urras',
  'urrei',
  'urrem',
  'urres',
  'urros',
  'urrou',
  'ursas',
  'ursel',
  'ursos',
  'urubu',
  'urucu',
  'urulu',
  'urupa',
  'urupe',
  'urutu',
  'urzal',
  'urzes',
  'usada',
  'usado',
  'usais',
  'usamo',
  'usara',
  'usava',
  'useis',
  'usemo',
  'usina',
  'usmar',
  'usnea',
  'ussia',
  'ussua',
  'ustao',
  'ustia',
  'ustir',
  'ustos',
  'usual',
  'usura',
  'utana',
  'utata',
  'uteis',
  'utena',
  'utero',
  'utfpr',
  'utoca',
  'utota',
  'uvaca',
  'uvacu',
  'uvaia',
  'uvato',
  'uveal',
  'uvico',
  'uvido',
  'uvite',
  'uvula',
  'uxiva',
  'uzias',
  'uziel',
  'vaali',
  'vacal',
  'vacao',
  'vacar',
  'vacas',
  'vaceo',
  'vaceu',
  'vacua',
  'vacum',
  'vacuo',
  'vadea',
  'vades',
  'vadeu',
  'vadia',
  'vadie',
  'vadio',
  'vaduz',
  'vafro',
  'vagai',
  'vagal',
  'vagam',
  'vagao',
  'vagar',
  'vagas',
  'vagem',
  'vagia',
  'vagil',
  'vagir',
  'vagis',
  'vagiu',
  'vagos',
  'vagou',
  'vague',
  'vaiai',
  'vaiam',
  'vaiar',
  'vaias',
  'vaiei',
  'vaiem',
  'vaies',
  'vaila',
  'vaiou',
  'vaipe',
  'vaira',
  'vaita',
  'vaixa',
  'valao',
  'valar',
  'valas',
  'valdo',
  'valea',
  'valei',
  'valem',
  'valer',
  'vales',
  'valeu',
  'valga',
  'valgo',
  'valha',
  'valho',
  'valia',
  'valim',
  'valio',
  'valor',
  'valos',
  'valsa',
  'valva',
  'valvu',
  'vamos',
  'vampa',
  'vampe',
  'vampi',
  'vanga',
  'vania',
  'vante',
  'vapor',
  'vapua',
  'varai',
  'varal',
  'varam',
  'varao',
  'varar',
  'varas',
  'varei',
  'varem',
  'vares',
  'varge',
  'varia',
  'varie',
  'varig',
  'vario',
  'variz',
  'varja',
  'varli',
  'varno',
  'varoa',
  'varou',
  'varra',
  'varre',
  'varri',
  'varro',
  'varve',
  'varvo',
  'vasal',
  'vasco',
  'vasol',
  'vasos',
  'vassa',
  'vasta',
  'vasti',
  'vasto',
  'vatel',
  'vatio',
  'vatua',
  'vaura',
  'vazai',
  'vazam',
  'vazao',
  'vazar',
  'vazas',
  'vazei',
  'vazem',
  'vazes',
  'vazia',
  'vazio',
  'vazou',
  'veado',
  'vedai',
  'vedal',
  'vedam',
  'vedar',
  'vedas',
  'vedei',
  'vedem',
  'vedes',
  'vedor',
  'vedou',
  'vedro',
  'veeme',
  'vegas',
  'veiar',
  'veias',
  'veiga',
  'veios',
  'veira',
  'veiro',
  'vejai',
  'vejam',
  'vejas',
  'velai',
  'velam',
  'velar',
  'velas',
  'velea',
  'velei',
  'velem',
  'veles',
  'velha',
  'velho',
  'velia',
  'velon',
  'velos',
  'velou',
  'veloz',
  'velta',
  'velum',
  'vemos',
  'venal',
  'venca',
  'vence',
  'venci',
  'venco',
  'venda',
  'vende',
  'vendi',
  'vendo',
  'venha',
  'venho',
  'venia',
  'venta',
  'vente',
  'vento',
  'venus',
  'vepre',
  'vepsa',
  'verao',
  'veras',
  'veraz',
  'verba',
  'verbi',
  'verbo',
  'verca',
  'verde',
  'verdi',
  'verei',
  'verem',
  'veres',
  'verga',
  'verge',
  'veria',
  'veril',
  'verio',
  'verme',
  'verpa',
  'versa',
  'verse',
  'verso',
  'verta',
  'verte',
  'verti',
  'verto',
  'verve',
  'vesco',
  'vesga',
  'vesgo',
  'vespa',
  'veste',
  'vesti',
  'vetai',
  'vetam',
  'vetao',
  'vetar',
  'vetas',
  'vetei',
  'vetem',
  'vetes',
  'vetor',
  'vetos',
  'vetou',
  'vexai',
  'vexam',
  'vexar',
  'vexas',
  'vexei',
  'vexem',
  'vexes',
  'vexou',
  'vezar',
  'vezes',
  'viado',
  'viaja',
  'viaje',
  'viajo',
  'viana',
  'viara',
  'vibal',
  'vibra',
  'vibre',
  'vibro',
  'vicar',
  'vichi',
  'vicia',
  'vicie',
  'vicio',
  'vicoa',
  'victo',
  'vidal',
  'vidar',
  'vidas',
  'video',
  'vides',
  'vidia',
  'vidma',
  'vidra',
  'vidre',
  'vidro',
  'vidua',
  'vieis',
  'viela',
  'vielo',
  'viemo',
  'viena',
  'viera',
  'vieza',
  'vigar',
  'vigas',
  'viger',
  'vigia',
  'vigie',
  'vigil',
  'vigio',
  'vigna',
  'vigor',
  'viiia',
  'vilao',
  'vilar',
  'vilas',
  'vileu',
  'villa',
  'vilma',
  'viloa',
  'vilro',
  'vilta',
  'vimar',
  'vimba',
  'vimea',
  'vimos',
  'vinal',
  'vinca',
  'vinci',
  'vinco',
  'vinda',
  'vinde',
  'vindo',
  'vineo',
  'vinga',
  'vingo',
  'vinha',
  'vinho',
  'vinil',
  'vinoa',
  'vinte',
  'vioco',
  'vioes',
  'viola',
  'viole',
  'violo',
  'virai',
  'viral',
  'viram',
  'virao',
  'virar',
  'viras',
  'virei',
  'virem',
  'vireo',
  'vires',
  'virga',
  'virgo',
  'viria',
  'viril',
  'viris',
  'virmo',
  'virol',
  'viros',
  'virou',
  'virte',
  'virus',
  'visai',
  'visam',
  'visao',
  'visar',
  'visas',
  'visco',
  'visei',
  'visem',
  'vises',
  'viseu',
  'visgo',
  'visma',
  'visom',
  'visor',
  'visos',
  'visou',
  'visse',
  'vista',
  'viste',
  'visto',
  'vitae',
  'vital',
  'vitex',
  'vitis',
  'vitor',
  'vitre',
  'vitro',
  'viuva',
  'viuve',
  'viuvo',
  'vivam',
  'vivar',
  'vivas',
  'vivaz',
  'vivei',
  'vivem',
  'viver',
  'vives',
  'viveu',
  'vivia',
  'viviu',
  'vivos',
  'vivre',
  'vizir',
  'vleme',
  'voaco',
  'voada',
  'voado',
  'voais',
  'voamo',
  'voara',
  'voata',
  'voava',
  'vobis',
  'vocal',
  'voces',
  'vodca',
  'vodum',
  'voeis',
  'voemo',
  'vogal',
  'vogar',
  'vogas',
  'vogul',
  'voile',
  'voixo',
  'volar',
  'volei',
  'volta',
  'volte',
  'volto',
  'volts',
  'volva',
  'volve',
  'volvi',
  'volvo',
  'vomer',
  'vonda',
  'voras',
  'voraz',
  'vossa',
  'vosso',
  'votai',
  'votam',
  'votar',
  'votas',
  'votei',
  'votem',
  'votes',
  'votos',
  'votou',
  'vouga',
  'vouve',
  'vovos',
  'vozes',
  'vreia',
  'vridi',
  'vrija',
  'vriti',
  'vuapa',
  'vulco',
  'vulgo',
  'vulso',
  'vulto',
  'vulva',
  'vumbe',
  'vumbi',
  'vunar',
  'vunda',
  'vunje',
  'watts',
  'weber',
  'welia',
  'xabao',
  'xabeu',
  'xacra',
  'xadai',
  'xador',
  'xaile',
  'xales',
  'xalma',
  'xamba',
  'xampu',
  'xandu',
  'xango',
  'xaoro',
  'xaque',
  'xaras',
  'xarau',
  'xarda',
  'xarel',
  'xarem',
  'xareu',
  'xaual',
  'xauim',
  'xaxim',
  'xciii',
  'xcvii',
  'xeica',
  'xelim',
  'xelma',
  'xenar',
  'xenio',
  'xenon',
  'xenos',
  'xeque',
  'xerem',
  'xereu',
  'xerez',
  'xerga',
  'xerox',
  'xerre',
  'xerva',
  'xeura',
  'xexeu',
  'xibio',
  'xibiu',
  'xifio',
  'xiita',
  'xilol',
  'ximao',
  'ximbe',
  'ximbo',
  'xinar',
  'xinga',
  'xingo',
  'xingu',
  'xinje',
  'xinto',
  'xique',
  'xirua',
  'xisto',
  'xitau',
  'xliii',
  'xlvii',
  'xocar',
  'xogum',
  'xonar',
  'xorca',
  'xordo',
  'xossa',
  'xotar',
  'xuate',
  'xucra',
  'xucro',
  'xviii',
  'xxiii',
  'xxvii',
  'xxxii',
  'xxxiv',
  'xxxix',
  'xxxvi',
  'yocto',
  'yogos',
  'yotta',
  'zabai',
  'zabes',
  'zabro',
  'zacai',
  'zacum',
  'zagal',
  'zagao',
  'zagar',
  'zagas',
  'zagor',
  'zagre',
  'zague',
  'zaibo',
  'zaida',
  'zaide',
  'zaidi',
  'zaimo',
  'zaine',
  'zaino',
  'zaira',
  'zaire',
  'zaldo',
  'zambe',
  'zambi',
  'zambo',
  'zamia',
  'zanal',
  'zande',
  'zanga',
  'zango',
  'zania',
  'zanoa',
  'zante',
  'zanza',
  'zanze',
  'zanzo',
  'zaque',
  'zarao',
  'zarco',
  'zargo',
  'zarpa',
  'zarpe',
  'zarpo',
  'zarro',
  'zavar',
  'zavra',
  'zebra',
  'zebul',
  'zebus',
  'zefir',
  'zelai',
  'zelam',
  'zelar',
  'zelas',
  'zelda',
  'zelei',
  'zelem',
  'zeles',
  'zelha',
  'zelio',
  'zelos',
  'zelou',
  'zende',
  'zenia',
  'zenir',
  'zepto',
  'zerai',
  'zeram',
  'zerar',
  'zeras',
  'zerbo',
  'zerei',
  'zerem',
  'zeres',
  'zeros',
  'zerou',
  'zesto',
  'zetta',
  'zeugo',
  'zezao',
  'zibia',
  'zidim',
  'zigue',
  'zilda',
  'zilma',
  'zilro',
  'zimbo',
  'zimra',
  'zimri',
  'zinco',
  'zinga',
  'zinho',
  'zinia',
  'zinir',
  'zipar',
  'ziper',
  'zipor',
  'zirbo',
  'zirra',
  'zoeia',
  'zoico',
  'zoide',
  'zoilo',
  'zoina',
  'zomba',
  'zombe',
  'zombo',
  'zonal',
  'zonar',
  'zonas',
  'zonza',
  'zonzo',
  'zoose',
  'zorra',
  'zorro',
  'zoupo',
  'zoura',
  'zuate',
  'zuavo',
  'zucar',
  'zucos',
  'zular',
  'zulos',
  'zumba',
  'zumbe',
  'zumbi',
  'zumbo',
  'zunam',
  'zunar',
  'zunas',
  'zunda',
  'zunem',
  'zunes',
  'zunia',
  'zunir',
  'zunis',
  'zuniu',
  'zupar',
  'zurca',
  'zurpa',
  'zurra',
  'zurre',
  'zurro',
]
export default VALID_GUESSES
